import React, { useEffect, useState } from 'react';
import './style.css'; // Custom CSS file for styling
import { Link } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';
import { Spinner } from 'react-activity';
import { Rating } from 'react-simple-star-rating';

const SingleOrderPage = ({detectPageChange}) => {
  const [order, setOrder] = useState({
    id: '',
    typeOfWork: '',
    contractorId:'',
    order_number:'',
    down_payment: 0,
    final_payment:0,
    clientPicture:null,
    contractorPicture:null,
    property_address: '',
    clientName: '',
    contractorName:'',
    PaymentMethod:'',
    status: '',
    requirements: '',
    createdAt:'',
    authToCompleteOrder:false
  });
  const [editing, setEditing] = useState(false);
  const [editingRequirements, setEditingRequirements] = useState(false);
  const [newRequirements, setNewRequirements] = useState(order.requirements);
  const [updateOrderLoading,setUpdateOrderLoading] = useState(false);
  const [reviewData,setReviewData] = useState(null)
  const [isLoading,setisLoading] = useState(true)
  const [completeOrderLoading,setCompleteOrderLoading] = useState(false)
  const [cancelOrderLoading,setCancelOrderLoading] = useState(false)
  const [EditFinalPayment,setEditFinalPayment] = useState(0)
  const [error,setError] = useState("")

  const handleEditFinalPayment = () => {

    if(editing){
      setEditing(false)
      setEditFinalPayment(0)
    }else{
      setEditing(true)
      setEditFinalPayment(order.final_payment)
    }
    
  };

  const handleFinalPaymentChange = (event) => {
    setEditFinalPayment(event.target.value);
  };

  const handleSubmitFinalPayment = async() => {
    if(EditFinalPayment <5){
      alert("Final Payment Must Be Greater Than 5")
      return false
    }
    setUpdateOrderLoading(true)
    const user = await localStorage.getItem(localStorageUserKey);
    const order_id =await window.location.pathname.split('/')[2];
    let formData = new FormData()
    formData.append('order_id',order_id)
    formData.append('final_payment',EditFinalPayment)
   
    axios.put(`${base_url}/apis/order/updated_order_final_payment`, formData, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          "authorization": user,
          'Content-Type': 'application/json',
        },
      })
     .then((res) => {
        
        const newOrder = {...order}
        newOrder.final_payment = EditFinalPayment
        setOrder(newOrder)

        setUpdateOrderLoading(false)
        setEditing(false);
        alert("Order updated successfully")
      })
      .catch(err=>{
        if(err && err.response.data.message){
          alert(err.response.data.message)

        }else{
          alert("Failed to update order")
        }

        setEditFinalPayment(0)
        setUpdateOrderLoading(false)
        setEditing(false);

      })
   
  };

  const fetchOrderData = async()=>{
    const user = await localStorage.getItem(localStorageUserKey);
    const order_id = await window.location.pathname.split('/')[2]

    await axios.get(`${base_url}/apis/order/single-order?order_id=${order_id}`,{
      headers:{
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', 
      }
    })
    .then(res=>{
      
        setOrder({id:res.data._id,
                  typeOfWork:res.data.work_need_done,
                 
                  down_payment:res.data.down_payment,
                  final_payment:res.data.final_payment,

                  property_address:res.data.property_address,
                  clientPicture:res.data.client?res.data.client[0]?.profile_picture:null,
                  clientName:res.data.client[0]?res.data.client[0]?.username:'deleted by admin',
                  contractorName:res.data.contractor[0]?res.data.contractor[0]?.username:'deleted by admin',
                  contractorId:res.data.contractor[0]?res.data.contractor[0]?._id:null,
                  contractorPicture:res.data.contractor?res.data.contractor[0]?.profile_picture:null,
                  order_number:res.data.order_number,
                  status:res.data.status,
                  requirements:res.data.requirements,
                  authToCompleteOrder:res.data.authToCompleteOrder,
                  createdAt:res.data.createdAt

                  })
      if(res.data.status == "completed"){
      fetchOrderReview()

      }
      setisLoading(false)
      
     
    })
    .catch(err=>{
      if(err && err.response){
        setError(err.response.data.message)
      }else{
        setError("Something went wrong")
      }
      
    })
  }

  const generate_complete_order_payment_link = async()=>{
   
    setCompleteOrderLoading(true)
    const user = await localStorage.getItem(localStorageUserKey);
    const order_id = await window.location.pathname.split('/')[2]
    
      await axios.post(`${base_url}/apis/order/complete_order_payment_creation`, {
        order_id:order_id
      }, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "authorization": user,
          'Content-Type': 'application/json', // Set the content type
        },
      })
      .then((response) => {
          const data = response.data
          window.location = data.link
          setCompleteOrderLoading(false)
      })
      .catch((err) => {
          setCompleteOrderLoading(false)

          if(err.response && err.response.data){
            alert(err.response.data.message)
          }else{
            alert("Something went wrong")
          }
        }
      );
  }
 
  const handleCancelOrder = async() => {
   const user = await localStorage.getItem(localStorageUserKey);
   const order_id = await window.location.pathname.split('/')[2]
   const reason= window.prompt("Reason For Canceling This Order","");

   if(reason && reason.length > 0){
    let formData = new FormData()
    formData.append('order_id',order_id)
    formData.append('reason',reason)
    setCancelOrderLoading(true)
    axios.put(`${base_url}/apis/order/cancel-order`,formData,{
      headers:{
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', 
      }
    })
    .then(res=>{
      if(res.status == 200){
        alert(res.data.message)
        window.location.reload()
      }else{
        alert(res.data.message)
      }

     setCancelOrderLoading(false)

    })
    .catch(err=>{
      if(err.response && err.response.data){
        alert(err.response.data.message)
       }else{
        alert("Something went wrong")
       }

    setCancelOrderLoading(false)

    })
   }
  };

  const handleEditRequirements = () => {
    setEditingRequirements(true);
    setNewRequirements(order.requirements);
};

const handleRequirementsChange = (event) => {
    setNewRequirements(event.target.value);
};

const handleSubmitRequirements = async () => {
    if (newRequirements.trim() === '') {
        alert("Requirements cannot be empty");
        return;
    }

    setUpdateOrderLoading(true);
    const user = await localStorage.getItem(localStorageUserKey);
    const order_id = await window.location.pathname.split('/')[2];
    try {
        const response = await axios.put(`${base_url}/apis/order/update_order_requirements`, {
            order_id,
            requirements: newRequirements,
        }, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                "authorization": user,
                'Content-Type': 'application/json',
            },
        });

        setOrder({ ...order, requirements: newRequirements });
        setEditingRequirements(false);
        alert("Requirements updated successfully");
    } catch (err) {
        alert("Failed to update requirements");
    } finally {
        setUpdateOrderLoading(false);
    }
};

  const fetchOrderReview = async()=>{
    
   const user =await localStorage.getItem(localStorageUserKey);
   const order_id = await window.location.pathname.split('/')[2]
   axios.get(`${base_url}/apis/order/get_one_order_review?order_id=${order_id}`, {
    headers:{
      "x-api-key": process.env.REACT_APP_API_KEY,
      "authorization": user,
      'Content-Type': 'application/json', 
    }
  })
  .then(res=>{
   
    setReviewData(res.data)
  })
  .catch(err=>{
    console.log(err)
  })
  }

  useEffect(()=>{
    detectPageChange(window.location.pathname)
   
    fetchOrderData()

 
  },[])

 if(error) return <center className='container mt-5'>
  <h2>{error}</h2>
 </center>

    if(isLoading == false){
      return (
      
        <div className="card ">
          <div className="card-header bg-primary text-white">
            <h2 className="card-title">Order No: {order.order_number}</h2>
            <p className="card-text ">Status: <b className='border p-1 ' style={{borderRadius:5,backgroundColor:'skyblue',color:'black'}}>{order.status == "pending"?'progress':order.status}</b></p>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between mb-3">

            <div className="border p-2" style={{color:order.contractorName != "deleted by admin"?'black':'red',borderRadius:5}}>
                    <p style={{fontSize:13,color:'white',backgroundColor:'#007aff',padding:3,borderRadius:5,position:'relative'}} className='float-right ml-3'>Handyman</p>

                    {order.contractorName != "deleted by admin" ?<a href={`/contractor_profile/${order.contractorId}`} style={order.contractorName == "deleted by admin" ?{pointerEvents:'none',cursor:'default'}:{}}><img src={order.contractorPicture != null?order.contractorPicture:require('../../../assets/user-circle.png')} style={{width:30,height:30,borderRadius:'100%'}}/></a> :null} <a href={`/contractor_profile/${order.contractorId}`}><b style={{color:order.contractorName != "deleted by admin"?'black':'red'}}>{order.contractorName}</b></a>
              </div>




              <div className="border p-2" style={{color:order.clientName != "deleted by admin"?'black':'red',borderRadius:5}}>

            {order.clientName != "deleted by admin"?<img src={order.clientPicture != null?order.clientPicture:require('../../../assets/user-circle.png')} style={{width:30,height:30,borderRadius:'100%'}}/>:null} <b style={{color:order.clientName != "deleted by admin"?'black':'red'}}>{order.clientName}</b>

            <p style={{fontSize:13,color:'white',backgroundColor:'#007aff',padding:3,borderRadius:5,position:'relative'}} className='float-right ml-3'>Client</p>
            
              </div>
              
            </div>
            <div className="mb-3">
              <div className="card-text border p-3" style={{flexDirection:'row',display:'flex', justifyContent:'space-between'}}>Service  <b>{order.typeOfWork}</b></div>
              <div  className="card-text border p-3" style={{flexDirection:'row',display:'flex', justifyContent:'space-between'}}>Down Payment <b>${order.down_payment}</b></div>
            <div className="card-text border p-3" style={{flexDirection:'row',display:'flex', justifyContent:'space-between',flexWrap:'wrap'}}>Final Payment {editing ? (
              <div style={{width:'100%'}}>

                <input
                  type="number"
                  className="form-control"
                  value={EditFinalPayment}
                  onChange={handleFinalPaymentChange}
                />
                <br />
                <button className="btn btn-primary" disabled={updateOrderLoading} onClick={handleSubmitFinalPayment}>{updateOrderLoading?<Spinner color='skyblue' size={18}/>:'Update'}</button>
                <button className="btn btn-danger ml-3"  onClick={()=>{
                  setEditFinalPayment()
                  setEditing(false)
                }}>Close</button>

                </div>
              ) : (
              
                <b>{`$${order.final_payment}`} {editing == false && updateOrderLoading == false && order.authToCompleteOrder && order.status == "pending"?<i style={{cursor:'pointer',color:'#007bff',fontSize:20}} onClick={handleEditFinalPayment} className='fa fa-edit'></i>:null}</b>
              

              )}</div>

              <div className="card-text border p-3" style={{flexDirection:'row',display:'flex', justifyContent:'space-between'}}>Address <b>{order.property_address}</b></div>
              <div className="card-text border p-3" style={{flexDirection:'row',display:'flex', justifyContent:'space-between'}}>Start Date  <b>{ new Date(order.createdAt).toUTCString()}</b></div>

            </div>

            <div >
            <div className="mb-3 border p-3">
            {order.status === "pending" && editingRequirements == false && order.authToCompleteOrder  && (
                  <button className="btn btn-primary float-right" onClick={handleEditRequirements}>Edit</button>
              )}
                    <h4>Requirements:</h4>
                    
                    {editingRequirements ? (
                        <div>
                            <textarea
                                className="form-control"
                                value={newRequirements}
                                onChange={handleRequirementsChange}
                            />
                            <br />
                            <button className="btn btn-primary" disabled={updateOrderLoading} onClick={handleSubmitRequirements}>
                                {updateOrderLoading ? <Spinner color='skyblue' size={18} /> : 'Update'}
                            </button>
                            <button className="btn btn-danger ml-3" onClick={() => setEditingRequirements(false)}>Cancel</button>
                        </div>
                    ) : (
                      <div>
                      
                        <p className="card-text">
                        
                            {order.requirements} 
                            
                        </p>
                        </div>
                    )}
                </div>
            {order.status === "pending" ? (
              <div className="d-flex justify-content-end">
                {order.authToCompleteOrder ? (
                <div>
                <button className="btn btn-danger mr-2" disabled={cancelOrderLoading} onClick={handleCancelOrder}>
                {cancelOrderLoading?<Spinner color='skyblue' size={18}/>: 'Cancel Order'}
                </button>
                
                  <button
                    className="btn btn-success"
                    disabled={completeOrderLoading}
                    onClick={generate_complete_order_payment_link}
                  >
                    {completeOrderLoading?<Spinner color='skyblue' size={18}/>: 'Complete Order and Make Payment'}
                  </button>
                
                </div>
                ) : null}
              </div>
            ) : null}


            {reviewData? <div className='border p-2 mt-3' style={{borderRadius:10}} >
            <h3>Client Review</h3>
            <div>
            {order.clientName != "deleted by admin"?<img src={order.clientPicture != null?order.clientPicture:require('../../../assets/user-circle.png')} style={{width:30,height:30,borderRadius:'100%'}}/>:null} <b style={{color:order.clientName != "deleted by admin"?'black':'red'}}>{order.clientName}</b>
            <br />
            <div className='ml-4'>

              <Rating initialValue={reviewData.rating} size={17} readonly />

                <p >{reviewData.comment}</p>
                </div>
              </div>
            </div>:null}
          </div>
        </div>
      </div>
      
      );
    }else{
      return <center><Spinner size={25} color='skyblue'/></center>
    }
  };



export default SingleOrderPage;
