import React from "react";


export default class SomethingWentWrong extends React.Component{
    componentDidMount(){
        if(this.props.detectPageChange){

        this.props.detectPageChange("Error");
        }
       
    }
    render(){
        return(
            <div class="container">
            <div class="row">
                <div class="col-12 text-center mt-5">
                <i class="fa fa-globe fa-5x text-primary"></i>
                    <h1 class="text-danger">Something Went Wrong</h1>
                    <p class="lead">Please try again.</p>
                    <button onClick={()=>window.location.reload()}  className="btn btn-primary">Retry</button>
                </div>
            </div>
          </div>
        )
    }
}
