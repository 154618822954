import axios from 'axios';
import React, { useState,useEffect } from 'react';
import base_url from '../../../base_url';

const ResetPasswordVerifyEmail = ({detectPageChange}) => {
  const [email, setEmail] = useState('');

  const handleSendVerificationEmail = async() => {
    try{
        let formData =new FormData()
        formData.append('email',email)
        const response = await fetch(base_url+'/apis/auth/send_reset_pass_verification',{
            method:'POST',
            body:formData,
            headers:{
                "x-api-key":process.env.REACT_APP_API_KEY
            }
        })
        const data = await response.json()
        console.log(data)
        if(response.status == 200){
            window.location = "/login"
            alert(data.message)
        }else{
            alert("Something Went Wrong")
        }
    }catch(error){
        alert("Something Went Wrong")
    }
    
  };

  

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2 className="text-center mb-4">Reset Password</h2>

         
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSendVerificationEmail}
            >
              Reset
            </button>
         
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordVerifyEmail;
