import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';
import ContractorJobsPage from './jobs_for_contractor';
import ClientJobsPage from './jobs_page_for_client';
import axios from 'axios';
import SomethingWentWrong from '../../something_wentwrong';

const Home = ({socket,user,detectPageChange}) => {
const [user_type,setUserType] = useState('client')
const [jobs,setJobs]= useState([])
const [isLoading,setisLoading]=useState(true)
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const [error,setError] = useState(null)

const itemsPerPage = 10

const getJobs = async()=>{
  const user = await localStorage.getItem(localStorageUserKey);

  await axios.get(`${base_url}/apis/job/jobs?page=${currentPage}&pageSize=${itemsPerPage}`,{
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "authorization": user,
      'Content-Type': 'application/json', 
    }})
    .then(response=>{
      
      if(response.status == 200){
      
        setJobs(response.data.data)
        setTotalPages(response.data.totalPages);
        setUserType(response.data.user_type)
        setisLoading(false)
      }else{
        setError("Something Went Wrong")

      }
    })
    .catch(err=>{
      console.log(err)
      setError(err)
    })
}


useEffect(()=>{
  detectPageChange(window.location.pathname)
  getJobs()

},[currentPage])

const handlePageChange = (newPage) => {
  if (newPage >= 1 && newPage <= totalPages) {
    setCurrentPage(newPage);
   

  }
  window.scrollTo(0, 0)

};


if(isLoading){
  return <center>
    <Spinner size={25} color='skyblue'/>
    </center>
}else{
  if(error == null){

  if(user_type == 'client'){
    return <>
      <ClientJobsPage jobs={jobs} isLoading={isLoading} user={user} getJobs={getJobs} pageName="home"/>
      {jobs.length>0?<center>

      <div className="row mb-3">
              <div className="col-md-12">
                ({currentPage}/{totalPages})&nbsp;
                <button
                  className="btn btn-secondary mr-2"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>

      </center>:null}

    </> 
}else if(user_type == "contractor"){
    return <>

      <ContractorJobsPage socket={socket} jobs={jobs} isLoading={isLoading} getJobs={getJobs} user={user} pageName="home"/>

      <center>

      <div className="row mb-3">
              <div className="col-md-12">
                ({currentPage}/{totalPages})&nbsp;
                <button
                  className="btn btn-secondary mr-2"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
      </center>
      </>

}
}else{
  return  <SomethingWentWrong RedirectPage={'/home'}/>
 }
 
}
 
};

export default Home;
