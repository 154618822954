import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import axios from 'axios';
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';
import { Link } from 'react-router-dom';
import TimeSlotManager from '../account/timeslots';

const user = localStorage.getItem(localStorageUserKey);
const currentDate = new Date().toISOString().split('T')[0];

const PostJobPage = ({detectPageChange}) => {

  const [name, setName] = useState('');
 
  const [workNeeded, setWorkNeeded] = useState('');

  const [first_appointmentDate, setFirstAppointmentDate] = useState('');
  const [second_appointmentDate, setSecondAppointmentDate] = useState('');
  const [description,setDescription] = useState('')
  const [phone_number,setPhonenumber] = useState('')
  const [zipcode,setZipcode] = useState('')
  const [btn_loading,setbtnLoading] = useState(false)
  const [locations,setLocations] = useState([])
  const [cities,setCities] = useState([])
  const [servicesData,setservicesData] = useState([])
  const [timeslotsData,setTimeSlotsData] = useState([])
  const [first_timeslot,setfirst_timeslot] = useState('')
  const [second_timeslot,set_second_timeslot] = useState('')
  const [address,setAddress] = useState('')

  const validate = ()=>{
    if(name.length<4){
      alert("Title must be 4 character long")
      

      return false
    }

   

    if(!workNeeded){
      alert("Work Need Field is required")
      return false
    }
    
    if(locations.length<1){
      alert("City field is required")
     
      return false
    }

    
    if(address.length<4){
      alert("Address must be atleast 4 characters")
     
      return false
    }

    if(first_appointmentDate.length<1 || second_appointmentDate.length<1){
      alert("both appointment dates are required to be selected")
      
      return false
    }

    if(zipcode.length<4){
      alert("Zipcode must be atleast 4 characters")
     
      return false
    }

    if(first_timeslot.length<1){
      alert("Please select the time slots.If incase you are unable to see the time slots then go to your account page and add time slots ")
      return false
    }


    if(second_timeslot.length<1){
      alert("Please select the time slot.If incase you are unable to see the time slot then go to your account page and add time slots ")
      return false
    }
    if(phone_number.length<1){
      alert("Phone number field is required")
     
      return false
    }

    if(description.length<1){
      alert("description is required")
      return false
    }
    

   
    return true
    
  }


  const fetchCities = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/city/get-cities`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let temp = []
        response.data.map(i=>{
          temp.push({value:i.name,label:i.name})
        })
        temp.sort((a, b) => a.value.localeCompare(b.value))
        setCities(temp);

      } else {
       setCities([])
       console.log(response.status)
      }
    } catch (error) {
      console.error(error);
      setCities([])

    }
  };


  const fetchServices = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/service/get-services`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let temp = []
        response.data.map(i=>{
          temp.push({value:i.name,label:i.name})
        })
        temp.sort((a, b) => a.value.localeCompare(b.value))

        setservicesData(temp)
      } else {
        
        setservicesData([])
      }
    } catch (error) {
      // console.error(error);
      setservicesData([])
     
    }
  };

  const formatTimeWithAmPm = (timeString) => {
    if (!timeString) return '';

    const [hours, minutes] = timeString.split(':');
    let formattedHours = parseInt(hours, 10);

    // Determine AM or PM
    const period = formattedHours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    formattedHours = formattedHours % 12 || 12;

    return `${formattedHours}:${minutes} ${period}`;
  };

  useEffect(()=>{
    detectPageChange(window.location.pathname)

  },[])

  const handleFormSubmit = async() => {
  

    const is_validate = validate();

    if (is_validate && !btn_loading) {
     
      setbtnLoading(true);
    
      const data = {
        name: name,
        type_of_work: workNeeded,
        location: locations,
        first_appointment_date: first_appointmentDate,
        second_appointment_date: second_appointmentDate,
        first_timeslot:first_timeslot,
        second_timeslot:second_timeslot,
        zipcode: zipcode,
        phone_number: phone_number,
        description:description,
        address:address
      };
    
      try {
        const response = await axios.post(`${base_url}/apis/job/add_job`, data, {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "authorization": user,
            'Content-Type': 'application/json', // Set the content type
          },
        });
        const responseData = response.data;
    
        if (response.status === 200) {
            alert(responseData.message);
            window.location = "/home"
          // Job added successfully
        } else {
          // Handle error responses here
          alert(responseData.message);
        }
    
        setbtnLoading(false);
      } catch (error) {
        alert("Something Went wrong");
        setbtnLoading(false);
      }
  
  
    }
  };
  const GetUserTimeSlots = async()=>{
    const user = await localStorage.getItem(localStorageUserKey);

    await axios.get(`${base_url}/apis/auth/profile`,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', 
      },
    })
    .then(res=>{
      if(res.status == 200){
        

        if(res.data.user.timeslot1_from.length>0 && res.data.user.timeslot1_to.length>0 && res.data.user.timeslot2_from.length>0 && res.data.user.timeslot2_to.length>0){

       
        setTimeSlotsData([
          { id: 1, from: res.data.user.timeslot1_from, to: res.data.user.timeslot1_to },
          { id: 2,from: res.data.user.timeslot2_from, to: res.data.user.timeslot2_to },
        ])

        }
         

      }else{
        return false
      }
    })
    .catch(err=>{
     return false
    })
  }


  useEffect(()=>{
    fetchCities()
    fetchServices()
    GetUserTimeSlots()
  },[])
  return (
    <div className="container mt-5">
      <h2>Request quote for your job:</h2>
    
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Title:
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Service:
          </label>

          <Select
            options={servicesData}
            isMulti={false}
                   
            closeMenuOnSelect={true}
            placeholder="Services"
            onChange={(val)=>setWorkNeeded(val.value)}
            />
        </div>

        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Phone Number:
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            
            onChange={(e) => {
              setPhonenumber(e.target.value)
            }}
            required
          />
        </div>


        <div className="mb-3">
          <label htmlFor="name" className="form-label">
           Address:
          </label>
          <input
            type="text"
            className="form-control"
            id="address"
            
            onChange={(e) => {
              setAddress(e.target.value)
            }}
            required
          />
        </div>


        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            City:
          </label>
        <Select
                    
        options={cities}
        isMulti={false}
        onChange={(val)=>{
                  setLocations(val.value)
                  }}
        closeMenuOnSelect={true}

        placeholder="City"
        />
      </div>
             
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Zipcode:
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            
            onChange={(e) => {
              setZipcode(e.target.value)
            }}
            required
          />
        </div>

      

      <div className="container mt-3 border p-4 rounded">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3" >
            <label htmlFor="dateInput" className="form-label">APPOINTMENT OPTION # 1:</label>
            <input type="date" className="form-control" id="dateInput" min={currentDate} onChange={(val)=>setFirstAppointmentDate(val.target.value)}/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Select Time Slot:</label>
            {timeslotsData.map((data,index)=>{
              return <div key={index} className="form-check">
              <input className="form-check-input" type="radio"  name="timeSlot1"  onChange={(val)=>{
                
                  setfirst_timeslot(val.target.value)
               
              }} value={`${formatTimeWithAmPm(data.from)} to ${formatTimeWithAmPm(data.to)}`} />
              <label className="form-check-label" htmlFor="morningRadio">
                {formatTimeWithAmPm(data.from) } to {formatTimeWithAmPm(data.to)}
              </label>
            </div>
            })}
            
           

        <button type="button" className="btn btn-primary float-right" data-toggle="modal" data-target="#myModal">
          Manage Timeslots
        </button>
          </div>
        </div>
      </div>



    </div>



    <div className="container mt-3 border p-4 rounded">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3" >
            <label htmlFor="dateInput" className="form-label">APPOINTMENT OPTION # 2:</label>
            <input type="date" className="form-control" min={currentDate} onChange={(val)=>setSecondAppointmentDate(val.target.value)} id="dateInput"/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Select Time Slot:</label>
            {timeslotsData.map((data,index)=>{
              return <div key={index} className="form-check">
              <input className="form-check-input" type="radio" name="timeSlot2"  onChange={(val)=>{
                
                  set_second_timeslot(val.target.value)
               
              }} value={`${formatTimeWithAmPm(data.from)} to ${formatTimeWithAmPm(data.to)}`} />
              <label className="form-check-label" htmlFor="morningRadio">
              {formatTimeWithAmPm(data.from) } to {formatTimeWithAmPm(data.to)}

              </label>
            </div>
            })}
            
           
            <button type="button" className="btn btn-primary float-right" data-toggle="modal" data-target="#myModal">
          Manage Timeslots
        </button>
           
          </div>
        </div>
      </div>



    </div>

    <br />
   

       
        
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
           Description:
          </label>
        <textarea
        name='description'
        placeholder='description..'
        onChange={(val)=>setDescription(val.target.value)}
        value={description}
        className='form-control'
        />
        </div>


        <button onClick={handleFormSubmit} disabled={btn_loading} className="btn btn-primary">
          {btn_loading?<Spinner color='skyblue' size={18}/>:null}&nbsp;&nbsp;Submit Job
        </button>


        <button onClick={()=>{
             window.history.back()

        }} disabled={btn_loading} className="btn btn-danger ml-3">
          Cancel
        </button>


        <br />
        <br />
        <br />


        <div className="modal fade" id="myModal" >
  <div className="modal-dialog" >
    <div className="modal-content">
      {/* Modal Header */}
      <div className="modal-header">
        <h4 className="modal-title">Manage Your TimeSlots</h4>
        <button type="button" className="close" data-dismiss="modal">
          ×
        </button>
      </div>
      {/* Modal body */}
     <TimeSlotManager fetchTimeSlots={GetUserTimeSlots}/>
      {/* Modal footer */}
      <div className="modal-footer">
        <button type="button" className="btn btn-danger" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

export default PostJobPage;
