import {
    redirect,
    Navigate
  } from "react-router-dom";
  import {localStorageUserKey} from '../local_storage_vars'; 

const GuestRequire = ({children})=>{
    const user = localStorage.getItem(localStorageUserKey);
    if(user){
        return <Navigate to={'/home'} />
    }else{
        return children
    }
}

export default GuestRequire