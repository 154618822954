

import React, { useEffect } from 'react';

const NotFound = ({detectPageChange}) => {
  useEffect(()=>{
    detectPageChange(window.location.pathname)

  },[])
  return (

    <div class="container border">
            <div class="row">
                <div class="col-12 text-center mt-5">
                <i class="fa fa-globe fa-5x text-primary"></i>
                    <h1 class="text-danger">404 - Not Found</h1>
                    <p class="lead">Sorry, the page you are looking for does not exist.</p>
                        <a href='/' className='btn btn-primary'>Go to home page</a>
                </div>
            </div>
          </div>


  );
};

export default NotFound;
