// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-custom {
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .btn-custom:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .btn-custom.btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .btn-custom.btn-secondary {
    background-color: #6c757d;
    color: #fff;
  }
  
  .btn-custom.btn-danger {
    background-color: #dc3545;
    color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/main/account/account.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;EAC3B;;EAEA;IACE,2BAA2B;IAC3B,wCAAwC;EAC1C;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb","sourcesContent":[".btn-custom {\n    border: none;\n    border-radius: 5px;\n    padding: 10px 20px;\n    margin-bottom: 10px;\n    cursor: pointer;\n    transition: all 0.3s ease;\n  }\n  \n  .btn-custom:hover {\n    transform: translateY(-2px);\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n  }\n  \n  .btn-custom.btn-primary {\n    background-color: #007bff;\n    color: #fff;\n  }\n  \n  .btn-custom.btn-secondary {\n    background-color: #6c757d;\n    color: #fff;\n  }\n  \n  .btn-custom.btn-danger {\n    background-color: #dc3545;\n    color: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
