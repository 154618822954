import { useState } from "react";
import { Spinner } from "react-activity";
import base_url from "../../../base_url";
import {localStorageUserKey, localStorageAdminKey} from '../../../local_storage_vars';
import axios from "axios";
import { useEffect } from "react";


export default function AdminCities(){

    
        const [data,setData] = useState([])
        const [name,setName] = useState('')
        const [isLoading,setisLoading]= useState(true)


        const handleSubmit = async(e) => {
            e.preventDefault();
         
              const user = await localStorage.getItem(localStorageAdminKey);
              axios.post(`${base_url}/apis/city/create-city`,{
                name:name
              },{
              headers:{
                  "x-api-key": process.env.REACT_APP_API_KEY,
                  "authorization": user,
                  'Content-Type': 'application/json', 
              }
              })
              .then(response => {
                  // Update the records with the new data
                  setData([...data, response.data]);
                  setName("")
              })
              .catch(error =>{
                if(error.response && error.response.data){
                    alert(error.response.data.message)
                   }else{
                    alert("Failed to add city.Please try again")
                   }
              });
  
          
        };
        
  
        const handleDelete = async(id)=>{
          const user = await localStorage.getItem(localStorageAdminKey);
  
          axios.delete(`${base_url}/apis/city/delete-city?city_id=${id}`,{
              headers:{
                  "x-api-key": process.env.REACT_APP_API_KEY,
                  "authorization": user,
                  'Content-Type': 'application/json', 
              }
          })
          .then((res) => {
            if(res.status == 200){
                if(res.data.is_deleted){
                    setData(data.filter(i => i._id !== id));

                }else{
                    alert(res.data.message)

                }
             }
          })
          .catch(error => alert("Something Went Wrong"));
        }
        
        const hide = async(id)=>{
            const user = await localStorage.getItem(localStorageAdminKey);
  
            axios.put(`${base_url}/apis/city/hide`, { city_id: id },{
                headers:{
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "authorization": user,
                    'Content-Type': 'application/json', 
                }
            })
            .then((res) => {
  
               if(res.status == 200){
                 fetchData()
               }else{
                alert(res.data.message)
               }
              
            })
            .catch(error => alert(error.message));
        }

        const unhide = async(id)=>{
            const user = await localStorage.getItem(localStorageAdminKey);
  
            axios.put(`${base_url}/apis/city/unhide`, { city_id: id },{
                headers:{
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "authorization": user,
                    'Content-Type': 'application/json', 
                }
            })
            .then((res) => {
  
               if(res.status == 200){
                 fetchData()
               }else{
                alert(res.data.message)
               }
              
            })
            .catch(error => alert(error.message));
        }
        const fetchData =async ()=>{
          const user = await localStorage.getItem(localStorageAdminKey);
          axios.get(`${base_url}/apis/city/get-cities?is_admin=true`,{
              headers:{
                  "x-api-key": process.env.REACT_APP_API_KEY,
                  "authorization": user,
                  'Content-Type': 'application/json', 
              }
          })
          .then(res=>{
              if(res.status == 200){
                  setData(res.data)
                  setisLoading(false)
              }else{
                  alert(res.data.message)
              }
          })
          .catch(err=>{
              alert("Something Went Wrong")
          })
        }
  
        useEffect(()=>{
            fetchData()
        },[])

        return(
        <div className="container mt-5">
      <div className="row">
        <div className="col-md-6 offset-md-3">
       

        {isLoading == false?<>
        
        <form onSubmit={handleSubmit}>
            <div className="form-group">
            <input
                type="text"
                className="form-control"
                placeholder="City Name"
                onChange={(val)=>setName(val.target.value)}
                value={name}
                required
            />
            </div>
            <button type="submit" className="btn btn-primary">Add</button>
            <h5 className='float-right border p-2 bg-primary text-white' style={{borderRadius:5}}>Total Cities : {data.length}</h5>

        </form>


        <h2 className="mt-4">Cities Management</h2>
        
        
        <ul className="list-group mt-3">
           {data.map((i)=><li key={i._id} className="list-group-item d-flex justify-content-between align-items-center">
            {i.name}{i.hidden?<button className="btn btn-danger btn-sm" onClick={()=>unhide(i._id)}>UnHide</button>:<button className="btn btn-danger btn-sm" onClick={()=>hide(i._id)}>Hide</button>}
            </li>)}
         
           
        </ul>

        </>:<center><Spinner color="skyblue" size={25}/></center>}

        </div>
    </div>
    </div>

    )
    
}