// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    padding: 10px; /* 20px; */
  }
  
  .order-heading {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .card {
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card-body {
    padding: 20px;
  }
  
  .btn {
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-danger {
    background-color: #dc3545;
    color: #fff;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  
  @media (max-width: 767px) {
    .col {
      margin-bottom: 15px;
    }
  }

  
 `, "",{"version":3,"sources":["webpack://./src/pages/main/order/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,UAAU;EAC3B;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,wCAAwC;EAC1C;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE;MACE,mBAAmB;IACrB;EACF","sourcesContent":[".container {\r\n    padding: 10px; /* 20px; */\r\n  }\r\n  \r\n  .order-heading {\r\n    font-size: 24px;\r\n    font-weight: bold;\r\n    color: #007bff;\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  .card {\r\n    border-radius: 10px;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  .card-body {\r\n    padding: 20px;\r\n  }\r\n  \r\n  .btn {\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .btn-danger {\r\n    background-color: #dc3545;\r\n    color: #fff;\r\n  }\r\n  \r\n  .btn-primary {\r\n    background-color: #007bff;\r\n    color: #fff;\r\n  }\r\n  \r\n  @media (max-width: 767px) {\r\n    .col {\r\n      margin-bottom: 15px;\r\n    }\r\n  }\r\n\r\n  \r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
