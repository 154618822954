import React from "react";


export default function MessageContainer({message,decodedTokenRef}){

    return(
        <div  className="message mt-2 position-relative" style={{borderColor:message.sender_user[0]._id == decodedTokenRef.current._id ?'#007aff':'white'}}>
        <div className="d-flex align-items-center">
         <a style={message.sender_user[0] && message.sender_user[0]?.user_type == "client"?{pointerEvents:'none',cursor:'default'}:{}} href={message.sender_user[0] && message.sender_user[0]?.user_type == "contractor"?`/contractor_profile/${message.sender_user[0]?._id}`:'#'}><strong style={{ color: 'black' }}>
            <img
              src={message.sender_user[0]?.profile_picture?message.sender_user[0]?.profile_picture:require('../../assets/user-circle.png')}
              style={{ width: 30, height: 30, borderRadius: '50%', marginRight: '10px' }}
              alt="User Profile"
            />
            {message.sender_user[0]?._id == decodedTokenRef.current._id? 'Me':message.sender_user[0]?.username}
          </strong></a>



          <span className="ml-auto small text-muted">
            {new Date(message.createdAt).toLocaleString()}
          </span>
        </div>
        <div className="border rounded p-3 position-relative">
          <p className="mb-0">{message.message}</p>

          {message.is_invitation == true?<button disabled={message.sender == decodedTokenRef.current?._id} className="btn btn-success" onClick={()=>window.location=`/invitation/${message._id}`} style={{marginTop:10,width:'100%'}}>View Job Invitation</button>:null}
          <div className="speech-bubble"></div>
        </div>
      </div>
    )
}