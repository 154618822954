import { useState, useEffect } from "react";
import { Spinner } from "react-activity";
import base_url from "../../../base_url";
import {localStorageAdminKey} from '../../../local_storage_vars';
import axios from "axios";
import Loading from 'react-fullscreen-loading';

const AdminJobList = () => {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [operationLoading,setOperationLoading] = useState(false)
  const [totalJobsCount, setTotalJobsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize,setPageSize] = useState(5)
  const [services, setServices] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    _id: '',
    added_by: '',
    first_appointment_date: '',
    second_appointment_date:'',
    name: '',
    phone_number: '',
    type_of_work: '',
    updatedAt: '',
    zipcode: '',
    location: ''
  });
  const [isLoading, setIsLoading] = useState(true);

  const generateCsv = () => {
    if(data.length<1){
      alert("You dont have any record to generate csv")
      return false
    }
    setOperationLoading(true)

    const csvContent = 'data:text/csv;charset=utf-8,' +
      Object.keys(data[0]).join(',') + '\n' +
      data.map(row => Object.values(row).join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setOperationLoading(false)

  };

  const fetchJobs = async () => {
  
    try {
      const user = await localStorage.getItem(localStorageAdminKey);
      const response = await axios.get(`${base_url}/apis/job/all_jobs?page=${currentPage}&&limit=${pageSize}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        
        setData(response.data.jobs);
        setTotalPages(Math.ceil(response.data.totalCount / pageSize));
       
        setTotalJobsCount(response.data.totalCount)
        setIsLoading(false);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert('Something Went Wrong');
    }
  };

  const fetchCities = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/city/get-cities`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setCities(response.data);
      } else {
        setCities([]);
        console.log(response.status);
      }
    } catch (error) {
      console.error(error);
      setCities([]);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/service/get-services`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setServices(response.data);
      } else {
        setServices([]);
      }
    } catch (error) {
      console.error(error);
      setServices([]);
    }
  };

  useEffect(() => {
    // Check if any filter is applied
    const isFilterApplied = Object.values(filter).some(value => value !== '');
  
    // If filters are applied, call applyFilter
    // Otherwise, call fetchOrders
    if (isFilterApplied) {
      applyFilter();
    } else {
      fetchJobs();
    }
  }, [currentPage,pageSize]);

  useEffect(() => {
    fetchServices();
    fetchCities();
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const toggleSelection = (_id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(_id)) {
        return prevSelectedRows.filter((rowId) => rowId !== _id);
      } else {
        return [...prevSelectedRows, _id];
      }
    });
  };

  const deleteSelected = async () => {
    setOperationLoading(true)

    try {
      if (selectedRows.length === 0) {
        alert('No jobs selected for deletion');
        return;
      }
      const user = await localStorage.getItem(localStorageAdminKey);

      const response = await axios.delete(`${base_url}/apis/job/delete-jobs`, {
        data: { jobIds: selectedRows },
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setData((prevData) => prevData.filter((row) => !selectedRows.includes(row._id)));
        setSelectedRows([]);
      } else {
        alert(response.data.message);
      }
    setOperationLoading(false)

    } catch (error) {
      console.error(error);
      alert('Something Went Wrong');
    setOperationLoading(false)

    }
  };

  const editRow = (_id) => {
    window.location = `/admin/job/edit/${_id}`;
  };

  const deleteRow = async (_id) => {
    setOperationLoading(true)

    try {
      const user = await localStorage.getItem(localStorageAdminKey);

      const response = await axios.delete(`${base_url}/apis/job/delete_job?job_id=${_id}`, {
        data: { jobIds: selectedRows },
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setData((prevData) => prevData.filter((row) => row._id !== _id));
      } else {
        alert("Something Went Wrong");
      }
    setOperationLoading(false)

    } catch (error) {
      console.error(error);
      alert('Something Went Wrong');
    setOperationLoading(false)

    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
  };

  const applyFilter = async () => {
  
    setOperationLoading(true)
    try {
      const user = await localStorage.getItem(localStorageAdminKey);
      const response = await axios.get(`${base_url}/apis/job/filter_jobs`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        },
        params: {
         ...filter,
          page: currentPage,
          limit: pageSize
        },
      });
     
      if (response.status === 200) {
        setData(response.data.data);
        setTotalPages(Math.ceil(response.data.totalPages / pageSize));
        setTotalJobsCount(response.data.totalCount)

      } else {
        alert(response.data.message);
      }
    setOperationLoading(false)

    } catch (error) {
      console.error(error);
       setOperationLoading(false)

      alert('Something Went Wrong');
    }
  };

  const resetFilter = () => {
    setFilter({
      _id: '',
      added_by: '',
      appointment_datetime: '',
      name: '',
      phone_number: '',
      type_of_work: '',
      updatedAt: '',
      zipcode: '',
      location: ''
    });

    fetchJobs();
  };

  return (
    <div className="p-5 mt-4" style={{overflowX:'scroll'}}>
            <Loading loading={operationLoading}  loaderColor="#3498db" />

      {isLoading ? (
        <center>
          <Spinner color="skyblue" size={25} />
        </center>
      ) : (
        <>
          <div className="row mb-3">
            <div className="col-md-2">
              <label>ID:</label>
              <input
                type="text"
                className="form-control"
                name="_id"
                value={filter._id}
                onChange={handleFilterChange}
              />
            </div>


          


            <div className="col-md-2">
              <label>Title:</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={filter.name}
                onChange={handleFilterChange}
              />
            </div>
            <div className="col-md-2">
              <label>Service:</label>
              <select
                className="form-control"
                name="type_of_work"
                value={filter.type_of_work}
                onChange={handleFilterChange}
              >
                <option value={""}>{services.length > 0 ? 'Filter By Service' : 'Loading...'}</option>
                {services?.map(service => (
                  <option key={service._id} value={service.name}>
                    {service.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <label>Phone Number:</label>
              <input
                type="text"
                className="form-control"
                name="phone_number"
                value={filter.phone_number}
                onChange={handleFilterChange}
              />
            </div>
           
            <div className="col-md-2">
              <label>City:</label>
              <select
                name="location"
                className="form-control"
                value={filter.location}
                onChange={handleFilterChange}
              >
                <option value="">{cities.length > 0 ? 'Filter By City' : 'Loading....'}</option>
                {cities.map(city => (
                  <option key={city._id} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>


            <div className="col-md-2">
              <label>First Appointment Date:</label>
              <input
                type="date"
                className="form-control"
                name="first_appointment_date"
                value={filter.first_appointment_date}
                onChange={handleFilterChange}
              />
            </div>

            <div className="col-md-2">
              <label>Second Appointment Date:</label>
              <input
                type="date"
                className="form-control"
                name="second_appointment_date"
                value={filter.second_appointment_date}
                onChange={handleFilterChange}
              />
            </div>
            

          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <button className="btn btn-primary mr-2" onClick={applyFilter}>
                Apply Filter
              </button>
              <button className="btn btn-secondary" onClick={resetFilter}>
                Reset Filter
              </button>

              <button className="btn btn-danger ml-2" onClick={deleteSelected}>
                Delete Selected
              </button>

              <button className="btn btn-success ml-2" onClick={generateCsv}>
                Generate CSV
              </button>
             

             <div className="mt-3">
            <h5 className='float-right border p-2 bg-primary text-white ml-3' style={{borderRadius:5}}>Total Jobs : {totalJobsCount}</h5>

            <p className='border p-2' style={{borderRadius:5,width:100,float:'right'}}>page size</p>

            <select className='form-control float-right' style={{width:100}} value={pageSize} onChange={(val)=>{
            setPageSize(val.target.value)
            }}>
       
         
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={40}>40</option>
        

          </select>

          </div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr>
                    <th>Select</th>
                    
                    <th>Added By</th>
                    <th>First Appointment Date</th>
                    <th>Second Appointment Date</th>

                    <th>First Time Slot</th>
                    <th>Second Time Slot</th>


                    <th>Created At</th>
                    <th>Title</th>
                    <th>Phone Number</th>
                    <th>Service</th>
                    <th>Updated At</th>
                    <th>City</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((row) => (
                    <tr
                      key={row._id}
                      className={selectedRows.includes(row._id) ? 'selected' : ''}
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(row._id)}
                          onChange={() => toggleSelection(row._id)}
                        />
                      </td>
                      
                      <td>{row.added_by}</td>
                      <td>{new Date(row.first_appointment_date).toLocaleDateString()}</td>
                      <td>{new Date(row.second_appointment_date).toLocaleDateString()}</td>
                      <td>{row.first_timeslot}</td>
                      <td>{row.second_timeslot}</td>
                      
                      <td>{new Date(row.createdAt).toLocaleDateString()}</td>
                      <td>{row.name}</td>
                      <td>{row.phone_number}</td>
                      <td>{row.type_of_work}</td>
                      <td>{new Date(row.updatedAt).toLocaleDateString()}</td>
                      <td>{row.location}</td>
                      <td>
                        <button className="btn btn-info btn-sm" onClick={() => editRow(row._id)}>
                          Edit
                        </button>
                        <button
                          className="btn btn-danger btn-sm ml-2"
                          onClick={() => deleteRow(row._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              ({currentPage}/{totalPages})&nbsp;
              <button
                className="btn btn-secondary mr-2"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminJobList;
