import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import base_url from '../../base_url';
import {localStorageUserKey, localStorageAdminKey} from '../../local_storage_vars';
import { Spinner } from 'react-activity';

export default function AdminUserApproval() {
  const [users, setUsers] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [selectedUserType, setSelectedUserType] = useState(''); // State for selected user type

  // Function to fetch pending users
  const fetchPendingUsers = async () => {
    try {
      const user = await localStorage.getItem(localStorageAdminKey);
      const response = await Axios.get(`${base_url}/apis/user/get_pending_users`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setUsers(response.data);
        setisLoading(false);
      } else {
        alert('Something Went Wrong');
      }
    } catch (error) {
      alert('Something Went Wrong');
    }
  };

  // Function to approve a user
  const approveUser = async (user_id) => {
    try {
      const user = await localStorage.getItem(localStorageAdminKey);
      await Axios.put(
        `${base_url}/apis/user/approve-user`,
        { user_id },
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            authorization: user,
            'Content-Type': 'application/json',
          },
        }
      ).then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          fetchPendingUsers();
        } else {
          alert(res.data.message);
        }
      });
    } catch (error) {
      alert('Something Went Wrong');
    }
  };

  // Function to reject a user
  const rejectUser = async (user_id, reason) => {
    try {
      const user = await localStorage.getItem(localStorageAdminKey);
      await Axios.put(
        `${base_url}/apis/user/reject-user`,
        { user_id, reason },
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            authorization: user,
            'Content-Type': 'application/json',
          },
        }
      ).then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          fetchPendingUsers();
        } else {
          alert(res.data.message);
        }
      });
    } catch (error) {
      alert('Something Went Wrong');
    }
  };

  useEffect(() => {
    fetchPendingUsers();
  }, []);

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h2>Pending Users: ({users.length})</h2> <a href='/admin/parameters' className='btn btn-primary'>Parameters</a>

          {/* Add a filter dropdown */}
          <div className="mb-3">
            <label htmlFor="userTypeFilter" className="form-label">
              Filter by User Type:
            </label>
            <select
              id="userTypeFilter"
              className="form-control"
              value={selectedUserType}
              onChange={(e) => setSelectedUserType(e.target.value)}
            >
              <option value="">All</option>
              <option value="client">Client</option>
              <option value="contractor">Handyman</option>
            </select>
          </div>

          {isLoading === false ? (
            <>
              {users.length < 1 ? (
                <div style={{ marginTop: 20 }}>
                  <h2 style={{ color: 'red' }}>Currently We Don't Have Any Pending Users</h2>
                </div>
              ) : (
                users
                  .filter((user) => !selectedUserType || user.user_type === selectedUserType)
                  .map((user) => (
                    <div key={user._id} className="card mt-4">
                      <div className="card-body">

                        <img src={user.profile_picture?user.profile_picture:require('../../assets/user-circle.png')} className='float-right' style={{borderRadius:'100%',width:100,height:100}}/>
                        <br />
                        <h5 className="card-title">User Type: {user.user_type === 'contractor' ? 'Handyman' : user.user_type}</h5>
                        <p className="card-text">Email: {user.email}</p>
                        <p className="card-text">Email Verified: {user.isVerified ? 'Yes' : 'No'}</p>
                        <p className="card-text">Description: {user.about_me}</p>
                        <p className="card-text">Name: {user.username}</p>
                        {user.contractorEntity?.license ? (
                          <a href={user.contractorEntity.license} target="_blank" className="btn btn-primary">
                            View License 
                          </a>
                        ) : null}

                        {user.contractorEntity?.insurance ? (
                          <a href={user.contractorEntity.insurance} target="_blank" className="btn btn-primary ml-2">
                            View Insurance
                          </a>
                        ) : null}

                        <div className="d-flex justify-content-between mt-3">
                          <button className="btn btn-success" onClick={() => approveUser(user._id)}>
                            Approve
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              const reason = prompt('Reason for disapproval');
                              rejectUser(user._id, reason);
                            }}
                          >
                            Disapprove
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </>
          ) : (
            <center>
              <Spinner size={25} color="skyblue" />
            </center>
          )}
        </div>
      </div>
    </div>
  );
}
