import { useState } from "react"
import { Spinner } from "react-activity"
import base_url from "../../../base_url"
import axios from "axios"
import { useEffect } from "react"


export default function Services({detectPageChange}){

    
        const [data,setData] = useState([])
       
        const [isLoading,setisLoading]= useState(true)
  
        const fetchData = ()=>{
         
          axios.get(`${base_url}/apis/service/get-services`,{
              headers:{
                  "x-api-key": process.env.REACT_APP_API_KEY,
                  
                  'Content-Type': 'application/json', 
              }
          })
          .then(res=>{

            let temp = []
            res.data.map(i=>{
              temp.push({value:i.name,label:i.name})
            })
            temp.sort((a, b) => a.value.localeCompare(b.value))
            
        


            setData(temp)
            setisLoading(false)
          })
          .catch(err=>{
              alert("Something Went Wrong")
          })
        }
       
        useEffect(()=>{
            detectPageChange(window.location.pathname)

            fetchData()
        },[])


        if(!isLoading){
          return(
            <div className="container">
            <h1 className="mt-5 mb-4">Our Services</h1>
            <p>To consider a new Service Category for you please email us at Support@81Handyman.com</p>
            <div className="row">
              {data.map((service, index) => (
                <div className="col-md-4 mb-4" key={index}>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">{service.value}</h5>
                     
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
         )
        }else{
          return <center><Spinner color="skyblue" size={25}/></center>
        }
       
    
}