import React, { useState, useEffect } from 'react';
import axios from 'axios';
import base_url from '../../../base_url';
import { useParams } from 'react-router-dom';

const JobOffers = ({detectPageChange}) => {
    const [offers, setOffers] = useState([]);
    const [error,setError] = useState('');
    const params = useParams()
    useEffect(()=>{
      detectPageChange(window.location.pathname)
  
    },[])
    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const response = await axios.get(`${base_url}/apis/job/job/${params.job_id}/offers`);
                
                setOffers(response.data);
            } catch (err) {
              if(err && err.response){
                setError(err.response.data.message)
              }else{
                setError("Something went wrong")
              }
            }
        };

        fetchOffers();
    }, [params.job_id]);
    
    if(error) return <center className='container mt-5'>
    <h2>{error}</h2>
   </center>
    return (
        <div>
           
            <div className="list-group container" >
                <h2 className='jumbotron p-3'>Handymen Job Offers: </h2>
                {offers.map((offer, index) => (
                     <div className="offer_message mt-2" key={index}>
                     <div className="d-flex align-items-center border" style={{backgroundColor:'#007aff',padding:5,borderRadius:5}}>
                       
                       
                       <a href={`/contractor_profile/${offer.sender}`}><strong style={{ color: 'white' }}>
                         <img
                           src={offer.user[0]?.profile_picture?offer.user[0]?.profile_picture:require('../../../assets/user-circle.png')}
                           style={{ width: 30, height: 30, borderRadius: '50%', marginRight: '10px' }}
                           alt="User Profile"
                         />
                         {offer.user[0]?.username}  
                       </strong>
                       </a>
                       <span className="ml-auto small" style={{color:'white'}}>
                         {new Date(offer.createdAt).toLocaleString()}
                       </span>
                     </div>
             
                    
                       <br />
                       <h4>{offer.job[0]? offer.job[0].name:offer.job_title}</h4>
                       <b style={{ color: 'black' }}>Handyman Offer: </b>
                       <br />
                       <span className="float-left">{offer.message}</span>
                       <br />
                       <span><b style={{ color: 'black' }}>Down Payment:</b> ${offer.down_payment}</span>
                       <br />
                       <span><b style={{ color: 'black' }}>Final Payment:</b> ${offer.final_payment}</span>
             
                       <br />
                       <b style={{ color: 'black' }}>Services: </b><p>{offer.work_need_done}</p>
                      
                       <a
                        href={`/chat/${offer.sender}`} className='btn btn-success' style={{width:'100%'}}>Chat with Handyman</a>
             
             
             
                     </div>
                ))}
            </div>
        </div>
    );
};

export default JobOffers;
