import axios from 'axios';
import React, { useState,useRef } from 'react';
import validator, { isLength } from 'validator';
import Select from 'react-select';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import base_url from '../../../base_url';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import {localStorageUserKey} from '../../../local_storage_vars';

const Profile = ({userDetails})=>{
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [Contractorservices,setServices] = useState([])

  const [servicesData,setServicesData] = useState([])
  const [locations,setLocations] = useState([])
  const [locationsData,setLocationsData] = useState([])
  const [license,setLicense] = useState("")
  const [insurance,setInsurance] = useState("")
  const [newLicense,setNewLicense] = useState(null)
  const [newInsurance,setNewInsurance] = useState(null)
  const [userType,setUserType] = useState("")
  const [isLoading,setisLoading] = useState(false)
  const [zipcode,setZipcode] = useState('')
  const [about_me,setAboutMe] = useState('')
  const [profilePictureFromDatabase,setProfilePictureFromDatabase]= useState('')
  const [review,setReview] = useState(0)
  const [ratingsCount,setRatingsCount] = useState(0)

  const [previewImage,setPreviewImage] = useState('')

  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setSelectedImage(file);
        
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    // Trigger the hidden file input when the image is clicked
    fileInputRef.current.click();
  };

  const logout = async()=>{
    await localStorage.removeItem(localStorageUserKey);
    window.location = "/login"
  }

  function validateForm(){
    if(email == undefined || validator.isEmail(email) == false){
      alert("Invalid Email")
      return false
    }

    if(name == undefined ||name.length<5){
      alert("Name must be at least 5 characters")
      return false
    }

    if(about_me == undefined || about_me.length<10){
      alert("AboutMe must be at least 10 characters")
      return false
    }

    if(userType == "contractor"){
    
      if(companyName == undefined || companyName.length<3){
        alert("Company name must be at least 3 characters")
        return false
      }
      
      if(Contractorservices.length<1){
        alert("Please select services.It is required")
        return false
      }

      if(locations.length<1){
        alert("Please select cities.It is required")
        return false
      }
    }

    return true
 
  }

  const fetchCities = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/city/get-cities`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let temp_cities = []
        response.data?.map(i=>{
          temp_cities.push({value:i.name,label:i.name})
        })
        setLocationsData(temp_cities);
      } else {
        setLocationsData([]);
      }
    } catch (error) {
      console.error(error);
      setLocationsData([]);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/service/get-services`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let temp_services = []
        response.data?.map(i=>{
          temp_services.push({value:i.name,label:i.name})
        })

        setServicesData(temp_services)
      } else {
        
        setServicesData([])
      }
    } catch (error) {
      // console.error(error);
      setServicesData([])
     
    }
  };

  const updateProfile = async() => {
    const user = await localStorage.getItem(localStorageUserKey);
    const is_validated = validateForm();

    if(is_validated == false){return false}
    
    if(!isLoading){
         setisLoading(true)

        let formData = new FormData()
        let services_array = []
        let locations_array = []

       
        Contractorservices.map((i)=>{
          
          services_array.push(i.value)
        })

        locations.map((i)=>{
          locations_array.push(i.value)
        })
        

        formData.append("username",name)
        formData.append("email",email)

        
        if(selectedImage){
       
          formData.append("profile_picture",selectedImage)

        }

        if(phone != undefined && phone.length>0){
          formData.append("phone_number",phone)

        }


        if(zipcode != undefined && zipcode.length>0){
        formData.append("zipcode",zipcode)
        }

        
        formData.append("about_me",about_me)

        if(userType == "contractor"){
          
          formData.append("company_name",companyName)
          formData.append("services",services_array.map(data => data).join(','))
          
          formData.append("locations",locations_array.map(data => data).join(','))


          if(newLicense){
            formData.append("license",newLicense)
          }
          if(newInsurance){
            formData.append("insurance",newInsurance)
          }

        }

        await fetch(base_url+'/apis/auth/update_profile',{
          method:'POST',
          body:formData,
          headers:{
           
              "x-api-key":process.env.REACT_APP_API_KEY,
              "authorization": user
          }
        })
        .then(async(response) => {
          const data =await response.json()
          if(response.status === 200){            
            localStorage.setItem(localStorageUserKey, data.token);
            
            alert("Profile Updated Successfully")

            if(newLicense || newInsurance){
              window.location.reload()
            }
            
          }else{
            alert(data.message)
          }
          setisLoading(false)
        })
        .catch(err=>{
          if(err.response && err.response.data){
            alert(err.response.data.message)
           }else{
            alert("Something went wrong")
           }
          setisLoading(false)

        })
    }
    
  };

  const GetUserDetais = async()=>{
    const user = await localStorage.getItem(localStorageUserKey);

    await axios.get(`${base_url}/apis/auth/profile`,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', 
      },
    })
    .then(res=>{
      if(res.status == 200){
       
        userDetails(res.data.user,res.data.rating,res.data.ratings_count)

        if(res.data.user.user_type == "contractor"){
          
          setReview(res.data.rating)
          setRatingsCount(res.data.ratings_count)
        }

        setEmail(res.data.user.email)
        setName(res.data.user.username)
        setPhone(res.data.user.phone_number)
        setAboutMe(res.data.user.about_me)
        setZipcode(res.data.user.zipcode)

        setProfilePictureFromDatabase(res.data.user.profile_picture)
        setUserType(res.data.user.user_type)
        if(res.data.user.user_type == "contractor"){
          setCompanyName(res.data.user.contractorEntity?.company_name)
          console.log("Contractor Entity: ",res.data.user.contractorEntity)
          setLicense(res.data.user.contractorEntity?.license)
          setInsurance(res.data.user.contractorEntity?.insurance)

          let temp_services = []
          let temp_locations = []
          if(res.data.user.contractorEntity?.services?.split(',').length == 1){
            temp_services.push({value:res.data.user.contractorEntity.services,label:res.data.user.contractorEntity.services})

          }else{
            res.data.user.contractorEntity?.services?.split(',').map(i=>{
              temp_services.push({value:i,label:i})
            })
          }
          setServices(temp_services)

          if(res.data.user.contractorEntity?.locations?.split(',').length == 1){
            temp_locations.push({value:res.data.user.contractorEntity.locations,label:res.data.user.contractorEntity.locations})

          }else{
            res.data.user.contractorEntity?.locations?.split(',').map(i=>{
              temp_locations.push({value:i,label:i})
            })
          }

          setLocations(temp_locations)

        
        }
      }else{
        alert(res.data.message)
      }
    })
    .catch(err=>{
      if(err.response && err.response.data){
        alert(err.response.data.message)
       }else{
        alert("Something went wrong")
       }
    })
  }
 
  

  useEffect(()=>{
    GetUserDetais()
    fetchServices()
    fetchCities()
  },[])
    return(
        
        
            <div className="container mt-5">
          <h2>Account</h2>
         
          <center>
    
           
          
    
              <div
            role="button"
            tabIndex={0}
            onClick={handleImageClick}
            onKeyPress={handleImageClick}
            style={{ cursor: 'pointer' }}
          >
            {selectedImage ? (
              <img
                src={previewImage}
                alt="Preview"
                style={{ width: 150, height:150,borderRadius:200 }}
              />
            ) : (
              <img
                src={profilePictureFromDatabase?profilePictureFromDatabase:require('../../../assets/user-circle.png')} 
                alt="Default"
                style={{ width: 150, height:150,borderRadius:200 }}
              />
            )}
    
    
            <br />
            <small>Click to pick profile picture</small>
          </div>
    
          {/* Hidden file input */}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
    
            {userType && userType == "contractor"?<><Rating
              readonly
              initialValue={review} /> <b>{review}</b> <a href='/account/reviews'> ({ratingsCount}) (View All)</a></>:null}
            
            </center>
    
            <br />
            <br />
    
    
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone:
              </label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
    
    
            
            <div className="mb-3">
            <label htmlFor="zipcode" className="form-label">
                Zipcode:
              </label>
              <input
               onChange={(val)=>setZipcode(val.target.value)}
               type="text"
               className="form-control"
               value={zipcode}
               pattern="\d{5}"
               
               placeholder="Ziplcode"
               />
              </div>
    
    
            {userType == "contractor"?<div className="mb-3">
              <label htmlFor="companyName" className="form-label">
                Company Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </div>:null}



            {userType == "contractor"?<div className="mb-3">
              <label htmlFor="license" className="form-label">
                License
              </label>
              <input
                type="file"
                className="form-control"
                id="license"
               
                onChange={(e) => setNewLicense(e.target.files[0])}
                required
              />
              <a href={license?license:null} className='btn btn-secondary text-white' style={{width:'100%'}}>view current license {license?'(Available)':'(Not Available)'}</a>

            </div>:null}


            {userType == "contractor"?<div className="mb-3">
              <label htmlFor="insurance" className="form-label">
                Insurance
              </label>
              <input
                type="file"
                className="form-control"
                id="insurance"
                
                onChange={(e) => setNewInsurance(e.target.files[0])}
                required
              />

              <a href={insurance?insurance:null} className='btn btn-secondary text-white' style={{width:'100%'}}>view current insurance {insurance?'(Available)':'(Not Available)'}</a>
            </div>:null}
    


    
            {userType == "contractor"?<Select
              options={servicesData}
              isMulti
              defaultValue={Contractorservices}       
              closeMenuOnSelect={false}
              onChange={(val)=>setServices(val)}
              placeholder="Select Services..."
              />:null}
            <br />
    
            {userType == "contractor"?<Select
              options={locationsData}
              isMulti
              defaultValue={locations}       
              closeMenuOnSelect={false}
              onChange={(val)=>setLocations(val)}
              placeholder="Select City..."
              />:null}
    
            <div className="mb-3">
              <label  className="form-label">
                About You:
              </label>
              <textarea
                type="text"
                className="form-control"
                id="about"
                value={about_me}
                onChange={(val)=>setAboutMe(val.target.value)}
                />
            </div>
    
            
          
            <button type="submit" onClick={updateProfile} disabled={isLoading} className="btn btn-success mt-3" style={{width:'100%'}}>
              {isLoading?<Spinner size={18} color='skyblue'/>:null} Save Profile
            </button>


            </div>
            
           )
}

export default Profile