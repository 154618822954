// PricingList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PricingItem from './pricing_item';
import base_url from '../../../base_url';

const PricingList = ({pricings,fetchPricings}) => {
 

 
 

  return (
    <div>
      <h1>Pricings</h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Service</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pricings.map(pricing => (
            <PricingItem key={pricing._id} pricing={pricing} onDelete={fetchPricings} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PricingList;
