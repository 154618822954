import {
    redirect,
    Navigate
  } from "react-router-dom";
import {localStorageAdminKey} from '../local_storage_vars';
 
const AdminAuthRequired = ({children})=>{
    const user = localStorage.getItem(localStorageAdminKey);
    if(!user){
        return <Navigate to={'/login'} />
    }else{
        return children;
    }
}

export default AdminAuthRequired