// FeaturedHandymen.js

import React from "react";
import base_url from "../../base_url";

const FeaturedHandymen = ({ handymen }) => {

  
  return (
    <div className="featured-handymen">
      <h4 className=" border p-2" style={{borderColor:'#007aff',backgroundColor:'#007aff',borderRadius:2,color:'white'}}>Our Featured Handymen</h4>
      <div className="row">
        {handymen?.map((handymen,index) => (
          <div key={index} className="col-lg-3 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src={handymen.userInfo?.profile_picture?handymen.userInfo?.profile_picture:require('../../assets/user-circle.png')}
                className="card-img-top"
                style={{height:200,width:210,borderRadius:'100%',alignSelf:'center',marginTop:10}}
              />
              <div className="card-body">
                <h5 className="card-title" style={{textAlign:'center'}}>{handymen.userInfo?.username.split(' ')[0]}</h5>

                <div className="profile-details">
              
                {handymen.userInfo?.contractorEntity.services?.split(',').map((service, index) => (
                        <span key={index} style={{ backgroundColor: '#007aff', padding: 2, fontSize: 12, marginLeft: 3,color:'white',}}>
                          {service}
                        </span>
                ))}
                </div>

                <br />
              <button className='btn btn-primary' style={{width:'100%'}} onClick={()=>window.location=`/contractor_profile/${handymen.userId}`}>Contact Now</button>

              </div>
                


            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedHandymen;
