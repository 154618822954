import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as regularHeart,faClock } from '@fortawesome/free-regular-svg-icons';
import { Spinner } from 'react-activity';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const maxLength = 150; // Set your preferred maximum length

const ViewInvitation = ({detectPageChange,socket}) => {
  const [job, setJob] = useState(null);
  const [user,setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTruncated, setIsTruncated] = useState(true);
  const [downPayment,setDownPayment] = useState(0)
  const [finalPayment,setFinalPayment] = useState(0) 
  const [message,setMessage] = useState('')
  const [sendOfferLoading,setsendOfferLoading] = useState(false)
  const [is_offer_sent, setIsOfferSent] = useState(true)
  const params = useParams()

  const send_offer = async () => {
  try {
   
    const user = await localStorage.getItem(localStorageUserKey);

    if (message.length < 1) {
      alert("Message cannot be empty");
      return false;
    }

    if (message.length > 1000) {
      alert("Message must not be more than 1000 characters");
      return false;
    }

    if (downPayment < 5 || finalPayment < 5) {
      alert("Down payment and final payment must not be less than 5$");
      return false;
    }

    

    
    setsendOfferLoading(true)
   
    setTimeout(async()=>{
      const offer = await socket.emit('new_message', {
        job_id: job._id ,
        sender: user,
        receiver: job.added_by,
        message: message,
        is_offer: true,
        down_payment: downPayment,
        final_payment: finalPayment,
        work_need_done: job.type_of_work,
      })
      

      if(offer._callbacks){
        setsendOfferLoading(false)
        setMessage('')
        setDownPayment(0)
        setFinalPayment(0)
        toast.success('Offer Sent Successfully')
        window.location = `/chat/${job.added_by}`
      }else{
      alert("Failed to send offer. Please try again to send offer");
      setsendOfferLoading(false)
      }
    },1200)
    
   
  } catch (err) {
   
    setsendOfferLoading(false)

    alert("Failed to send offer. Please try again to send offer");

  }
};

  useEffect(() => {
    detectPageChange(window.location.pathname)

    const fetchJob = async () => {
      try {
        const user = await localStorage.getItem(localStorageUserKey);

        // Make a GET request to the API endpoint
        const response = await axios.get(`${base_url}/apis/job/view_job_invitation?job_id=${params.invitation_id}`,{
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
             "authorization": user,
              'Content-Type': 'application/json',
          }
        });
      
        setJob(response.data.job);
        setUser(response.data.user);
        setIsOfferSent(response.data.is_offer_sent)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching job:', error);
        setError(error.response?.data?.message || 'Something went wrong');
        setLoading(false);
      }
    };

    fetchJob();
  }, []);
  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const showDescription =()=>{
    
    const description = job.description 
    const displayDescription = isTruncated ? description?.slice(0, maxLength) : description;
    return <p>{displayDescription} {description&&description.length>150&&<b style={{color:"#007aff",cursor:'pointer'}} onClick={toggleTruncate}>{isTruncated?' Readmore':' Read Less'}</b>}</p>
  }
  if (loading) {
    return <center className='mt-3'>
      <Spinner size={25} color='skyblue'/>
    </center>
  }

  if (error) {
    return <center className='mt-5'>
       <h1>{error}</h1>
       <br />

       <button className='btn btn-primary' onClick={()=>window.history.back()}>Go Back</button>
    </center>;
  }


  if(is_offer_sent == false){

  return (
    <div>
      <h2 className='border p-3' style={{borderRadius:10}}>Invitation</h2>


      {job ? (
         <div  className="col mb-4">
         <div className="card h-100">
     
         <div className="card-header">
        
         <img style={{width:30,height:30,borderRadius:50}}  src={user.profile_picture?user.profile_picture:require('../../../assets/user-circle.png')} alt="User" className="user-image" />
        
         <b className="card-title">{' '}{ user.username}</b>
         <b className="card-text float-right">Posted on: {new Date(job.createdAt).toLocaleDateString()}</b>
     
         
       </div>

       <div className="card-body">
       
       <h4 className="card-title">{job.name}</h4>
    
     <p className="card-text">
       <strong>Phone Number:</strong> {job.phone_number}
       <br />
       <strong>Service:</strong> {job.type_of_work}
      
       <br />
       <strong>Zipcode:</strong> {job.zipcode}
       
       <br />
       <strong>City:</strong> {job.location}

       <br />
       <strong>Address:</strong> {job.address}
     </p>
     <div className='border p-2'>
     <strong>description:</strong>
     {showDescription(job)}
     </div>

     <div className='border p-2 mt-2' style={{borderRadius:5}}>
     <b> <FontAwesomeIcon
       size={'lg'}
         icon={faClock}
         color={'blue'}
       /> APPOINTMENT OPTION # 1</b>
       <br />
     <><b>Date: </b>{job.first_appointment_date}</>
     <br />

     <><b>Time: </b>{job.first_timeslot}</>

     <hr />

     <b>  <FontAwesomeIcon
       size={'lg'}
         icon={faClock}
         color={'blue'}
       /> APPOINTMENT OPTION # 2</b>
       <br />

     <><b>Date: </b> {job.second_appointment_date}</>
     <br />

     <><b>Time: </b> {job.second_timeslot}</>

     </div>
     <br />
        <b>Write your offer:</b>
      <textarea className='form-control' placeholder='describe...' value={message} onChange={(val)=>setMessage(val.target.value)}></textarea>
       

        <div className='border p-2' style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
            <div style={{width:'45%'}}>
                <b>Down payment: </b>
                <input className='form-control' placeholder='Down Payment in usd'  min="1"
                  step="1" type='number' value={downPayment} onChange={(val)=>setDownPayment(val.target.value)}/>
            </div>

            <div style={{width:'45%'}}>
                <b>Final payment: </b>
                <input placeholder='Final Payment in usd' className='form-control'  min="1"
                  step="1" type='number' value={finalPayment} onChange={(val)=>setFinalPayment(val.target.value)}/>
            </div>
        </div>


        <br />
        <br />

      
     <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:10}}>

     <Link  to={`/chat/${user._id}`} className="btn btn-primary float-right mr-4">
       Chat With Client
     </Link>
    

      <button className='btn btn-success' disabled={sendOfferLoading || is_offer_sent} onClick={send_offer}>{sendOfferLoading?<Spinner size={15} color='skyblue'/>:'Submit offer'}</button>
   

     </div>
   </div>

       </div>
        </div>
      ) : (
        <div>No job found</div>
      )}
    </div>
  );
}else{
  return <center className='container mt-5'>
    <h2>Offer Already Sent</h2>
    <br />

    <button className='btn btn-primary' onClick={()=>window.history.back()}>Go Back</button>
  </center>
}
};

export default ViewInvitation;
