import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import base_url from '../../../base_url'
const token = window.location.pathname.split('/')[2]
export default class VerifyEmail extends React.Component{
    state = {
        is_verified:false
    }
    handleVerifyClick = ()=>{
        const formData = new FormData()
        formData.append("token",token)
       
        fetch(base_url+'/apis/auth/verify_email',{
          method:'POST',
          body:formData,
          headers:{
              "x-api-key":process.env.REACT_APP_API_KEY
          }
        })
        .then(response => response.json())
        .then(response=>{
            if(response.is_verified == true){
                this.setState({is_verified:true})
            }else{
                this.setState({is_verified:false})

                alert(response.message)
            }
        })
        .catch(error=>{
            this.setState({is_verified:false})

            alert(error.message)
        })
    }
   
    render(){
        if(this.state.is_verified == false){
            return(
                <div className="container">
                <div className="row justify-content-center mt-5">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Email Verification</h2>
                        <p className="card-text">
                          Please click the button below to verify your email address.
                        </p>
                        <button
                          className="btn btn-primary"
                          onClick={this.handleVerifyClick}
                        >
                          Verify Your Email
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
        }else{
        return(
            <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Email Verification</h2>
                    <p className="card-text">
                     Your Email Has Been Verified.Click Below Button To Login
                    </p>
                    <Link
                      className="btn btn-primary"
                      to={'/login'}
                    >
                      Go To Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        }
      
    }
}