import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/logo-4-sticky.webp';
import base_url from '../../base_url';
import {localStorageUserKey} from '../../local_storage_vars';
import logoWhite from '../../assets/logo300white.png';
import axios from 'axios';

export default function Header({ socket, user, no_unread_msgs }) {
  const location = useLocation();
  const is_loggedin = localStorage.getItem(localStorageUserKey);
  const navbarRef = useRef(null);

  const CallUsNow = () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobile) {
      window.location.href = 'tel:+18142639626';
    } else {
      const whatsappLink = `https://wa.me/+14076875654`;
      window.open(whatsappLink, '_blank');
    }
  };

  const handleNavClick = () => {
    if (navbarRef.current) {
      navbarRef.current.classList.remove('show');
    }
  };

  return (
    <header className="main-header style4" style={{ zIndex: 1, }}>
      <div className="header-top-style4">
        <div className="container">
          <div className="outer-box clearfix" style={{ display: 'block', border: '0px  Green Solid' }}>
            <div className="logo top-left float-left" style={{ border: '0px Magenta Solid'}}>
              <Link to="/">
                <img src={logoWhite} alt="81Handyman" style={{ height: 130 }} title="" />
              </Link>
            </div>
            <div className="top-right float-right btn btn-warning" style={{ display: 'block', marginBottom: 20, border: '0px Red Solid' }}>
              <i className="fa fa-phone" aria-hidden="true" style={{ fontSize: 22 }}>
                <small style={{ fontFamily: 'monospace' }}> +18142639626</small>

               
              </i>
            </div>
            <div className="header-top-menu-style4" style={{ width: '100%', }}>
              <div className="float-right" style={{ display: 'block', width: '100%', border: '0px Aquamarine Solid' ,}}>
                <div className="header-lawer-content" >
                  <div className="nav-outer text-right clearfix" >
                    <nav className="main-menu style2 s4 dropdown-menus2 navbar-expand-lg" >
                      <div className="navbar-header">
                        <button
                          type="button"
                          className="navbar-toggle menu-toggle-button"
                          data-toggle="collapse"
                          data-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                        </button>
                      </div>
                      <div
                        className="navbar-collapse collapse clearfix"
                        id="navbarSupportedContent"
                       
                        ref={navbarRef}
                      >
                        <ul className="navigation clearfix" style={{ margin: 0 }}>
                          {!user ? (
                            <>
                              {is_loggedin ? (
                                <li>
                                  <Link className="last-border" to="/home" style={{ color: 'white' }} onClick={handleNavClick}>
                                    Dashboard
                                  </Link>
                                </li>
                              ) : (
                                <li>
                                  <Link className="last-border" to="/" style={{ color: 'white' }} onClick={handleNavClick}>
                                    Home
                                  </Link>
                                </li>
                              )}
                              <li>
                                <Link className="last-border" to="/about_us" style={{ color: 'white' }} onClick={handleNavClick}>
                                  About Us
                                </Link>
                              </li>
                              <li>
                                <Link to="/services" style={{ color: 'white' }} onClick={handleNavClick}>
                                  Services
                                </Link>
                              </li>
                              <li>
                                <Link to="/pricings" style={{ color: 'white' }} onClick={handleNavClick}>
                                  Pricing
                                </Link>
                              </li>
                              <li>
                                <Link to="/cities" style={{ color: 'white' }} onClick={handleNavClick}>
                                  Cities
                                </Link>
                              </li>
                              <li>
                                <Link className="last-border" to="/contact_us" style={{ color: 'white' }} onClick={handleNavClick}>
                                  Contact Us
                                </Link>
                              </li>
                              {/* {is_loggedin ? null : (
                                <li>
                                  <Link className="last-border" to="/login" style={{ color: 'white' }} onClick={handleNavClick}>
                                    Login
                                  </Link>
                                </li>
                              )} */}
                             
                            </>
                          ) : (
                            <>
                              {is_loggedin ? (
                                <>
                                  <li>
                                    <Link className="last-border" to="/home" style={{ color: 'white' }} onClick={handleNavClick}>
                                      Dashboard
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/favorites" style={{ color: 'white' }} onClick={handleNavClick}>
                                      Favorites
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/services" style={{ color: 'white' }} onClick={handleNavClick}>
                                      Services
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/pricings" style={{ color: 'white' }} onClick={handleNavClick}>
                                      Pricing
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/cities" style={{ color: 'white' }} onClick={handleNavClick}>
                                      Cities
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/orders" style={{ color: 'white' }} onClick={handleNavClick}>
                                      Work orders
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/chat/0" style={{ color: 'white' }} onClick={handleNavClick}>
                                      Messages
                                      {no_unread_msgs > 0 ? (
                                        <span style={{ padding:5, height: 22, color: 'white', backgroundColor: 'red', borderRadius: '100%', position: 'absolute', lineHeight: '15px'
                                      }}>
                                          {no_unread_msgs}
                                        </span>
                                      ) : null}
                                    </Link>
                                  </li>
                                  {/* {user ? (
                                    <li>
                                      <Link to="/account" style={{ color: 'white' }} onClick={handleNavClick}>
                                        <img src={user.profile_picture ? user.profile_picture : require('../../assets/user-circle.png')} style={{ width: 40, height: 40, borderRadius: '100%' }} />
                                        <small style={{ color: 'white', fontWeight: 'bold' }}>{' '} {user?.username}</small>
                                      </Link>
                                    </li>
                                  ) : null} */}

                                   

                                </>
                              ) : null}
                            </>
                          )}
                        </ul>
                      </div>
                    </nav>
                  </div>
                  <div  className="nav-outer-login text-right clearfix" style={{zIndex:"30"}}>
                    <nav className="dropdownZ" >
                       
                        <ul className="navigation clearfix">
                        {!user ? (
                            <>
                            {is_loggedin ? null : (
                                <li className="menu-login-item dropdownZ">
                                  <div style={{ color: 'white', fontWeight: 'bold', marginTop:"7px" }}>LOG IN &#9660;</div>
                                  {/* <Link className="last-border dropdown-toggle" to="/login" style={{ color: 'white' }} onClick={handleNavClick}>  
                                    Log In
                                  </Link> */}
                                  <ul className="dropdown-login-menu dropdownZ">
                                  <li className="dropdown-login-item">
                                      <div className='dropdownZ-text'>Want an <b>81Handyman.com</b> account? </div>
                                      <Link to="/register"  className="dropdown-login-item"   onClick={handleNavClick}>Please click here!</Link>
                                       
                                  </li>
                                  <li className="dropdown-login-item">
                                      <div className='dropdownZ-text'>Already a member? </div>
                                      <Link to="/login"  className="dropdown-login-item"  onClick={handleNavClick}>Log In</Link>
                                       
                                  </li>
                                  </ul>
                                  
                                </li>
                              )}
                            </>
                        ):(
                          <>
                          {user ? (
                                    <li  className="menu-login-item dropdownZ">
                                      <div>
                                        <img src={user.profile_picture ? user.profile_picture : require('../../assets/user-circle.png')} style={{ width: 40, height: 40, borderRadius: '100%' }} />
                                        <small style={{ color: 'white', fontWeight: 'bold' }}>{' '} {user?.username} &#9660;</small>
                                      </div>
                                      <ul className="dropdown-login-menu dropdownZ">
                                      <li className="dropdown-login-item">
                                         <Link  to="/account"  className="dropdown-login-item"  onClick={handleNavClick}>Account</Link>
                                      </li>
                                      <li className="dropdown-login-item">
                                          <Link to="/login" className="dropdown-login-item"   onClick={()=>{localStorage.removeItem(localStorageUserKey); window.location = "/login";}}>Logout</Link>
                                      </li>
                                     
                                      </ul>
                                  
                                    </li>
                                  ) : null}
                          </>
                        )
                      }
                        </ul>

                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
