import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-activity';
import base_url from '../../../base_url';
import {localStorageAdminKey} from '../../../local_storage_vars';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'react-fullscreen-loading';

const AdminOrderList = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [operationLoading,setOperationLoading] = useState(false)
  const [cities, setCities] = useState([]);
  const [services, setServices] = useState([]);
  const [pageSize,setPageSize] = useState(5)
  const [totalOrdersCount, setTotalOrdersCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    order_number: '',
    client_id: '',
    contractor_id: '',
    property_address: '',
    name: '',
    work_need_done: '',
    requirements: '',
    created_by: '',
    status: '',
   
    payment_method: '',
    price: '',
  });


  const generateCsv = () => {
    if(data.length<1){
      alert("You dont have any record to generate csv")
      return false
    }
    setOperationLoading(true)
    const csvContent = 'data:text/csv;charset=utf-8,' +
      Object.keys(data[0]).join(',') + '\n' +
      data.map(row => Object.values(row).join(',')).join('\n');
      
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setOperationLoading(false)

  };


  const fetchOrders = async () => {
    try {
      const user = await localStorage.getItem(localStorageAdminKey);
      const response = await axios.get(`${base_url}/apis/order/get_all_orders?page=${currentPage}&&pageSize=${pageSize}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
       
        setData(response.data.orders);
        setTotalPages(response.data.totalPages);
        setTotalOrdersCount(response.data.totalOrdersCount)
        setIsLoading(false);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
      alert('Something Went Wrong');
    }
  };



  // Fetch cities and services on component mount
  useEffect(() => {
    fetchOrders();
    
    fetchCities();
    fetchServices();
  }, []);

  const fetchCities = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/city/get-cities`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setCities(response.data);
      } else {
        setCities([]);
      }
    } catch (error) {
      console.error(error);
      setCities([]);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/service/get-services`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setServices(response.data);
      } else {
        setServices([]);
      }
    } catch (error) {
      console.error(error);
      setServices([]);
    }
  };



  useEffect(() => {
  const isFilterApplied = Object.values(filters).some(value => value !== '');
  if (isFilterApplied) {
    applyFilter();
  } else {
    fetchOrders();
  }
  }, [currentPage,pageSize]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const applyFilter = async () => {
    try {
    setOperationLoading(true)

      const user = await localStorage.getItem(localStorageAdminKey);
      const response = await axios.get(`${base_url}/apis/order/filter_orders`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        },
        params: {
          ...filters,
          page: currentPage,
          pageSize: pageSize
        }, // Include filters as params in the request
      });
  
      if (response.status === 200) {
        setData(response.data.filteredOrders);
        setTotalPages(response.data.totalPages);
        setTotalOrdersCount(response.data.totalOrdersCount)

      } else {
        alert(response.data.message);
      }

    setOperationLoading(false)

    } catch (error) {
      console.error(error);
      alert('Something Went Wrong');
    setOperationLoading(false)

    }
  };
  const resetFilter = () => {
    
    setFilters({
      order_number: '',
      client_id: '',
      contractor_id: '',
      property_address: '',
      name: '',
      work_need_done: '',
      requirements: '',
      created_by: '',
      status: '',
     
      payment_method: '',
      price: '',
    });
    fetchOrders()
  };


  const deleteRow = async (_id) => {
    try {
    setOperationLoading(true)

        const user = await localStorage.getItem(localStorageAdminKey);

        // Use axios.delete to send a DELETE request to the API endpoint
        const response = await axios.delete(`${base_url}/apis/order/delete-order/${_id}`, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'authorization': user,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            setData((prevData) => prevData.filter((row) => row._id !== _id));
        } else {
            alert('Something Went Wrong');
        }
    setOperationLoading(false)

    } catch (error) {
        console.error(error);
        alert('Something Went Wrong');
    setOperationLoading(false)

    }
};


  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const toggleSelection = (_id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(_id)) {
        return prevSelectedRows.filter((rowId) => rowId !== _id);
      } else {
        return [...prevSelectedRows, _id];
      }
    });
  };

  const deleteSelected = async () => {
    try {
      if (selectedRows.length === 0) {
        alert('No orders selected for deletion');
        return;
      }
      setOperationLoading(true)

      const user = await localStorage.getItem(localStorageAdminKey);
      const response = await axios.delete(`${base_url}/apis/order/delete-orders`, {
        data: { orderIds: selectedRows },
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setData((prevData) => prevData.filter((row) => !selectedRows.includes(row._id)));
        setSelectedRows([]);
      } else {
        alert(response.data.message);
      }
    setOperationLoading(false)

    } catch (error) {
     
      alert('Something Went Wrong');
    setOperationLoading(false)

    }
  };

  return (
    <div className="p-5 mt-4" style={{overflowX:'scroll'}}>
            <Loading loading={operationLoading}  loaderColor="#3498db" />

      {isLoading ? (
        <center>
          <Spinner color="skyblue" size={25} />
        </center>
      ) : (
        <>
          <div className="row mb-3">
            {/* Filter input fields for each column */}
            <div className="col-md-2">
              <label>Order Number:</label>
              <input
                type="text"
                className="form-control"
                name="order_number"
                value={filters.order_number}
                onChange={handleFilterChange}
              />
            </div>
            <div className="col-md-2">
              <label>Client ID:</label>
              <input
                type="text"
                className="form-control"
                name="client_id"
                value={filters.client_id}
                onChange={handleFilterChange}
              />
            </div>
            <div className="col-md-2">
              <label>Contractor ID:</label>
              <input
                type="text"
                className="form-control"
                name="contractor_id"
                value={filters.contractor_id}
                onChange={handleFilterChange}
              />
            </div>
            <div className="col-md-2">
              <label>Property Address:</label>
              <input
                type="text"
                className="form-control"
                name="property_address"
                value={filters.property_address}
                onChange={handleFilterChange}
              />
            </div>
           
          
        <div className="col-md-2">
          <label>Service:</label>
          <select
            name="service"
            className="form-control"
            value={filters.service}
            onChange={handleFilterChange}
          >
            <option value="">Select Service</option>
            {services.map((service) => (
              <option key={service._id} value={service.name}>
                {service.name}
              </option>
            ))}
          </select>
        </div>



        <div className="col-md-2">
          <label>Status:</label>
          <select
            name="status"
            className="form-control"
            value={filters.status}
            onChange={handleFilterChange}
          >
            <option value="">Select Status</option>
            <option  value={'completed'}>completed</option>


            <option value={'canceled'}>canceled</option>
            <option value={'pending'}>pending</option>
            
          </select>
        </div>


            {/* ... Add similar filter inputs for other columns ... */}
          
          </div>

          {/* Table header with column names */}
          <div className="row mb-3">
            <div className="col-md-12">
              <button className="btn btn-primary mr-2" onClick={applyFilter}>
                Apply Filter
              </button>
              <button className="btn btn-secondary" onClick={resetFilter}>
                Reset Filter
              </button>

              <button className="btn btn-danger ml-2" onClick={deleteSelected}>
                Delete Selected
              </button>

              <button className="btn btn-success ml-2" onClick={generateCsv}>
                Generate CSV
              </button>



            <div className='mt-3'>

            <h5 className='float-right border p-2 bg-primary text-white ml-3' style={{borderRadius:5}}>Total Orders : {totalOrdersCount}</h5>
            <p className='border p-2' style={{borderRadius:5,width:100,float:'right'}}>page size</p>

            <select className='form-control float-right' style={{width:100}} value={pageSize} onChange={(val)=>{
            setPageSize(val.target.value)
            }}>


          
         
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={40}>40</option>
        

          </select>
          </div>

            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Order No</th>
                    <th>Client</th>
                    <th>Contractor</th>
                    <th>Property Address</th>

                    <th>Retailer Per</th>

                    <th>Order Requirements</th>
                   

                    <th>Service</th>
                    <th>Status</th>

                   
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((row,index) => (
                    <tr
                      key={index}
                      className={selectedRows.includes(row._id) ? 'selected' : ''}
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(row._id)}
                          onChange={() => toggleSelection(row._id)}
                        />
                      </td>
                      <td>{row.status == "completed"?<a href={`/admin/order/reviews/${row._id}`}>{row.order_number}</a>:row.order_number}</td>
                      <CopyToClipboard onCopy={()=>{
                         toast.success("Client Id Copied", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                          });
                      }} text={row.client_id}>
                      
                      <td style={{cursor:'pointer'}}> 
                        {row.client[0]?row.client[0].username:<b style={{color:'red'}}>not available</b>}
                        </td>
                        </CopyToClipboard>

                        <CopyToClipboard onCopy={()=>{
                         toast.success("Contractor Id Copied", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                          });
                      }} text={row.contractor_id}>
                      
                      <td style={{cursor:'pointer'}}> 
                        {row.contractor[0]?row.contractor[0].username:<b style={{color:'red'}}>not available</b>}
                        </td>
                        </CopyToClipboard>



                      <td>{row.property_address}</td>
                      <td>{row.retailer_percentage?row.retailer_percentage['$numberDecimal']:'#'}</td>

                      <td>{row.requirements.slice(0,20)} {row.requirements.length>20?<b style={{cursor:'pointer',color:'blue',fontSize:20}} onClick={()=>alert(row.requirements)}>...</b>:null}</td>

                      
                    
                      <td>{row.work_need_done}</td>
                      <td>{row.status == 'pending'?'progress':row.status}</td>

                      
                      <td>
                        <button
                          className="btn btn-danger btn-sm ml-2"
                          onClick={() => deleteRow(row._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                ({currentPage}/{totalPages})&nbsp;
                <button
                  className="btn btn-secondary mr-2"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      )}

    <ToastContainer />
    </div>
  );
};

export default AdminOrderList;
