import React from 'react'
import {
    BrowserRouter as Router,
    Routes as Switch,
    Route
  } from "react-router-dom";
import LandingPage from '../pages/landing_page';
import Services from '../pages/main/services';
import Header from '../components/main/header';
import Register from '../pages/auth/register';
import Login from '../pages/auth/login';
import AboutUs from '../pages/main/about_us';
import ContractorProfile from '../pages/main/contractor_profile';
import Contractors from '../pages/main/contractors';
import Chat from '../pages/main/chat';
import Orders from '../pages/main/order';
import Account from '../pages/main/account';
import MembershipPage from '../pages/main/memberships';
import AdminServices from '../pages/admin/services';
import AdminUserApproval from '../pages/admin';
import AdminHeader from '../components/admin/header';
import SingleOrderPage from '../pages/main/single_order';
import CompleteOrder from '../pages/main/complete_order';
import PostJobPage from '../pages/main/post_job';
import Home from '../pages/main/home';
import ContactUsPage from '../pages/main/contact_us';
import AuthRequired from '../middlewares/require_auth';
import AdminAuthRequired from '../middlewares/admin_auth_required';
import VerifyEmail from '../pages/auth/verify_email';
import MemberShipRequired from '../middlewares/membership_required';
import GuestRequire from '../middlewares/guest_require';
import SomethingWentWrong from '../pages/something_wentwrong';
import ChangePassword from '../pages/main/account/change_password';
import EditJob from '../pages/main/edit_job';
import AdminMemberships from '../pages/admin/memberships';
import {io} from 'socket.io-client';
import base_url from '../base_url';
import AccountReviews from '../pages/main/account/reviews';
import UserList from '../pages/admin/users_list';
import AdminCities from '../pages/admin/cities';
import OrderList from '../pages/admin/orders';
import AdminOrderReviews from '../pages/admin/orders/review';
import AdminJobList from '../pages/admin/jobs';
import ResetPassword from '../pages/auth/reset_password';
import ResetPasswordVerifyEmail from '../pages/auth/reset_password/verify_email';
import AdminEditJob from '../pages/admin/edit_job';
import Favorites from '../pages/main/favorites';
import UpdateUser from '../pages/admin/users_list/update_user';
import NotFound from '../pages/NotFound';
import TermsAndConditions from '../pages/TermsAndConditions';
import PaymentMethodSuccess from '../pages/main/account/payment_method_success';
import PaymentMethods from '../pages/main/account/payment_method';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import messageAudio from '../assets/message.mp3'
import OfferAccepted from '../pages/main/chat/offer_accepted';
import Parameters from '../pages/admin/parameters';
import Cities from '../pages/main/cities';
import FeaturedHandymen from '../pages/admin/featured_handymen';
import EditOffer from '../pages/main/chat/edit_offer';
import AdminPricing from '../pages/admin/admin_pricings';
import Pricings from '../pages/main/pricings';
import JobOffers from '../pages/main/JobOffers';
import AdminTransactions from '../pages/admin/transactions';
import ViewInvitation from '../pages/main/view_invitation';
import Footer from '../components/footer';
import WarningAlert from '../components/main/WarningAlert';
import {localStorageUserKey} from '../local_storage_vars';

const user = localStorage.getItem(localStorageUserKey);

const socket = io(base_url, {
  query:{user:user}
});

export default class Routes extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            user:null,
            number_of_unread_msgs:0,
        }
        
        // Create a ref using React.createRef()
        this.decodedTokenRef = React.createRef();
        this.shouldLog = React.createRef(true)
        this.notificationMessageId = React.createRef(null)
        this.audioRef = React.createRef();
        
      }

      playAudio = () => {
        if (this.audioRef.current) {
          this.audioRef.current.play()
          .catch(error => {
            console.error('Failed to play audio:', error);
          });
        }
      };

      checkTokenExistence = async()=>{

        await fetch(base_url+'/apis/auth/checkuser_in_database',{
            method:'GET',
           
            headers:{
                "x-api-key":process.env.REACT_APP_API_KEY,
                "authorization":user
            }
          })
          .then(res=>res.json())
          .then(response=>{
           if(response.found === false){
            localStorage.removeItem(localStorageUserKey);
            window.location = "/login"
           }else{
           
            this.setState({user:response.user,number_of_unread_msgs:response.no_unread_msgs})
           }
          })
          .catch(error=>{
           console.log(error)

          })                                                                                                                          
      }

     verifyToken = async () => {
        const token = await localStorage.getItem(localStorageUserKey);
        try {
          const response = await axios.get(`${base_url}/apis/auth/verify_token_after_login`, {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "authorization": token,
              'Content-Type': 'application/json',
            },
          });
          const responseData = response.data;
          console.log("***verify token responsedata: ***", responseData);

          if (response.status === 200) {
            if (responseData.found) {
              console.log("***verify token user: ***", responseData.user);
             return responseData.user
            
            } else {
             
                return null
            }
          }
        } catch (err) {
            return null
    
        }
      };



  
      NotificationComponent = ({ username,body,user_image,sender_id,not_for }) => {
        if(not_for === "new_order"){
          return (
            <div onClick={()=>this.handleToastClick(sender_id)}>
             
              <p style={{color:'white',borderColor:'#007aff',backgroundColor:'#007aff',padding:5,marginTop:5,borderRadius:10}} className='border' >{body}</p>
            </div>
          );
        }else{
          return (
            <div onClick={()=>this.handleToastClick(sender_id)}>
              <img src={user_image?user_image:require('../assets/user-circle.png')} style={{width:30,height:30,borderColor:'white',borderRadius:'100%'}} alt="" /> {username} sent you message
  
              <p style={{color:'white',borderColor:'#007aff',backgroundColor:'#007aff',padding:5,marginTop:5,borderRadius:10}} className='border' >{body.slice(0,20)}....</p>
            </div>
          );
        }
       
      };
      
      handleToastClick = (sender_id) => {
        
        window.location = `${process.env.PUBLIC_URL}/chat/${sender_id}`
        // Perform your desired action here
      };

      
      async componentDidMount() {
        if (user) {
          await this.checkTokenExistence();
          
          let _user = await this.verifyToken();

          if (!socket.connected && this.shouldLog.current) {
            
        
             this.shouldLog.current = false

             if(_user){
              socket.connect()
             }
            
          }

            // Save a reference to the function passed to socket.on
            this.handleNewMessages = (data) => {
              
              if (data.type === "broadcast" && data.receiver === _user._id) {
              
                // Your toast logic here
                const { sender_username, message, sender_image,sender_id,message_id } = data;
                

                if(this.notificationMessageId.current !== message_id){
                  this.showToast({
                    username: sender_username,
                    body: message,
                    sender_id:sender_id,
                    user_image: sender_image,
                    message_id:message_id,
                    not_for:'message'
                  });
                  this.notificationMessageId.current = message_id
                }
                // Assuming you have a method to show a toast message
                
                this.playAudio()

                this.checkTokenExistence()
              }
            };


            this.handleNewOrder =(data)=>{
              console.log(data)
              if (data.type === "broadcast" && data.receiver === _user._id) {
                console.log("Notification for new order")
                 // Your toast logic here
                 const { offer_id,sender_id } = data;
                

                 if(this.notificationMessageId.current !== offer_id){
                   this.showToast({
                    
                     body: 'You have got new order!',
                     message_id:offer_id,
                     sender_id:sender_id,
                     not_for:'new_order'
                   });
                   this.notificationMessageId.current = offer_id
                 }
                 // Assuming you have a method to show a toast message
                 
                 this.playAudio()
              }
            }
      
            // Remove previous listener before adding a new one
            if(_user){
              socket.on("new_messages", this.handleNewMessages);
              socket.on("offer_accepted_notification", this.handleNewOrder);

            }
           

        }



        
      }
      
      
      showToast = ({ username, body, user_image,sender_id,message_id,not_for }) => {
        
        
        // Assuming you have a method to show a toast message
        toast(<this.NotificationComponent username={username} body={body} sender_id={sender_id} not_for={not_for} user_image={user_image} />, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          
          toastId:message_id,
          theme: "dark",
        });

       
       
        
      };
    


      detectPageChange = (page_name)=>{
       

        if(page_name !== "chat"&& user){
          
        
          socket.emit("left_chatpage",{
            user:user
          })
        }else{
         
          socket.emit("open_chatpage",{
            user:user
          })

        }



      }

    render(){
      const { user  } = this.state;
      
        return(
            <Router>
              
                {!window.location.pathname.includes('admin')?
                <div>
                  {window.location.pathname !== "/terms-conditions"? <Header  socket={socket}  user={user} no_unread_msgs={this.state.number_of_unread_msgs}/>:null}
                </div>
               :<AdminHeader />}
               
                {user && window.location.pathname !== "/terms-conditions"?
                  <WarningAlert>
                  By accepting our membership <a href="/terms-conditions" target="_blank"  >terms and conditions</a> you agree that all payments for services are to be made through 81Handyman.com only.
                </WarningAlert>
              :null}
                
                <Switch>
                    <Route path="*" element={<NotFound detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>} />

                    <Route exact path='/' element={
                    <GuestRequire>
                    <LandingPage detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </GuestRequire>
                    } />


                    <Route exact path='/home'  element={
                    <AuthRequired>
                    <MemberShipRequired>
                    <Home socket={socket} detectPageChange={(page_name)=>this.detectPageChange(page_name)} user={user}/>

                    </MemberShipRequired>
                    </AuthRequired>
                    } />

                    <Route exact path='/services'  element={<Services  detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>} />

                    
                    <Route exact path='/about_us' element={<AboutUs detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>} />

                    <Route exact path='/contact_us' element={<ContactUsPage detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>} />


                    <Route exact path='/contractors/:job_id' element={
                    <AuthRequired>
                    <MemberShipRequired>

                    <Contractors detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />


                    <Route exact path='/job/offer/:job_id' element={
                    <AuthRequired>
                    <MemberShipRequired>

                    <JobOffers detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />

                    <Route exact path='/contractor_profile/:id' element={
                    <AuthRequired>
                    <MemberShipRequired>

                    <ContractorProfile detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    
                     } />

                    <Route exact path='/chat/:user_id' element={
                    <AuthRequired>
                    <MemberShipRequired>

                    <Chat socket={socket} user_data={user} detectPageChange={(page_name)=>this.detectPageChange(page_name)} CallforMessageCount={this.checkTokenExistence} number_of_unread_msgs={this.state.number_of_unread_msgs}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    
                    } />


                    {/* <Route exact path='/create-offer/:chat_id' element={
                    <AuthRequired>
                    <MemberShipRequired>

                    <CreateOffer socket={socket} user={this.state.user}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    
                    } /> */}

                    <Route exact path='/edit_offer/:offer_id' element={
                    <AuthRequired>
                    <MemberShipRequired>

                    <EditOffer socket={socket} detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    
                    } />



                    <Route exact path='/orders' element={
                    <AuthRequired>
                    <MemberShipRequired>

                    <Orders user={user} detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />


                    

                   <Route exact path='/offer_accepted/success' element={
                    <AuthRequired>
                    <MemberShipRequired>

                    <OfferAccepted socket={socket} detectPageChange={(page_name)=>this.detectPageChange(page_name)} />
                    </MemberShipRequired>
                    </AuthRequired>
                    } />




                    <Route exact path='/single_order/:order_id' element={
                    <AuthRequired>
                    <MemberShipRequired>

                    <SingleOrderPage detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />

                    <Route exact path='/complete_order/:order_id' element={
                    <AuthRequired>
                    <MemberShipRequired>

                    <CompleteOrder user={user} socket={socket} detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />


                    <Route exact path='/post-job' element={
                    <AuthRequired>
                    <MemberShipRequired>
                        
                        <PostJobPage detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />


                  <Route exact path='/invitation/:invitation_id' element={
                    <AuthRequired>
                    <MemberShipRequired>
                        
                        <ViewInvitation socket={socket} decodedTokenRef={this.decodedTokenRef} detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />


                    <Route exact path='/payment_method/success' element={
                    <AuthRequired>
                    <MemberShipRequired>
                        
                        <PaymentMethodSuccess detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />

                    
                    <Route exact path='/payment_methods' element={
                    <AuthRequired>
                    <MemberShipRequired>
                        
                        <PaymentMethods detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />

                    <Route exact path='/pricings' element={ <Pricings detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>} />


                    <Route exact path='/job/edit/:id' element={
                    <AuthRequired>
                    <MemberShipRequired>
                        
                        <EditJob detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />



                    


                  

                    <Route exact path='/memberships' element={
                     <AuthRequired>
                    <MembershipPage detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </AuthRequired>
                    } />

                    <Route exact path='/account' element={
                    <AuthRequired>
                    <MemberShipRequired>
                    
                        <Account detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />


                    {/* <Route exact path='/account/timeslots' element={
                    <AuthRequired>
                    <MemberShipRequired>
                    
                        <TimeSlotManager detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } /> */}


                    <Route exact path='/favorites' element={
                    <AuthRequired>
                    <MemberShipRequired>
                    
                        <Favorites socket={socket} detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />


                    <Route exact path='/account/reviews' element={
                    <AuthRequired>
                    <MemberShipRequired>
                    
                        <AccountReviews detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />


                  

                    

                    <Route exact path='/change-password' element={
                    <AuthRequired>
                    <MemberShipRequired>
                    
                        <ChangePassword detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </MemberShipRequired>
                    </AuthRequired>
                    } />

                    <Route exact path='/login' element={
                    <GuestRequire>
                        <Login detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </GuestRequire>
                    } />

                    <Route exact path='/reset/verify' element={
                    <GuestRequire>
                        <ResetPasswordVerifyEmail detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </GuestRequire>
                    } />

                    <Route exact path='/reset/:token' element={
                    <GuestRequire>
                        <ResetPassword detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </GuestRequire>
                    } />


                    <Route exact path='/register' element={
                    <GuestRequire>
                    <Register detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    </GuestRequire>
                    } />


                  <Route exact path='/cities' element={
                   
                    <Cities detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                   
                    } />


                    <Route exact path='/terms-conditions' element={
                    <TermsAndConditions detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    } />

                    <Route exact path='/error' element={
                    <SomethingWentWrong detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>
                    } />
                    <Route exact path='/verify-email/:token' element={<VerifyEmail detectPageChange={(page_name)=>this.detectPageChange(page_name)}/>} />



                {/* Admin Routes */}
                <Route exact path='/admin/services' element={
                <AdminAuthRequired>
                    <AdminServices />
                </AdminAuthRequired>
                } />

              <Route exact path='/admin/transactions' element={
                <AdminAuthRequired>
                    <AdminTransactions />
                </AdminAuthRequired>
                } />


              <Route exact path='/admin/pricings' element={
                <AdminAuthRequired>
                    <AdminPricing />
                </AdminAuthRequired>
                } />


              <Route exact path='/admin/parameters' element={
                <AdminAuthRequired>
                    <Parameters />
                </AdminAuthRequired>
                } />


                <Route exact path='/admin/cities' element={
                <AdminAuthRequired>
                    <AdminCities />
                </AdminAuthRequired>
                } />


                <Route exact path='/admin/orders' element={
                <AdminAuthRequired>
                    <OrderList />
                </AdminAuthRequired>
                } />


                <Route exact path='/admin/order/reviews/:order_id' element={
                <AdminAuthRequired>
                    <AdminOrderReviews />
                </AdminAuthRequired>
                } />



                <Route exact path='/admin/users/' element={
                <AdminAuthRequired>
                    <UserList />
                </AdminAuthRequired>
                } />

                <Route exact path='/admin/users/edit/:id' element={
                <AdminAuthRequired>
                    <UpdateUser />
                </AdminAuthRequired>
                } />

                <Route exact path='/admin/memberships' element={
                <AdminAuthRequired>
                    <AdminMemberships />
                 </AdminAuthRequired>
                } />



                <Route exact path='/admin' element={
                 <AdminAuthRequired>
                <AdminUserApproval />
                </AdminAuthRequired>
                } />


                <Route exact path='/admin/jobs' element={
                 <AdminAuthRequired>
                <AdminJobList />
                </AdminAuthRequired>
                } />


              <Route exact path='/admin/featured_handymen' element={
                 <AdminAuthRequired>
                <FeaturedHandymen />
                </AdminAuthRequired>
                } />


                <Route exact path='admin/job/edit/:id' element={
                    <AdminAuthRequired>
                        <AdminEditJob />
                    </AdminAuthRequired>
                    } />
                </Switch>

            <ToastContainer limit={1}/>
            <audio ref={this.audioRef} src={messageAudio} ></audio>
            {!window.location.pathname.includes('admin')?<div>
              {window.location.pathname !== "/terms-conditions"?<Footer />:null}
              </div>:null}
            
            </Router>
        )
    }
}