import axios from "axios";
import React, { useEffect ,useRef,useState} from "react";
import { Spinner } from "react-activity";
import base_url from "../../../base_url";
import {localStorageUserKey} from '../../../local_storage_vars';

export default function OfferAccepted({socket,detectPageChange}){
    const [is_order_created,setOrderCreated] = useState(false);
    const [isLoading,setisLoading] = useState(true);
    const shouldLog  = useRef(true);

    const create_order = async () => {
        socket.disconnect()
        const params = new URLSearchParams(window.location.search);
        const of_id = params.get('of_id')
        const payment_method_id = params.get('payment_method_id')
        
        const paymentId = params.get('token')
        try {
            if (is_order_created) { return false }
            const user = localStorage.getItem(localStorageUserKey);
            const apiUrl = `${base_url}/apis/order/create-order`;

            await axios.post(
                apiUrl,
                {
                    offer_id: of_id,
                    payment_method_id: payment_method_id,
                    paymentId: paymentId,       
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "authorization": user,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    setTimeout(() => {
                        setOrderCreated(true)
                        setisLoading(false)
                    
                        socket.emit('offer_accepted',{
                            offer_id:of_id
                        })
                    }, 3000);
                } else {
                    setOrderCreated(false);
                    setisLoading(false);
                }
            })
            .catch((err) => {
                setOrderCreated(false);
                setisLoading(false);
            });
        } catch (err) {
            setOrderCreated(false);
            setisLoading(false);
        }
    }


    useEffect(() => {
            socket.connect();

            if(shouldLog.current){
                shouldLog.current = false
                detectPageChange(window.location.pathname)

                create_order();
            }
    }, [])
    
    if(isLoading == false){
        if(is_order_created){
            return (
                <center className="container border p-4" style={{borderRadius:10}}>
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Order Created Successfully</h1>

                            <button className="btn btn-primary" onClick={()=>window.location='/orders'}>Go to orders </button>

                        </div>
                    </div>
                </center>
            )
        }else{
            return (
                <center className="container border p-4" style={{borderRadius:10}}>
                    <div className="row">
                        <div className="col-md-12">
                        <i className="fa fa-globe fa-5x text-primary"></i>
                            <h1 >Required Operation Failed</h1>
                            <br />
                            <button className="btn btn-primary" onClick={()=>window.location='/orders'}>Go to orders</button>

                        </div>
                    </div>
                </center>
            )
        }
      
    }else{
       return <center>
            <Spinner size={30} color={'skyblue'} style={{marginTop:20}}/>
       </center>
    }
   
}