import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-activity';
import axios from 'axios';
import { Rating } from 'react-simple-star-rating';
import base_url from '../../../base_url';
import {localStorageAdminKey} from '../../../local_storage_vars';


const SingleReview = ({ name, rating, comment, profilePic }) => (
  <div className="card mb-4">
    <div className="card-body">
      <div className="d-flex align-items-center">
        <img
          src={profilePic?`${base_url}/uploads/${profilePic}`:require('../../../assets/user-circle.png')}
          alt={`Profile of ${name}`}
          className="rounded-circle mr-3"
          style={{width:80,height:80}}
        />
        <div>
          <b className="card-title">{name}</b>
          <div className="d-flex align-items-center">
            
            <div className="rating">
              <Rating initialValue={rating} readonly/>
            </div>
          </div>
        </div>
      </div>
      <p className="card-text mt-3">{comment?comment:'Comment deleted'}</p>
    </div>
  </div>
);

const AdminOrderReviews = () => {
 const [review,setReview] = useState({})
 const [isLoading,setisLoading] = useState(true)

 const getReviews = async()=>{
    const user = await localStorage.getItem(localStorageAdminKey);
    const order_id = await window.location.pathname.split('/')[4]
    await axios.get(`${base_url}/apis/order/order_review?order_id=${order_id}`,{
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "authorization": user,
          'Content-Type': 'application/json', 
        },
    })
    .then(res=>{
        
        setReview(res.data)
        setisLoading(false)
    })
    .catch(err=>{
        alert("Something Went Wrong")
    })

 }

 useEffect(()=>{
    getReviews()
    
 },[])

  if(!isLoading){
    return <div className="container">
    <h1 className="my-4">Order Review: </h1>
  
      {review? <SingleReview
        key={review._id}
        name={review.client?review.client[0]?.username:'Client not available'}
        rating={review.rating}
        comment={review.comment}
        profilePic={review.client?review.client[0]?.profile_picture:''}
      />:<h3 style={{color:'red'}}>Not Reviewed Yet!</h3>}
   
  </div>
  }else{
    return <center>
        <br />
        <br />

        <Spinner color='skyblue' size={25}/>
    </center>
  }
  
}

export default AdminOrderReviews;
