import React, { useEffect, useState } from 'react';
import Paypal from '../../../components/main/Paypal';
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';
import axios from 'axios';
import { Spinner } from 'react-activity';

const MembershipPage = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading,setIsLoading] = useState(false)
  const [pageLoading,setPageLoading] = useState(true)
  const [membershipPlans,setmembershiPlans] = useState([])

  const handlePlanSelect = (planId) => {
    setSelectedPlan(planId);
    setPageLoading(true)

    setTimeout(()=>{
      setPageLoading(false)
    },300)
  };

  const buy_free_trial_membership = async(duration,type)=>{
    setIsLoading(true)
    const user = await localStorage.getItem(localStorageUserKey);
    
    const formData = new FormData();
    formData.append('endDate', duration);
    formData.append('type', type);

    
   await axios.post(`${base_url}/apis/user_membership/buy-membership`, formData, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'multipart/form-data', // Set the content type for FormData
      },
    })
      .then(async(response) => {
         const responseData = response.data;

        if (response.status === 200) {
         
          alert(responseData.message);
          window.location = "/home"
        } else {
          alert(responseData.message);

        }

      setIsLoading(false)

      })
      .catch(err => {
        console.log(err)
        alert("Internal server error");
      setIsLoading(false)

      });
  }


  const fetchMembershipPlans = async()=>{
    const user = await localStorage.getItem(localStorageUserKey);
    axios.get(`${base_url}/apis/membership/get-all-memberships`,{
      headers:{
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'multipart/form-data',
      }
    })
    .then(res=>{
      if(res.status == 200){
        setmembershiPlans(res.data)
        setPageLoading(false)
      }else{
        alert(res.data.message)
      }
    })
    .catch(err=>{
      alert("Something Went Wrong")
    })
  }

  useEffect(()=>{
    fetchMembershipPlans()
  },[])
  
  return (
    <div className=" mt-2 border p-3">
      <h2 >Membership Plans</h2>
      <br />
      {pageLoading == false?<div>
      <div className="row">
        {membershipPlans.map((plan,index) => (
          <div key={index} className="col-md-4 mb-3">
            <div className={`card ${selectedPlan === plan._id ? 'border-primary' : ''}`}>
              <div className="card-header">
                <h4>{plan.title}</h4>
              </div>
              <div className="card-body">
                <h5>${!plan.price?0:plan.price}/{plan.expiry_days} Days</h5>
                <p>{plan.description}</p>
              </div>
              <div className="card-footer text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => handlePlanSelect(plan._id)}
                  disabled={selectedPlan === plan._id}
                >
                  {selectedPlan === plan._id ? 'Selected' : 'Select'}
                </button>
              </div>
            </div>

            
          </div>
        ))}

       
      </div> 
      
      {selectedPlan? 
        <div className="mt-4">
          <h4>Selected Plan: {membershipPlans.find((plan) => plan._id === selectedPlan).title}</h4>
          {membershipPlans.find((plan) => plan._id === selectedPlan).type != "trial"?<Paypal price={membershipPlans.find((plan) => plan._id === selectedPlan).price} type={membershipPlans.find((plan) => plan._id === selectedPlan).type} membership_id={membershipPlans.find((plan) => plan._id === selectedPlan)._id} membership_duration={membershipPlans.find((plan) => plan._id === selectedPlan).expiry_days} />:
          
          <button
          className="btn btn-primary"
          onClick={() => buy_free_trial_membership(membershipPlans.find((plan) => plan._id === selectedPlan).expiry_days,membershipPlans.find((plan) => plan._id === selectedPlan).type)}
          disabled={isLoading}
          >
          {isLoading?<Spinner size={17} color='skyblue'/>:null} Get For Free
        </button>}


        </div>
      :null}
      
      </div>:<center><Spinner size={25} color='skyblue'/></center>}
     

      
    </div>
  );
};

export default MembershipPage;
