import React, { useState } from 'react';
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';

const user = localStorage.getItem(localStorageUserKey);

function ChangePassword() {
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  
  const handleChangePassword = async () => {
    try{
      const response = await fetch(`${base_url}/apis/auth/change-password`, {
        method: 'POST',
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "authorization": user,
          'Content-Type': 'application/json', 
        },
        body: JSON.stringify({ email, oldPassword, newPassword }),
      });
  
      const data = await response.json();
  
      alert(data.message)
    }catch(err){
      if(err.response && err.response.data){
        alert(err.response.data.message)
       }else{
        alert("Something went wrong")
       }
    } 
  };

  return (
    <div className="mt-3 ">
    <div>
      <div className="">
        <div className="card h-100">
          <div className="card-body" >
            <h3 className="card-title  mb-4">Change Password</h3>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="oldPassword" className="form-label">
                Old Password
              </label>
              <input
                type="password"
                className="form-control"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">
                New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleChangePassword}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
}

export default ChangePassword;