
import React, { useEffect } from 'react';
//import PdfViewer from './PdfViewer';


const TermsAndConditions = ({detectPageChange}) => {
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };
  
  useEffect(()=>{
    detectPageChange(window.location.pathname)

  },[])
  return (
    // <div>
    //  // <PdfViewer />
    // </div>

    <div className="container mt-5">
      <button style={{padding:'0', height:'30px', width: '30px', position: 'fixed', top: '0', right: '0', zIndex: '10'}} onClick={closeTab}>&#x2716;</button>
      <div className="card">
        <div className="card-body text-justify">
          <h3 className="card-title text-center">NOTICE TO ALL USERS: CAREFULLY READ THE FOLLOWING LEGAL AGREEMENT</h3>
           
          <h5>Terms of Use and End Users License Agreement ("Terms of Use")</h5>
          <p>The following software, marketing referral services and or membership subscription services are being provided to you by New World Living LLC (d/b/a "81Handyman.com"). As such, by agreeing to access such software and services, the end user ("Client") hereby agrees to abide by the terms and conditions outlined below vis a vis Client and 81Handyman.com in addition to any terms and conditions agreed to by and between 81Handyman.com and Client pursuant to a previously entered into Marketing Referral Fee Agreement ("Agreement"). By registering and agreeing to use 81Handyman.com Referral Services all payments for services have to be made through 81Handyman.com. Failure to do so will result in 81Handyman.com reserving the right to revoke and cancel the user’s membership.  All purchases made through this website are non-refundable. Once a purchase is completed, no refunds will be provided by 81Handyman.com under any circumstances, except as required by law. All Taxes and Additional Fees are to be Calculated and Paid according to User's City, County, State, Municipality and Country Tax Laws and Jurisdiction.
          </p>          

          <p>By subscribing to 81Handyman.com you become a Client of 81Handyman.com. 81Handyman.com offers services to you, the Client, conditioned on your agreement to adhere to the following Terms of Use without modification or reservation of any kind.
          </p>

          <p>
          Client's agreement with 81Handyman.com and/or use of 81Handyman.com’s services constitutes Client's unconditional acceptance to these Terms of Use. These Terms of Use are subject to change by 81Handyman.com, in its sole discretion, at any time, without prior notice by posting upon its web site.
          </p>


          <p>
          Client understands that billing will occur as a marketing referral percentage fee and monthly subscription fee as outlined during the registration process along with any upgrades, enhancements, or fee-based services that Client may agree to within the Service or from the order page on 81Handyman.com's website, or that of its affiliates, until Client cancels their subscription. All marketing referral percentage fee are NON-REFUNDABLE. To cancel your monthly marketing referral subscription, please contact our Customer Support during business hours at 814-263-9626 prior to your next billing cycle date. 
          </p>


          <p>
          In the event that Client's designated primary payment method declines for any subscription fee or additional charge that Client incurs, Client authorizes 81Handyman.com to bill any and all additional credit cards or payment methods on file from Client with 81Handyman.com to satisfy Client's outstanding balance(s).
          </p>

          <p>
          Based upon the Client's agreement to adhere to, and fully comply with, these Terms of Use, 81Handyman.com grants and conveys to the Client, during the term of Client's valid subscription, a non-exclusive, non-transferable license to use the data, information and services provided through the 81Handyman.com website subject to the License Restrictions set forth hereafter.
          
          </p>

          <p>
          <h5>License Restrictions</h5>
          
          Client represents, warrants, affirms and agrees that the data, information and services provided by 81Handyman.com to Client will only be used by Client and that Client will not permit or allow the data, information and services to be used by any agent, representative, consultant, officer, director, shareholder, parent organization, subsidiary organization, third party or any other person or entity unless Client has the appropriate subscription allowing such expanded use. Client consents that Client’s search volume, and that of any Team Member(s) on Client’s account, is monitored to avoid abuse, data duplication, “data mining”, and excessive or otherwise non-customary search and usage patterns. Any such actions will be determined by 81Handyman.com in its sole discretion and will result in an immediate termination of Client’s account. Client further represents, warrants, affirms and agrees that Client will not transfer, sell, convey, use, resell or sublicense any data, information or services provided by 81Handyman.com to Client in any medium, form, manner or format whatsoever, for any purpose including, but not limited to the following:
          
          <ul style={{marginLeft: '20px', marginTop: '10px'}}>
            <li style={{listStyleType: 'decimal'}}>Use other than Client's own personal use, i.e. no commercial purpose or use, or use of Client's account by others is permitted;</li>
            <li style={{listStyleType: 'decimal'}}>Reproduction, reformatting, publication, distribution or dissemination associated with any service or product provided or made available to any third party;</li>
            <li style={{listStyleType: 'decimal'}}>Marketing or telemarketing uses;</li>
            <li style={{listStyleType: 'decimal'}}>World Wide Web, Internet or online uses;</li>
            <li style={{listStyleType: 'decimal'}}>Real estate valuation models, programs or systems;</li>
            <li style={{listStyleType: 'decimal'}}>Inclusion or in combination with any other service or product of any kind;</li>
            <li style={{listStyleType: 'decimal'}}>Extracting, selecting or drawing out any data element for any use;</li>
            <li style={{listStyleType: 'decimal'}}>Real estate appraisal;</li>
            <li style={{listStyleType: 'decimal'}}>Credit evaluation and/or supporting;</li>
            <li style={{listStyleType: 'decimal'}}>Evaluating risk, marketing sale of insurance products of any kind, including but not limited to life, health, long-term care, disability, casualty, umbrella, mortgage, title or property;</li>
            <li style={{listStyleType: 'decimal'}}>Debt collection; and</li>
            <li style={{listStyleType: 'decimal'}}>Marketing or sale of legal goods and/or services of any kind, including but not limited to bankruptcy or real estate title/lien history unless Client has the appropriate subscription allowing such expanded use.</li>
          </ul>
          
          </p>

          <p>
          <h5>Team Accounts</h5>
          Team Accounts may only be used by Team Members and each Team Member must agree to Terms of Use & End User License agreement before being granted access. Team Members are limited to designated individuals who have a bona fide relationship with the business that holds the Primary Account and have a legitimate business need to access and use 81Handyman.com's services in connection with the business that holds the Team Account. Team Members must be either employees, or direct consultants or contractors of the Primary Account Holder. A Team Member may not use the Service to find, evaluate, contact investments or listing opportunities for their own personal uses or for any business other than that of the Primary Account Holder. The solicitation or acceptance of money, or anything else of value, by the Primary Account holder in exchange for granting access to 81Handyman.com's services to a Team Member is strictly prohibited and will result in the immediate termination of the Account and may subject Account Holder(s) to additional damages as permitted by law.

          </p>


          <p>
          <h5>Acceptable Use Policy</h5>
          By using the services, you agree that:
          <ul style={{marginLeft: '20px', marginTop: '10px'}}>
            <li style={{listStyleType: 'decimal'}}>You will only use the services for lawful purposes, and not for deceptive or fraudulent purposes; you will not send or store any unlawful material.</li>
            <li style={{listStyleType: 'decimal'}}>You will not use the services to cause nuisance, annoyance or inconvenience.</li>
            <li style={{listStyleType: 'decimal'}}>You will not use the services, or any content accessible through the services, for any commercial purpose, including but not limited to contacting, advertising to, soliciting or selling to, any individuals.</li>
            <li style={{listStyleType: 'decimal'}}>You will not violate the publicity or privacy rights of another individual.</li>
            <li style={{listStyleType: 'decimal'}}>You will not copy or distribute any content displayed through the services.</li>
            <li style={{listStyleType: 'decimal'}}>You will not create or compile, directly or indirectly, any collection, compilation, or other directory from any content displayed through the services except for your personal, noncommercial use.</li>
            <li style={{listStyleType: 'decimal'}}>The information you provide to us or otherwise communicate with us is accurate.</li>
            <li style={{listStyleType: 'decimal'}}>You will not use the services in any way that could damage, disable, overburden or impair any of our servers, or the networks connected to any of our servers.</li>
            <li style={{listStyleType: 'decimal'}}>You will not attempt to gain unauthorized access to any part of the services and/or to any service, account, resource, computer system and/or network connected to any of our servers.</li>
            <li style={{listStyleType: 'decimal'}}>You will not deep-link to the services or access the services manually or with any robot, spider, web crawler, extraction software, automated process and/or device to scrape, copy or monitor any portion of the Services or any content on the Services.</li>
            <li style={{listStyleType: 'decimal'}}>You will report any errors, bugs, unauthorized access methodologies or any breach of our intellectual property rights that you uncover in your use of the Services.</li>
            <li style={{listStyleType: 'decimal'}}>You will not impersonate another person, act as another entity without authorization, or create multiple accounts.</li>
            <li style={{listStyleType: 'decimal'}}>You will not violate any state or federal law designed to regulate electronic advertising.</li>
            <li style={{listStyleType: 'decimal'}}>You will not upload pictures, data, audio or visual files, or any other content that is excessive in size, as determined by us in our sole discretion.</li>
          </ul>
          </p>

          <p>
          <h5>Privacy and Security</h5>
          We have adopted a Privacy Policy outlining our personal data collection and use practices. Please refer to it for details about how we collect and use your personal information. 
          By agreeing to the terms of this Agreement, you are automatically agreeing to our Privacy Policy, which is incorporated herein by reference.
          </p>
          <p>
          By registering for a paid subscription, you represent and warrant that, as required by applicable law:
          <ul style={{marginLeft: '20px', marginTop: '10px'}}>
            <li style={{listStyleType: 'decimal'}}>You adequately disclose any collection and use of personal information received from us;</li>
            <li style={{listStyleType: 'decimal'}}>You agree to abide by all applicable email marketing, privacy, and data protection laws when communicating with any individuals whose personal information you received from us;</li>
            <li style={{listStyleType: 'decimal'}}>You will protect your user credentials and notify us of any unauthorized use of your user credentials; and,</li>
            <li style={{listStyleType: 'decimal'}}>You agree to notify us of any complaints or privacy requests regarding our Services from individuals whose personal information you received from us.</li>
          </ul>

          </p>
         
         <p>
        <h5>Proprietary Rights</h5>
         Client represents, warrants, affirms and agrees that the data, information, services and 81Handyman.com name are proprietary information and property of 81Handyman.com, its suppliers and/or affiliates and are protected by copyright, trademark, trade name and other proprietary rights. Client's license only allows Client to use the data, information and services subject to the express limitations and restrictions provided for in these Terms of Use during the term of Client's valid subscription.
         
         </p>

         <p>
         <h5>Consequences of Non-Compliance with Terms of Use</h5>
         At any time that 81Handyman.com believes, in its sole discretion, that Client has violated any term, condition, restriction, permitted use or limitation provided in these Terms of Use, 81Handyman.com may immediately terminate Client's license and 81Handyman.com may at its sole discretion provide Client with a pro-rata refund (minus time and data accessed during the term of the subscription). A pro-rata refund is at the sole discretion of 81Handyman.com and will vary depending on severity of Non-Compliance.
         </p>

         <p>
         <h5>Terms of Subscription</h5>
         The term of Client's subscription is one month, but automatically renews month-to-month unless terminated by Client or 81Handyman.com. All marketing referral percentage fees are NON-REFUNDABLE.  
          
        </p>

        <p>
          <h5>Payments </h5>

          By registering, accepting and agreeing to use 81Handyman.com Referral Services all payments for services have to be made exclusively through 81Handyman.com. Failure to do so will result in 81Handyman.com reserving the right to revoke and cancel the user’s membership.  Should a contractor request payment directly, Client should notify 81Handyman.com immediately and only tender payments through 81Handyman.com.  All purchases made through this website are non-refundable by 81Handyman.com. Once a purchase is completed, no refunds will be provided under any circumstances, except as required by law. All Taxes and Additional Fees are to be Calculated and Paid according to User's City, County, State, Municipality and Country Tax Laws and Jurisdiction.

        </p>

         <p>
         <h5>Termination</h5>
         81Handyman.com reserves the right to cancel subscription services and this license to Client at any time for any, or no reason whatsoever, without recourse to Client beyond a pro-rata refund of the monthly subscription/license fee paid by Client for the remaining days of the then current month of prepaid fees.
         </p>

        <p>
        The Client may cancel the Client's subscriptions at any time. All fees due to 81Handyman.com up to the end of the then-current monthly billing cycle at time of termination shall remain payable to 81Handyman.com.
        </p>

        <p>
        The Client may terminate their contract in any of the following ways: 

        <ul style={{marginLeft: '20px', marginTop: '10px'}}>
            <li style={{listStyleType: 'decimal'}}>To cancel your subscription, please contact our Customer Support during business hours at 814-263-9626 prior to your next billing cycle date.</li>
            <li style={{listStyleType: 'decimal'}}>By emailing a cancelation request at least ten (10) days prior to the desired cancelation date to: <a href='mailto: support@81handyman.com?subject=ATTN: Cancelations'>support@81handyman.com ATTN: Cancellations</a> </li>
        </ul>
        
        </p>

        <p>
        <h5>Survival of Terms</h5>
        The License Restrictions and Proprietary Rights described herein survive any termination of this agreement and/or Client's subscription.

        </p>
        
        <p>
        <h5>Client Services</h5>
        Client affirms that all of the information Client provides to 81Handyman.com, whether online or otherwise, is accurate and complete. 
        Client also agrees to update 81Handyman.com with current and accurate information, if at any time the information Client provided to 81Handyman.com changes, 81Handyman.com reserves its 
        right to terminate or suspend access to 81Handyman.com services to any Client whose information 81Handyman.com believes, at 81Handyman.com's sole discretion, to be inaccurate or misleading. 
        
        </p>

        <p>
        81Handyman.com may from time to time offer eligible Clients promotional opportunities. Not all Clients may be eligible to receive all promotional opportunities.

        </p>

        <p>
        <h5>LIMITATION OF LIABILITY OF 81Handyman.com</h5>

        81Handyman.com is intended solely as a referral service and does not warranty or represent the ultimate quality or performance of the services offered by or through the companies referred.  81Handyman.com endeavors to only make quality referrals to reputable contractors, but it is the Client's responsibility to independently investigate the qualifications of each service provider relative to the job needed.  The Client indemnifies and holds harmless 81Handyman.com from any claims, disputes, demands, or suits arising out of the work performed by the referral.  In the event of any such dispute, 81Handyman.com’s liability shall be limited to the subscription fee paid by the Client for the month that the referral was made.  Any and all other claims or disputes shall be resolved between the Client and the Contractor.

        </p>

        <p>
        <h5>OTHER SERVICE PROVIDERS - NON-LIABILITY OF 81Handyman.com</h5>
        
        From time-to-time 81Handyman.com may include on its websites, software, or service, third-party service providers not associated with 81Handyman.com, such as Products and Other Services. 81Handyman.com lists these services solely as an accommodation to its clients. 81Handyman.com is not responsible for and makes no warranty or representation as to the quality or performance of the services offered by or through these third-party service providers. It is the Client's responsibility, and 81Handyman.com encourages this for all clients, to independently investigate the qualifications of each service provider and to carefully review each service provider's service offerings prior to entering into any engagement or transaction. Such services are offered on an "AS-IS'' basis and are subject to the limitations and disclaimers of the third-party providers, which are available from the providers upon request. 81Handyman.com cannot and does not guarantee the outcome of the use of any such third-party services.

        </p>

        <p>
        81Handyman.com is not responsible or liable for any acts or omissions created or performed by these other Service Providers. 81Handyman.com's websites, software, or service, may contain links to websites maintained by third-party Service Providers. Such links are provided for Client convenience and reference only.

        </p>
        <p>
        81Handyman.com does not operate or control, in any respect, any information, software, products or services available on such websites. 81Handyman.com 's inclusion of a link to such websites does not imply any endorsement, warranty, guarantee or recommendation of these websites, contents, products or services of the sponsoring organization.

        </p>

        <p>
        <h5>Third-Party Content and Screening</h5>
        
        81Handyman.com does not have editorial control over the statements, or other content provided, expressed or implied by third parties. 
        81Handyman.com reserves the right, but is not obligated, to screen and review any content provided to 81Handyman.com and remove or edit any content, 
        if it does not comply with laws, rules or regulations, or for any other reason 81Handyman.com deems relevant. 
        To the extent any content contains personal information subject to applicable privacy laws, please refer to 81Handyman.com's Privacy Policy.

        </p>

        <p>
        <h5>Modification of 81Handyman.com Services</h5>

        81Handyman.com reserves the right to modify or discontinue any service, portion or attribute thereof, and the offering of any information with or without notice to any Client. 
        </p>
        <p>
        81Handyman.com is not liable to any Client in the event that 81Handyman.com exercises its right to modify or discontinue any service.
        </p>

        <p>
        <h5>PAYMENT OF CHARGES</h5>

        A. 81Handyman.com currently charges a marketing referral percentage fee and or a monthly subscription fee. The monthly subscription fee is calculated from the day of the month that the Client's paid Subscription commences. The Client authorizes 81Handyman.com to, and the Client agrees that 81Handyman.com may, charge to the Client's credit card, debit card or bank account, in advance, the Client's regular monthly Subscription fee for each month of the Client's regular monthly Subscription on a recurring basis. The Client authorizes 81Handyman.com to, and the Client agrees that 81Handyman.com may, modify and/or change the monthly Subscription fee charged to the Client upon thirty (30) days' notice to the Client sent by e-mail to Client's email of record on file with 81Handyman.com. The monthly marketing referral percentage fee and Subscription fee is non-refundable and accrues until canceled whether or not the Client accesses the databases. All purchases made through this website are non-refundable. Once a purchase is completed, no refunds will be provided under any circumstances, except as required by law.

        </p>

        <p>
        <span style={{display:'block'}}>B. On Time Payment</span>
         
        The Client is required to pay all charges on time and agrees to submit an accompanying payment authorization in connection with these charges when requested by 81Handyman.com. Credit card transactions require an acceptable and currently working/continuously valid credit card number/account. 81Handyman.com may terminate or disable the Client's participation in 81Handyman.com services if the Client fails to pay fully and in a timely manner any and all amounts due to 81Handyman.com. If the Client's credit card expires or is otherwise declined for payment, access to 81Handyman.com services and websites can be modified or suspended without notice to the Client. 81Handyman.com may, if necessary, extend the expiration of your credit card in order to assure continued service. If any Client payment is more than thirty (30) calendar days past due, a monthly late fee in the amount of 1.5% of the past due amount or the maximum allowable by law, whichever is less, shall accrue per month, and the entire amount of unpaid charges and/or any other outstanding balance (if any), plus this assessment, will become immediately due and payable. The Client shall pay all costs of collection, including legal fees, incurred by 81Handyman.com. The Client is responsible for any premium charges incurred in connection with the use of information, content, goods, products or services that are provided at an extra cost. All fees are quoted and payable in United States Dollars. The Client is also responsible for paying all applicable taxes for information, content, goods, products or services and any other costs incurred in connection with the use of or access to 81Handyman.com and 81Handyman.com's services.
        
        </p>

        <p>
        <span style={{display:'block'}}>C. Payment Methods</span>
        
        The Client's complete billing address and telephone number must be provided to process payments. 81Handyman.com and its Partners accept major credit cards including Visa, MasterCard, Discover, and American Express and may accept bank drafts, including personal checks, money orders, cashier's checks, and company checks sent by mail. 81Handyman.com may charge a twenty-dollar ($20.00) handling fee, or the maximum allowable by law, whichever is less, for each check returned unpaid for insufficient funds or any other reason.
       
        </p>

        <p>
        <span style={{display:'block'}}>D. Account Discrepancies</span>
        
        A Client may contact 81Handyman.com Customer Service by e-mail at <a href='mailto:support@81handyman.com'>support@81handyman.com</a> or telephone at 814-263-9626 concerning charges or other questions regarding the status of the Client's Account. However, if the Client's account questions are not satisfactorily resolved within fifteen (15) business days of Client's calling or sending an e-mail, and in no event later than ninety (90) days after a questionable account billing or other discrepancy should have been discovered otherwise, any and all such Client complaints are irrevocably waived.

        </p>

        <p>
        <h5>DISCLAIMERS AND LIMITATIONS</h5>
        81Handyman.com attempts to ensure that the information contained in its service is accurate and reliable; however, errors sometimes occur. 81Handyman.com does not guarantee the accuracy of the information contained in this service and instructs you to independently verify the accuracy of the information provided. In addition, 81Handyman.com may make changes and improvements to the information provided herein at any time. THE WEBSITES AND THE INFORMATION, SOFTWARE, PRODUCTS AND SERVICES ASSOCIATED WITH THEM ARE PROVIDED "AS IS." 81HANDYMAN.COM, ITS SUPPLIERS, PROFESSIONAL SERVICE PROVIDERS, THIRD-PARTY CONTENT PROVIDERS AND OTHER SERVICE PROVIDERS DISCLAIM ANY WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS TO ANY MATTER WHATSOEVER RELATING TO THE WEBSITES AND ANY INFORMATION, SOFTWARE, PRODUCT, GOOD AND/OR SERVICE PROVIDED THEREIN, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON INFRINGEMENT. BY THE CLIENT'S USE OF 81HANDYMAN.COM 'S SERVICE, THE CLIENT AGREES AND ACKNOWLEDGES THAT THE CLIENT'S USE OF ALL 81HANDYMAN.COM INFORMATION, SOFTWARE, PRODUCTS, GOODS AND/OR SERVICES PROVIDED IN, BY OR 

        </p>
        <p>
        THROUGH 81HANDYMAN.COM AND/OR ITS WEBSITES IS AT THE CLIENT'S OWN RISK. 81HANDYMAN.COM AND/OR ITS SUPPLIERS AND PROVIDERS, ARE NOT LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR OTHER INJURY ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF 81HANDYMAN.COM'S SERVICE OR WITH THE DELAY OR INABILITY TO USE ITS WEBSITES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, GOODS AND/OR SERVICES OBTAINED THROUGH THE WEBSITES, OR OTHERWISE ARISING OUT OF THE USE OF THE WEBSITES, WHETHER RESULTING IN WHOLE OR IN PART, FROM BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, REGARDLESS OF WHETHER OR NOT THE CLIENT KNOWS, SUSPECTS OR HAS BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO THE CLIENT, IF THE CLIENT RESIDES IN SUCH A JURISDICTION.

        </p>

        <p>
        <h5>INDEMNITY</h5>
        As a condition precedent to the use of the 81Handyman.com service and/or websites of 81Handyman.com , the Client agrees to defend and indemnify New World Living LLC d/b/a 81Handyman.com., and its employees, agents and/or Service Providers from and against any and all liabilities, expenses (including attorneys' fees) and damages arising out of claims resulting from Client's use of 81Handyman.com's service, including without limitation, any claims alleging facts, that if true, would constitute a breach by Client of these Terms of Use.

        </p>

        <p> 
        <h5>Arbitration</h5>
        In the event of any dispute between Client and 81Handyman.com, Client agrees to submit such dispute to binding arbitration before seeking remedies in a Court of law.  Such arbitration shall be conducted within one hundred and twenty (120) days of either party’s demand for arbitration by a Florida Qualified Arbitrator and in accordance with the Florida Arbitration Code, Florida Statutes Chapter 682.  Any party refusing to participate in binding arbitration shall not be entitled to their legal fees and costs even if they are ultimately found to be the prevailing party.
        
        </p>
        

        <p>
        <h5>Choice of Law:</h5>
        These Terms of Use shall be deemed to have been agreed to in the State of Florida. In any litigation arising out of these Terms of Use, the prevailing party shall be entitled to reasonable attorneys' fees and costs.

        </p>

        <p>
        <h5>Jurisdiction:</h5>
        All actions brought hereunder, whether at law or in equity, shall be brought first in binding arbitration as discussed in the Arbitration clause above.  Should the parties require judicial action to confirm or enforce the arbitration award, or seek other remedies such as injunctive relief, the parties agree that jurisdiction is proper in the state or federal courts located in and serving the State of Florida. Client consents to jurisdiction in the State of Florida and expressly waives any jurisdiction privileges which may be asserted in connection with these Terms of Use. or any claim that Florida is an inconvenient forum.
        
        </p>
        
        <p>
        <h5> MISCELLANEOUS TERMS</h5>
        These Terms of Use may not be modified except by a writing signed by both parties.
        <ul style={{marginLeft: '20px', marginTop: '10px'}}>
            <li style={{listStyleType: 'decimal'}}>A party's failure to enforce strict performance of any provision of these Terms of Use will not constitute a waiver of its right to subsequently enforce such provision or any other provision of these Terms of Use.</li>
            <li style={{listStyleType: 'decimal'}}>Reservation of Rights. New World Living Llc., reserves all rights not expressly granted to you in this license agreement.</li>
            <li style={{listStyleType: 'decimal'}}>This agreement shall be deemed as being jointly drafted and not construed in favor of or against any party.</li>
            <li style={{listStyleType: 'decimal'}}>Severability. Except as otherwise provided herein, if any provision of this Agreement is found to be invalid, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect.</li>
            <li style={{listStyleType: 'decimal'}}>Electronic Communications. For contractual purposes, you (1) consent to receive communications from us in an electronic form; and (2) agree that all terms and conditions, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications would satisfy if they were in writing. This subparagraph does not affect your statutory rights.</li>
       </ul>
        </p>

        <p>
        <h5>CONSENT</h5>
        I have carefully read and considered all provisions of these Terms of Use and agree that all of the terms set forth are fair and reasonable. I acknowledge and warrant that I unconditionally consent to all of the terms of this Terms of Use agreement.
        
        </p>

        <p><strong>
        BY INSTALLING THE SOFTWARE AND/OR ACCESSING THE WEBSITE OR SERVICE, YOU (EITHER AN INDIVIDUAL OR A SINGLE ENTITY) CONSENT TO BE BOUND BY AND BECOME A PARTY TO THE AGREEMENT CONSTITUTED BY THESE TERMS OF USE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS OF USE, DO NOT INSTALL 81HANDYMAN.COM SOFTWARE AND/OR ACCESS THE WEBSITE OR SERVICE.

         </strong>
        </p>
        </div>
      </div>
    </div>
  
  );
};

export default TermsAndConditions;
