import React, { useEffect } from 'react';
import './style.css';

const AboutUs = ({detectPageChange}) => {
  
useEffect(()=>{
  detectPageChange(window.location.pathname)
   
},[])

  return (
    <div className="about-us-container">
    <div className="about-us-header">
      <h1>About Us</h1>
    </div>
    <div className="about-us-content">
      <div className="about-us-section">
        <h2>Our Values</h2>
        <p>We believe in honesty, integrity and transparency in all our dealings.</p>
        <p>Our values define our cultue and guide us in delivering exceptional services while fostering a positive work environment.</p>
      </div>
   
    </div>
  </div>
  );
};

export default AboutUs;
