import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-activity';
import base_url from '../../../base_url';
import {localStorageAdminKey} from '../../../local_storage_vars';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'react-fullscreen-loading';

const UserList = () => {
  const [data, setData] = useState([]);
  const [totalUsersCount,setTotalUsersCount] = useState(0)
  const [operationLoading,setOperationLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize,setPageSize] = useState(5)
  const [filters, setFilters] = useState({
    _id: '',
    username: '',
    email: '',
    user_type: '',
  });

  const fetchUsers = async () => {
    try {
     
      const user = await localStorage.getItem(localStorageAdminKey);
      const response = await axios.get(
        `${base_url}/apis/user/get_all_users?page=${currentPage}&&pageSize=${pageSize}`,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            authorization: user,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        
        setData(response.data.users);
        setTotalPages(response.data.totalPages);
        setTotalUsersCount(response.data.totalCount)
        setIsLoading(false);
        
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
      alert('Something Went Wrong');
    }
  };

  useEffect(() => {
    const isFilterApplied = Object.values(filters).some(value => value !== '');
  
    if (isFilterApplied) {
      applyFilter();
    } else {
      fetchUsers();
    }
  }, [currentPage,pageSize]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const applyFilter = async () => {
    try {
      const user = await localStorage.getItem(localStorageAdminKey);
      setOperationLoading(true)

      const response = await axios.get(
        `${base_url}/apis/user/filterUsers`,
        {
          params: {
            filters,
            currentPage,
            itemsPerPage:pageSize,
          },
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            authorization: user,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setData(response.data.users);
        setTotalPages(Math.ceil(response.data.totalCount / pageSize));
        setTotalUsersCount(response.data.totalCount)

      } else {
        alert(response.data.message);
      }
      setOperationLoading(false);

    } catch (error) {
      console.error(error);
      setOperationLoading(false);

      alert('Something Went Wrong');
    }
  };


  const resetFilter = () => {
    setFilters({
      _id: '',
      username: '',
      email: '',
      user_type: '',
    });

    fetchUsers()
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const toggleSelection = (_id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(_id)) {
        return prevSelectedRows.filter((rowId) => rowId !== _id);
      } else {
        return [...prevSelectedRows, _id];
      }
    });
  };

  const deleteSelected = async () => {
    try {
      if (selectedRows.length === 0) {
        alert('No users selected for deletion');
        return;
      }
      setOperationLoading(true)
      const user = await localStorage.getItem(localStorageAdminKey);
      const response = await axios.delete(
        `${base_url}/apis/user/delete_users`,
        {
          data: { userIds: selectedRows },
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            authorization: user,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setData((prevData) =>
          prevData.filter((row) => !selectedRows.includes(row._id))
        );
        setSelectedRows([]);
      } else {
        alert(response.data.message);
      }
      setOperationLoading(false)

    } catch (error) {
      console.error(error);
      setOperationLoading(false)

      alert('Failed to delete.Please try again');
    }
  };

  const deleteUser = async (userId) => {
    try {
      setOperationLoading(true)

      const response = await axios.delete(`${base_url}/apis/user/delete_user/${userId}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: localStorage.getItem(localStorageAdminKey),
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setData((prevUsers) => prevUsers.filter((user) => user._id !== userId));
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
      setOperationLoading(false)

    } catch (error) {
      console.error(error);
      setOperationLoading(false)

      alert('Something Went Wrong');
    }
  };

  return (
    <div className="p-5 mt-4" style={{overflowX:'scroll'}}>
            <Loading loading={operationLoading}  loaderColor="#3498db" />

      {isLoading ? (
        <center>
          <Spinner color="skyblue" size={25} />
        </center>
      ) : (
        <>
          <div className="row mb-3">
            <div className="col-md-2">
              <label>ID:</label>
              <input
                type="text"
                className="form-control"
                name="_id"
                value={filters._id}
                onChange={handleFilterChange}
              />
            </div>
            <div className="col-md-2">
              <label>Username:</label>
              <input
                type="text"
                className="form-control"
                name="username"
                value={filters.username}
                onChange={handleFilterChange}
              />
            </div>
            <div className="col-md-2">
              <label>Email:</label>
              <input
                type="text"
                className="form-control"
                name="email"
                value={filters.email}
                onChange={handleFilterChange}
              />
            </div>
            <div className="col-md-2">
              <label>User Type:</label>
              <select
                className="form-control"
                name="user_type"
                value={filters.user_type}
                onChange={handleFilterChange}
              >
                <option value="">Select User Type</option>
                <option value="admin">Admin</option>
                <option value="contractor">Handyman</option>
                <option value="client">Client</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <button className="btn btn-primary mr-2" onClick={applyFilter}>
                Apply Filter
              </button>
              <button className="btn btn-secondary" onClick={resetFilter}>
                Reset Filter
              </button>
              <button
                className="btn btn-danger ml-2"
                onClick={deleteSelected}
              >
                Delete Selected
              </button>


            <div className='mt-3'>

              
            <h5 className='float-right border p-2 bg-primary text-white ml-3' style={{borderRadius:5}}>Total Users : {totalUsersCount}</h5>

           
            <p className='border p-2' style={{borderRadius:5,width:100,float:'right'}}>page size</p>

        
            <select className='form-control float-right' style={{width:100}} value={pageSize} onChange={(val)=>{
            setPageSize(val.target.value)
            }}>
       
         
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={40}>40</option>
        

          </select>
         

          
          </div>

            </div>


          </div>

        
          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr>
                    <th>Select</th>
                 
                    <th>Username</th>
                    <th>Email</th>
                    <th>Phone number</th>
                    <th>Services</th>
                    <th>Locations</th>
                    <th>Retailter Per</th>


                    <th>User Type</th>
                    <th>Admin ver</th>
                    <th>Email ver</th>
                    <th>Agreed pol</th>

                    <th>Registered at</th>
                    <th>Active</th>


                    


                    <th>Files</th>

                    
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((row) => (
                    <tr
                      key={row._id}
                      className={selectedRows.includes(row._id) ? 'selected' : ''}
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(row._id)}
                          onChange={() => toggleSelection(row._id)}
                        />
                      </td>
                      
                      <td><img src={row.profile_picture?row.profile_picture:require('../../../assets/user-circle.png')} style={{width:30,height:30,borderRadius:'100%'}}/> {row.username}</td>
                      <td>{row.email}</td>
                      <td>{row.phone_number}</td>
                      <td>{row.user_type == "contractor" && row.contractorEntity.services?row.contractorEntity.services:'#'}</td>
                      <td>{row.user_type == "contractor" && row.contractorEntity.locations?row.contractorEntity.locations:'#'}</td>
                      <td>{row.user_type == "contractor" && row.contractorEntity.retailer_percentage?row.contractorEntity.retailer_percentage['$numberDecimal']*100+'%':'#'}</td>

                      <td>{row.user_type}</td>

                      <td>{row.isVerifiedByAdmin == false?'No':'Yes'}</td>
                      <td>{row.isVerified == false?'No':'Yes'}</td>
                     

                      <td>{row.agreed_to_policies == false?'No':'Yes'}</td>

                      <td>{row.createdAt}</td>
                      <td>{row.is_active?'Yes':'No'}</td>



                      <td>
                      {row.contractorEntity?.license ? (
                          <a href={row.contractorEntity.license} target="_blank" className="btn btn-primary">
                           License 
                          </a>
                        ) : '#'}

                        {row.contractorEntity?.insurance ? (
                          <a href={row.contractorEntity.insurance} target="_blank" className="btn btn-primary ml-2">
                            Insurance
                          </a>
                        ) : '#'}
                      </td>
                      <td>

                      <a
                          className="btn btn-primary btn-sm ml-2"
                          href={`/admin/users/edit/${row._id}`}
                        >
                          Edit
                        </a>


                        <button
                          className="btn btn-danger btn-sm ml-2"
                          onClick={() => {
                           deleteUser(row._id)
                          }}
                        >
                          Delete
                        </button>



                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              ({currentPage}/{totalPages})&nbsp;
              <button
                className="btn btn-secondary mr-2"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default UserList;
