import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Rating } from 'react-simple-star-rating';
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-activity';

const id = await window.location.pathname.split('/')[2];

export default function ContractorProfile({socket,user,detectPageChange}){
    const [contractorDetails,setContractorDetails] = useState({})
    const [ratings,setRatings] = useState([])
    const [review,setReview] = useState(0)
    const [isLoading,setisLoading] = useState(true)
    const [foundUser,setFoundUser] = useState(true)
   

   const getContractorDetails = async()=>{
    const user =await localStorage.getItem(localStorageUserKey);
    try {
      const contractor_id =await window.location.pathname.split('/')[2]
      const res = await axios.get(`${base_url}/apis/user/contractor?contractor_id=${contractor_id}`,{
         headers:{
           "x-api-key": process.env.REACT_APP_API_KEY,
           "authorization": user,
           'Content-Type': 'application/json', // Set the content type
         }
       })
       if(res.status == 200){
         let sum_of_ratings =0
         
         setContractorDetails(res.data.contractor)
         setRatings(res.data.ratings)
 
         res.data.ratings?.map(data=>{
           sum_of_ratings = sum_of_ratings+data.rating
         })
       
         if(sum_of_ratings == 0 && res.data.ratings.length == 0){
         setReview(0)
 
         }else{
         setReview(sum_of_ratings/res.data.ratings.length)
 
         }
       
        
         setisLoading(false)
       }
    } catch (error) {
      setContractorDetails(null)
      setisLoading(false)
      setFoundUser(false)
    }
  
    }


    
  

    useEffect(()=>{
      detectPageChange(window.location.pathname)

    
      getContractorDetails()
      
    },[])
  

  if(foundUser){


  if(contractorDetails && isLoading == false){

    return(
        <>

        
  {/*Start Single Service Area*/}
  <section className="single-service-area">
    <div className="container">
      <div className="row">
        <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12" >
          <div className="single-service-sidebar" >
            <div
              className="sidebar-contact-box text-center"
              
            >
              <div className="inner-content">
                <div className="icon-holder">
                  <img src={contractorDetails?.profile_picture} style={{borderRadius:'100%',width:150,height:150}}/>
                 
                </div>
               

                <h3>
                 {contractorDetails?.username}


                  <br />
                  
                </h3>
                <Rating
                    readonly
                    initialValue={review}
                  size={35}
                  /> <b style={{color:'white'}}>{review?.toFixed(2)} ({ ratings?.length })</b> 

                <div className="button" >
                  <a href={`/chat/${id}`}>Contact Now</a>
                </div>


                  

                <br />
                <b style={{color:'white'}}><i class="fa fa-phone" aria-hidden="true"></i>
                {contractorDetails?.phone_number}</b>
              </div>
             
           
              <br />


              <a href={contractorDetails?.contractorEntity?.license} target="_blank" className={`btn btn-${contractorDetails?.contractorEntity?.license?'success':'secondary'}`}  style={{width:'100%',fontSize:15}}>View License Document 
              <br />
              <b style={{color:"white"}}>{contractorDetails?.contractorEntity?.license?"Provided":"Not Provided"}</b></a>
              <br />
              <br />

             
              <a href={contractorDetails?.contractorEntity?.insurance} target="_blank" className={`btn btn-${contractorDetails?.contractorEntity?.insurance?'success':'secondary'}`}  style={{width:'100%',fontSize:15}}>View Insurance Document 
              <br />
              <b style={{color:"white"}}>{contractorDetails?.contractorEntity?.insurance?"Provided":"Not Provided"}</b></a>

              
            
            </div>
            
           
          </div>
         
        </div>
        <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12">
          <div className="single-service-top">
            <div className="sec-title-style1">
              <div className="title">About Handyman</div>
              <div className="text">
                <div className="decor-left">
                  <span />

                  
                </div>
                <br />
                <p>
                 {contractorDetails?.about_me}
                </p>
              </div>
            </div>
            <div className="inner">
         
             
            </div>
          </div>
          <div className="Servicesimg-content">

          <div className="sec-title-style1">
              <div className="title">Services Provided</div>
              <div className="text">
                <div className="decor-left">
                  <span />
                </div>
                <br />
                 {contractorDetails?.contractorEntity?.services?.split(',').map((service, index) => (
                      <span key={index} style={{ backgroundColor: '#007aff', padding: 2, fontSize: 15, marginLeft: 3,marginTop:10,color:'white' }}>
                        {service} 
                      </span>

                      
                    ))}



              
              </div>
            </div>


           
          </div>
          <div className="sec-title-style1">
              <div className="title">What Clients are  Saying</div>
              <div className="text">
                <div className="decor-left">
                  <span />
                </div>
              
              </div>
            </div>


              <h3>Client Reviews({ratings?.length})</h3>
              <br />


            {ratings?.map((data,index)=>{
                 return <div className='border p-3' key={index}>
                  <img src={data.client[0]?data.client[0]?.profile_picture?data.client[0]?.profile_picture:require('../../../assets/user-circle.png'):''} style={{width:50,height:50,borderRadius:50}}/>
                <Rating
                  readonly
                  size={25}
                  initialValue={data.rating}
                  
                />({data.rating})
               
                 <p style={{marginLeft:70,color:'black'}}>{data.comment}</p>
                </div>
            })}
          


          
        
        </div>
      </div>
    </div>
  </section>
  {/*End Single Service Area*/}
</>

    )
  }else{
    return <center><Spinner size={25} color='skyblue'/></center> 
  }
}else{
  return  <center className='mt-5'>
  <h1>Contractor Not Found(404) </h1>
<br />
  <button className='btn btn-primary' onClick={()=>{
   window.history.back()
  }}>Go Back</button>
</center>
}

}