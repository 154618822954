import React, { useEffect } from 'react'
import Routes from './routes'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { useLocation } from 'react-router-dom'

export default function App (){
   
   
    return  <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID}}>
    <Routes />
    </PayPalScriptProvider>
 
}