import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import axios from 'axios';
import base_url from '../../../base_url';
import {localStorageUserKey, localStorageAdminKey} from '../../../local_storage_vars';
import { useParams } from 'react-router-dom';






const AdminEditJob = () => {
  const params = useParams()
  const [name, setName] = useState('');
 
  const [workNeeded, setWorkNeeded] = useState('');
  const [city,setCity] = useState('')
  const [defaultWorkNeededValue,setDefaultWorkNeeded]=useState('')
  const [defaulCitiesValue,setDefaulCitiesValue] = useState('')

  const [first_appointmentDate, setFirstAppointmentDate] = useState('');
  const [second_appointmentDate, setSecondAppointmentDate] = useState('');

  const [timeslotsData,setTimeSlotsData] = useState([])
  const [first_timeslot,setfirst_timeslot] = useState('')
  const [second_timeslot,set_second_timeslot] = useState('')

  const [address,setAddress] = useState('')

  const [phone_number,setPhonenumber] = useState('')
  const [zipcode,setZipcode] = useState('')
  const [btn_loading,setbtnLoading] = useState(false)
  const [cities,setCities] = useState([])
  const [servicesData,setservicesData] = useState([])


  const fetchCities = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/city/get-cities`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let temp_cities =[]
        response.data?.map(i=>{
          temp_cities.push({value:i.name,label:i.name})
        })
        setCities(temp_cities);

      } else {
       setCities([])
       console.log(response.status)
      }
    } catch (error) {
      console.error(error);
      setCities([])

    }
  };


  const fetchServices = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/service/get-services`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let temp_services = []
        response.data?.map(i=>{
          temp_services.push({value:i.name,label:i.name})
        })

        setservicesData(temp_services)
      } else {
        
        setservicesData([])
      }
    } catch (error) {
      // console.error(error);
      setservicesData([])
     
    }
  };


  const formatTimeWithAmPm = (timeString) => {
    if (!timeString) return '';

    const [hours, minutes] = timeString.split(':');
    let formattedHours = parseInt(hours, 10);

    // Determine AM or PM
    const period = formattedHours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    formattedHours = formattedHours % 12 || 12;

    return `${formattedHours}:${minutes} ${period}`;
  };


  const validate = ()=>{
    if(name.length<4){
      alert("Title must be 4 character long")
      

      return false
    }

   

    if(!workNeeded){
      alert("Services Field is required")
      return false
    }

   
    if(zipcode.length<4){
      alert("Zipcode must be atleast 4 characters")
     
      return false
    }
    if(first_appointmentDate.length<1 || second_appointmentDate.length<1){
      alert("both appointment dates are required to be selected")
      
      return false
    }

    if(address.length<4){
      alert("Address must be atleast 4 characters")
     
      return false
    }

 
    
    return true
    
  }

  const GetUserTimeSlots = async()=>{
    const user = await localStorage.getItem(localStorageAdminKey);

    await axios.get(`${base_url}/apis/auth/profile`,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', 
      },
    })
    .then(res=>{
      if(res.status == 200){
        

        if(res.data.user.timeslot1_from.length>0 && res.data.user.timeslot1_to.length>0 && res.data.user.timeslot2_from.length>0 && res.data.user.timeslot2_to.length>0){

        setTimeSlotsData([
          { id: 1, from: res.data.user.timeslot1_from, to: res.data.user.timeslot1_to },
          { id: 2,from: res.data.user.timeslot2_from, to: res.data.user.timeslot2_to },
        ])

        }
         

      }else{
        return false
      }
    })
    .catch(err=>{
     return false
    })
  }


  const get_job_details = async()=>{
   const job_id = await params.id
   
   const user =await localStorage.getItem(localStorageAdminKey);

    await axios.get(`${base_url}/apis/job/view_job_for_edit_by_admin?job_id=${job_id}`,{
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "authorization": user,
          'Content-Type': 'application/json', // Set the content type
        },
      })
      .then(res=>{
        if(res.status == 200){
            setName(res.data.job.name)
            
            setFirstAppointmentDate(res.data.job.first_appointment_date)
            console.log(res.data.job.first_appointment_date)
            setSecondAppointmentDate(res.data.job.second_appointment_date)
            

            setZipcode(res.data.job.zipcode)
            setPhonenumber(res.data.job.phone_number)
            
            setDefaultWorkNeeded({value:res.data.job.type_of_work,label:res.data.job.type_of_work})
            setDefaulCitiesValue({value:res.data.job.location,label:res.data.job.location})
           
        }else{
            alert("Something Went Wrong")
        }
      })
      .catch(err=>{
        alert("Something Went Wrong")
      })
  }


  const handleFormSubmit = async() => {
  
   const user =await localStorage.getItem(localStorageAdminKey);
   const job_id = await params.id
    const is_validate = validate();

    if (is_validate && !btn_loading) {
     
      setbtnLoading(true);
   
      let formData = new FormData()
      formData.append('name',name)
      formData.append('type_of_work',workNeeded)
     
      formData.append('zipcode',zipcode)
      formData.append('location',city)
      formData.append('address',address)

      formData.append('phone_number',phone_number)
      formData.append('job_id',job_id)


      formData.append('first_appointment_date',first_appointmentDate)
      formData.append('second_appointment_date',second_appointmentDate)
      
      try {
        await axios.put(`${base_url}/apis/job/update_job`,formData, {
        
          
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "authorization": user,
            'Content-Type': 'application/json',
          },
        })
        .then(response=>{
          if (response.status == 200) {
            alert(response.data.message);
          } else {
            // Handle error responses here
            alert(response.data.message);
          }
        
          setbtnLoading(false);
        })
        .catch(err=>{
          alert("Something Went Wrong")
          setbtnLoading(false);

        })
      
      } catch (error) {
        alert("Something Went wrong");
        setbtnLoading(false);
      }
  
  
    }

    
  };

  useEffect(()=>{
    get_job_details()
    fetchCities()
    fetchServices()
    GetUserTimeSlots()
  },[])

  

  return (
    <div className="container mt-5">
      <h2>Edit Job</h2>
     
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Title:
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

       
          <Select
            options={servicesData}
            isMulti={false}
            closeMenuOnSelect={false}
            defaultValue={defaultWorkNeededValue}
            isLoading={servicesData.length<1?true:false}
          
            placeholder="Services"
            onChange={(val)=>setWorkNeeded(val.value)}
          />

        <br />
          <Select
            options={cities}
            isMulti={false}
            closeMenuOnSelect={false}
            defaultValue={defaulCitiesValue}
            
            isLoading={cities.length<1 ?true:false}
            placeholder="City"
            onChange={(val)=>setCity(val.value)}
          />
          <br />
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
           Address:
          </label>
          <input
            type="text"
            className="form-control"
            id="address"
            
            onChange={(e) => {
              setAddress(e.target.value)
            }}
            required
          />
        </div>
        <br />
        <div className="container mt-3 border p-4 rounded">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3" >
            <label htmlFor="dateInput" className="form-label">First Appointment Date:</label>
            <input type="date" className="form-control" id="dateInput" value={first_appointmentDate} onChange={(val)=>setFirstAppointmentDate(val.target.value)}/>
          </div>
        </div>
      </div>

    


    </div>



    <div className="container mt-3 border p-4 rounded">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3" >
            <label htmlFor="dateInput" className="form-label">Second Appointment Date:</label>
            <input type="date" className="form-control" value={second_appointmentDate} onChange={(val)=>setSecondAppointmentDate(val.target.value)} id="dateInput"/>
          </div>
        </div>
      </div>

   


    </div>

        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Zipcode:
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={zipcode}
            onChange={(e) => {
              setZipcode(e.target.value)
            }}
            required
          />
        </div>



        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Phone Number:
          </label>
          <input
            type="number"
            className="form-control"
            id="name"
            value={phone_number}
            onChange={(e) => {
              setPhonenumber(e.target.value)
            }}
            required
          />
        </div>


        <button onClick={handleFormSubmit} disabled={btn_loading} className="btn btn-primary">
          {btn_loading?<Spinner color='skyblue' size={18}/>:null}&nbsp;&nbsp;Submit Job
        </button>

        <button onClick={()=>window.history.back()}  className="btn btn-danger">
         Cancel
        </button>
    
    </div>
  );


};

export default AdminEditJob;
