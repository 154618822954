import axios from 'axios';
import React, { useEffect, useState } from 'react';
import base_url from '../../../base_url';
import { Spinner } from 'react-activity';

const ResetPassword = ({detectPageChange}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isLoading,setisLoading] = useState(true)


  
useEffect(()=>{
  detectPageChange(window.location.pathname)
   
},[])



  const checkToken = async()=>{
    const token =await window.location.pathname.split('/')[2]
    
    let formData = new FormData()
    formData.append('token',token)
    axios.post(`${base_url}/apis/auth/check_reset_token`,formData,{
      headers:{
          "x-api-key": process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json', 
      }
    })
  .then(res=>{
    if(res.status == 200){
      
      if(res.data.found){
        setisLoading(false)
      }else{
        alert(res.data.message)
      }
    }
  })
  .catch(err=>{
    console.log(err)
    alert("Something Went Wrong")
  })
  }

  const validateForm = ()=>{
    if(password.length<6){
      alert("Password Length Must Be Greater Than 6")
      return false
    }

    if(confirmPassword != password){
      alert("Confirm Password Must Match The New Password")
      return false
    }

    return true
  }


  const handleResetPassword = async() => {
    const token =await window.location.pathname.split('/')[2]
    const is_validated = validateForm()

    if(is_validated){
      let formData = new FormData()
      formData.append('token',token)
      formData.append('newPassword',password)
       axios.post(`${base_url}/apis/auth/reset_password`,formData,{
        headers:{
            "x-api-key": process.env.REACT_APP_API_KEY,
           
            'Content-Type': 'application/json', 
        }
      })
    .then(res=>{
      if(res.status == 200){
        
        if(res.data.is_updated){
          alert("Password Updated")
          setIsPasswordReset(true)
          window.location = "/login"
        }else{
          alert(res.data.message)
        }
      }
    })
    .catch(err=>{
      alert("Something Went Wrong")
    })
    }
    

  };


  useEffect(()=>{
    checkToken()
  },[])


  if(isLoading == false){

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2 className="text-center mb-4">Reset Password</h2>

          {isPasswordReset ? (
            <div className="alert alert-success">
              Password has been reset successfully!
            </div>
          ) : (
              <div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={handleResetPassword}
              >
                Reset Password
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}else{
  return <center>
    <br />
    <br />

    <Spinner color='skyblue' size={25}/></center>
}

};

export default ResetPassword;
