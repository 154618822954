
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import '../../pages/main/home/JobsPage.css'; 

import { Spinner } from 'react-activity';

const ClientjobContainer = ({job,pageName,favorites,showDescription,handleToggleFavorite,delete_job,deletionLoading,favorite_toggled})=>{
    return(
        <div key={job._id} className="col mb-4">
        <div className="card h-100">
        <div className="card-header">
        <img style={{width:30,height:30,borderRadius:30}} src={pageName === "favorites" ? job.addedByUser[0].profile_picture?job.addedByUser[0].profile_picture:require('../../assets/user-circle.png') : job.added_by[0].profile_picture?job.added_by[0].profile_picture:require('../../assets/user-circle.png') } alt="User" className="user-image" />
        <b className="card-title">{' '}{pageName === "favorites" ? job.addedByUser[0].username : job.added_by[0].username}</b>
        <b className="card-text float-right">Posted on: {pageName === "favorites" ? new Date(job.job.createdAt).toLocaleDateString() : new Date(job.createdAt).toDateString()}</b>

        
      </div>
          <div className="card-body">
         
              <h4 className="card-title">{pageName == "favorites"?job.job.name:job.name}</h4>
           
            <p className="card-text">
              <strong>Phone Number:</strong> {pageName == "favorites"?job.job.phone_number:job.phone_number}
              <br />
              <strong>Service:</strong> {pageName == "favorites"?job.job.type_of_work:job.type_of_work}
             
              <br />
              <strong>Zipcode:</strong> {pageName == "favorites"?job.job.zipcode:job.zipcode}
              <br />
              <strong>City:</strong> {pageName == "favorites"?job.job.location:job.location}
              <br />
          <strong>Address:</strong> {pageName == "favorites"?job.job.address:job.address}
            </p>

            <div className='border p-2'>
            <strong>Description:</strong>
            {showDescription(job)}
            </div>
            <div className='border p-2'>
            <b>APPOINTMENT OPTION # 1 : </b>
            <p><b>Date: </b> {pageName == "favorites"?job.job.first_appointment_date:job.first_appointment_date}</p>
            <p><b>Time: </b> {pageName == "favorites"?job.job.first_timeslot:job.first_timeslot}</p>

            <hr />

            <b>APPOINTMENT OPTION # 2 :</b>
            <p><b>Date: </b> {pageName == "favorites"?job.job.second_appointment_date:job.second_appointment_date}</p>
            <p><b>Time: </b> {pageName == "favorites"?job.job.second_timeslot:job.second_timeslot}</p>

            </div>

            <br />
            {/* Toggle heart icon based on favorite status */}
            <button
              className="btn btn-link float-right"
              disabled={favorite_toggled}
              onClick={() => handleToggleFavorite(pageName == "favorites"?job.job._id:job._id)}
            >
              <FontAwesomeIcon
              size={'lg'}
                icon={favorites.includes(pageName == "favorites"?job.job._id:job._id) ? solidHeart : regularHeart}
                color={favorites.includes(pageName == "favorites"?job.job._id:job._id) ? 'red' : 'gray'}
              />
            </button>

            <button  onClick={()=>delete_job(pageName == "favorites"?job.job._id:job._id)} className='btn btn-danger float-left '>{deletionLoading?<Spinner color='skyblue' size={20} />:null}Delete</button>
            <Link to={`/job/edit/${pageName == "favorites"?job.job._id:job._id}`} className='btn btn-primary float-left ml-2'>Edit</Link>
            <br />
            <Link to={`/job/offer/${pageName == "favorites"?job.job._id:job._id}`} style={{
              width: '100%',
             
              alignContent: 'center',
             
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              whiteSpace: 'normal',
              wordWrap: 'break-word'
          }} className='btn btn-success mt-4'>Click to view Handymen offers for your Job</Link>

            <Link to={`/contractors/${pageName == "favorites"?job.job._id:job._id}`} style={{width:'100%'}} className='btn btn-primary mt-4'>Find Handyman for your Job</Link>
          </div>
        </div>
      </div>
    )
}

export default ClientjobContainer