// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-card {
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 10px;
  height: 100%; /* Fixed height for profile card */
}

.profile-image {
  flex: 0 0 auto;
  margin-right: 20px;
  width: 120px;
  height: 135px;
}

.profile-details {
  flex: 1 1;
  
}

.profile-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.profile-card img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-card h4 {
  margin-bottom: 10px;
  font-size: 1.5em;
  color: #333;
}

.profile-card p {
  margin-bottom: 5px;
  font-size: 1em;
  color: #666;
}
`, "",{"version":3,"sources":["webpack://./src/pages/main/contractors/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,0CAA0C;EAC1C,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,eAAe;;EAEf,gBAAgB;EAChB,YAAY,EAAE,kCAAkC;AAClD;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,SAAO;;AAET;;AAEA;EACE,2BAA2B;EAC3B,2CAA2C;AAC7C;;AAEA;EACE,WAAW;EACX,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,0CAA0C;AAC5C;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;AACb","sourcesContent":[".profile-card {\r\n  border-radius: 12px;\r\n  margin-bottom: 20px;\r\n  padding: 20px;\r\n  background-color: #fff;\r\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\r\n  transition: all 0.3s ease;\r\n  display: flex;\r\n  align-items: center;\r\n  flex-wrap: wrap;\r\n\r\n  margin-top: 10px;\r\n  height: 100%; /* Fixed height for profile card */\r\n}\r\n\r\n.profile-image {\r\n  flex: 0 0 auto;\r\n  margin-right: 20px;\r\n  width: 120px;\r\n  height: 135px;\r\n}\r\n\r\n.profile-details {\r\n  flex: 1;\r\n  \r\n}\r\n\r\n.profile-card:hover {\r\n  transform: translateY(-5px);\r\n  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.profile-card img {\r\n  width: 100%;\r\n  max-width: 100%;\r\n  height: auto;\r\n  border-radius: 50%;\r\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.profile-card h4 {\r\n  margin-bottom: 10px;\r\n  font-size: 1.5em;\r\n  color: #333;\r\n}\r\n\r\n.profile-card p {\r\n  margin-bottom: 5px;\r\n  font-size: 1em;\r\n  color: #666;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
