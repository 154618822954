// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.about-us-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .about-us-header h1 {
    font-size: 3rem;
  }
  
  .about-us-content {
    display: grid;
    gap: 40px;
  }
  
  .about-us-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-section h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .about-us-container {
      padding: 20px;
    }
  
    .about-us-header h1 {
      font-size: 2rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/main/about_us/style.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,uCAAuC;EACzC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA,mCAAmC;EACnC;IACE;MACE,aAAa;IACf;;IAEA;MACE,eAAe;IACjB;EACF","sourcesContent":["\r\n.about-us-container {\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n    padding: 40px;\r\n    background-color: #f7f7f7;\r\n    border-radius: 10px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  .about-us-header {\r\n    text-align: center;\r\n    margin-bottom: 30px;\r\n  }\r\n  \r\n  .about-us-header h1 {\r\n    font-size: 3rem;\r\n  }\r\n  \r\n  .about-us-content {\r\n    display: grid;\r\n    gap: 40px;\r\n  }\r\n  \r\n  .about-us-section {\r\n    background-color: #fff;\r\n    padding: 20px;\r\n    border-radius: 5px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  .about-us-section h2 {\r\n    font-size: 2rem;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  /* Media query for responsiveness */\r\n  @media (max-width: 768px) {\r\n    .about-us-container {\r\n      padding: 20px;\r\n    }\r\n  \r\n    .about-us-header h1 {\r\n      font-size: 2rem;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
