import { useState } from "react"
import { Spinner } from "react-activity"
import base_url from "../../../base_url"
import axios from "axios"
import { useEffect } from "react"


export default function Cities({socket,user,detectPageChange}){

    
        const [data,setData] = useState([])
       
        const [isLoading,setisLoading]= useState(true)
  
        const fetchData = ()=>{
         
          axios.get(`${base_url}/apis/city/get-cities`,{
              headers:{
                  "x-api-key": process.env.REACT_APP_API_KEY,
                  
                  'Content-Type': 'application/json', 
              }
          })
          .then(res=>{

                let temp = []
               
                res.data.map(i=>{
                  temp.push({value:i.name,label:i.name})
                })
                temp.sort((a, b) => a.value.localeCompare(b.value))

                setData(temp)
                setisLoading(false)

            
          })
          .catch(err=>{
              alert("Something Went Wrong")
          })
        }
  
        useEffect(()=>{
    detectPageChange(window.location.pathname)

            fetchData()
        },[])


        if(!isLoading){
          return(
            <div className="container">
            <h1 className="mt-5 mb-4">Our Cities</h1>
            <p>To consider a new City for you please email us at Support@81Handyman.com</p>

            <div className="row">
              {data.map((city, index) => (
                <div className="col-md-4 mb-4" key={index}>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">{city.value}</h5>
                     
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
         )
        }else{
          return <center><Spinner color="skyblue" size={25}/></center>
        }
       
    
}