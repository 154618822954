
import React, { useState,useEffect } from 'react';
import PricingList from './pricing_list';
import AddPricingForm from './add_pricing_form';
import axios from 'axios';
import base_url from '../../../base_url';
import {localStorageUserKey, localStorageAdminKey} from '../../../local_storage_vars';


const AdminPricing = () => {
  const [pricings,setPricings] = useState([])
  const fetchPricings = async () => {
    try {
      const user = await localStorage.getItem(localStorageAdminKey);

      const response = await axios.get(`${base_url}/apis/pricing/`,
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        },
      });
      setPricings(response.data);
    } catch (error) {
      alert("Failed to fetch pricings.Please try again")
    }
  };

  useEffect(()=>{
    fetchPricings()
  },[])
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <PricingList pricings={pricings} fetchPricings={fetchPricings}/>
        </div>
        <div className="col-md-6">
          <AddPricingForm onAdd={fetchPricings}/>
        </div>
      </div>
    </div>
  );
};

export default AdminPricing;
