import React, { useState } from 'react';
import './style.css'; // Custom CSS file for styling
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';
import { useEffect } from 'react';
import { Spinner } from 'react-activity';
import { Rating } from 'react-simple-star-rating';

const Contractors = ({detectPageChange}) => {
  const [contractors, setContractors] = useState([]);
  const [isLoading,setisLoading]=useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages,setTotalPages] = useState(0) 
  const pageSize = 10; // Adjust the page size as needed
  const [error,setError] = useState('')

  const getContractors = async()=>{
    const job_id = await window.location.pathname.split('/')[2];
    const user = await localStorage.getItem(localStorageUserKey);

    await axios.get(`${base_url}/apis/job/job_contractors?job_id=${job_id}&&page=${currentPage}&&pageSize=${pageSize}`, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', // Set the content type
      },
    })
    .then(res=>{
      
      if(res.status == 200){
        console.log(res.data.data)
        
        
        setTotalPages(res.data.totalPages)
        setContractors(res.data.data)
        setisLoading(false)
      }else{
       
        alert(res.data.message)
      }
    })
    .catch(err=>{
     
      if(err && err.response){
        setError(err.response.data.message)
      }else{
        setError("Something went wrong")
      }
    })
  }
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(()=>{
    getContractors()  
    window.scrollTo(0, 0)

  },[currentPage])


  useEffect(()=>{
    detectPageChange(window.location.pathname)

  },[])
  if(error) return <center className='container mt-5'>
  <h2>{error}</h2>
 </center>
  if(isLoading == false){
    return(
       <div className="container p-5">
   {!isLoading && contractors.length > 0 ? (
     <div className="row">
       {/* Profile Cards */}
      {/* Profile Cards */}
      {contractors.map((contractor) => (
              <div key={contractor._id} style={{ cursor: 'pointer' }} onClick={() => (window.location = `/contractor_profile/${contractor._id}`)} className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                <div className="profile-card">
                  <div className="profile-image">
                    <img src={contractor.profile_picture?contractor.profile_picture:require('../../../assets/user-circle.png')} alt={contractor.username} style={{ height: 110 }} />
                  </div>
                  <div className="profile-details">
                    <h4>{contractor.username}</h4>
                    <Rating initialValue={contractor.averageRating} size={17} readonly /><b>{contractor.averageRating?.toFixed(2)}</b> ({contractor.rating_count?.length})<br />
                    Location:
                    {contractor.contractorEntity.locations?.split(',').slice(0, 3).map((location, index) => (
                      <span key={index} style={{ backgroundColor: '#007aff', padding: 2, fontSize: 12, marginLeft: 3,color:'white' }}>
                        {location}
                      </span>
                    ))}
                    {contractor.contractorEntity.locations?.split(',').length > 3  && <span>...more</span>}
                    <br />
                    Services:
                    {contractor.contractorEntity.services?.split(',').slice(0, 3).map((service, index) => (
                      <span key={index} style={{ backgroundColor: '#007aff', padding: 2, fontSize: 12, marginLeft: 3,color:'white' }}>
                        {service}
                      </span>
                    ))}
                    {contractor.contractorEntity.services?.split(',').length > 3 && <span>...more</span>}
                    <p>License: {contractor.contractorEntity?.license ? <b style={{ fontSize: 12, backgroundColor: 'green', padding: 1, borderRadius: 2,color:'white' }}>Provided</b> : <b style={{ color: 'red', fontSize: 12, backgroundColor: 'orange', padding: 1, borderRadius: 2,color:'white' }}>Not Provided</b>}</p>
                    <p>Insurance: {contractor.contractorEntity?.insurance ? <b style={{ fontSize: 12, backgroundColor: 'green', padding: 1, borderRadius: 2,color:'white' }}>Provided</b> : <b style={{ color: 'red', fontSize: 12, backgroundColor: 'orange', padding: 1, borderRadius: 2,color:'white' }}>Not Provided</b>}</p>

                  </div>
                <button className='btn btn-primary' style={{width:'100%'}} onClick={()=>window.location=`/contractor_profile/${contractor._id}`}>View Handyman Profile</button>

                </div>


              </div>
            ))}



       {/* Add more profile cards as needed */}
     </div>) : (
             <div className="text-center">
               <h2 style={{ marginTop: '10px', color: 'red' }}>Contractors Not Found</h2>
             </div>
           )}
   
      <br />
      <br />
      <br />

     {!isLoading&&contractors.length > 0 && (
             <div className="d-flex justify-content-center mt-5">
               <button
                 disabled={currentPage === 1}
                 className="btn btn-primary mr-2"
                 style={{width:100}}
                 onClick={() => handlePageChange(currentPage - 1)}
               >
                 Previous 
               </button>
               <span className="align-self-center">
                 Page {currentPage} / {totalPages}
               </span>
               <button
                 disabled={currentPage === totalPages}
                 className="btn btn-primary ml-2"
                 style={{width:100}}

                 onClick={() => handlePageChange(currentPage + 1)}
               >
                 Next 
               </button>
             </div>
           )}
   
   
   </div>
   
    ) 
    
   }else{
       return <center><Spinner color='skyblue' size={25}/></center>
     }
};

export default Contractors;
