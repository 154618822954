import { useState } from "react"
import { Spinner } from "react-activity"
import base_url from "../../../base_url"
import axios from "axios"
import { useEffect } from "react"


export default function Pricings({detectPageChange}){

    
        const [data,setData] = useState([])
       
        const [isLoading,setisLoading]= useState(true)
  
        const fetchData = ()=>{
         
          axios.get(`${base_url}/apis/pricing`,{
              headers:{
                  "x-api-key": process.env.REACT_APP_API_KEY,
                  
                  'Content-Type': 'application/json', 
              }
          })
          .then(res=>{

            let temp = []
            res.data.map(i=>{
              temp.push({value:i.service,price:i.price,to:i.to})
            })
            temp.sort((a, b) => a.value.localeCompare(b.value))
            
            setData(temp)
        
            setisLoading(false)

          })
          .catch(err=>{
              alert("Something Went Wrong")
          })
        }
  
        useEffect(()=>{
          detectPageChange(window.location.pathname)

            fetchData()
        },[])

       
        if(!isLoading){
          return(
            <div className="container">
            <h2 className="mt-5 mb-4">National Average Handyman Job Pricings</h2>
            <div className="row">
              {data.map((pricing, index) => (
                <div className="col-md-4 mb-4" key={index}>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title bg-primary p-3" style={{color:'white',borderRadius:5}}>{pricing.value}</h5>
                      <h5 className="card-title">${pricing.price} - ${pricing.to}</h5>
                     
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
         )
        }else{
          return <center><Spinner color="skyblue" size={25}/></center>
        }
       
    
}