
import React, { useEffect, useState } from 'react';
import './account.css'
import Profile from './profile';
import PaymentMethods from './payment_method';
import MembershipPage from '../memberships';
import ChangePassword from './change_password';
import axios from 'axios';
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';
import { Rating } from 'react-simple-star-rating';

const Account = ({detectPageChange}) => {
  const [selectedPage, setSelectedPage] = useState('profile');
  const [review,setReview] = useState(0)
  const [name,setName] = useState('')
  const [about_me,setAboutMe] = useState('')
  const [profilePictureFromDatabase,setProfilePictureFromDatabase]= useState('')
  const [userType,setUserType] = useState("")
 const [ratingsCount,setRatingsCount] = useState(0)

  const GetUserDetais = async(user,rating,ratings_count)=>{
   
        if(user.user_type == "contractor"){
          setReview(rating)
          setRatingsCount(ratings_count)

        }

        
        setName(user.username)
        
        setAboutMe(user.about_me)
        
        setProfilePictureFromDatabase(user.profile_picture)
        setUserType(user.user_type)
       
     
  }


 
  
useEffect(()=>{
  detectPageChange(window.location.pathname)
   
},[]) 


  const renderPage = () => {
    switch (selectedPage) {
      case 'profile':
        return <Profile userDetails={GetUserDetais}/>;
      case 'paymentMethods':
        return <PaymentMethods />;
      case 'memberships':
        return <MembershipPage />;
      case 'changePassword':
        return <ChangePassword />;
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Left side */}
        <div className="col-sm-3 profile-info bg-light p-4">
          <div className="profile-details">
          <center>

    <img
      src={profilePictureFromDatabase?profilePictureFromDatabase:require('../../../assets/user-circle.png')} 
      alt="Default"
      style={{ width: 150, height:150,borderRadius:200 }}
    />
    <br />
    {userType && userType == "contractor"?<><Rating
    readonly
    size={29}
    initialValue={review} /> <b>{review} ({ratingsCount})</b></>:null}

    <h3>{name}</h3>

   <br />

    <div className=' p-3' >
      <p className='float-left border p-3' style={{height:'100%',width:'100%'}}> 
      <b className='float-left'>About me : </b>
      <br />
      {about_me}</p>

    </div>
  
  
  </center>
          </div>
          <div className="profile-navigation mt-4">
           
            <button type="button" className={`btn ${selectedPage === 'profile' ? 'btn-primary' : 'btn-secondary'} btn-custom mr-2`} style={{width:'100%'}} onClick={() => setSelectedPage('profile')}>Profile</button>
            <br />
            <button type="button" className={`btn ${selectedPage === 'paymentMethods' ? 'btn-primary' : 'btn-secondary'} btn-custom`} style={{width:'100%'}} onClick={() => setSelectedPage('paymentMethods')}>Payment Methods</button>
            <br />
            <button type="button" className={`btn ${selectedPage === 'memberships' ? 'btn-primary' : 'btn-secondary'} btn-custom`} style={{width:'100%'}} onClick={() => setSelectedPage('memberships')}>Memberships</button>
            <br />
            <button type="button" className={`btn ${selectedPage === 'changePassword' ? 'btn-primary' : 'btn-secondary'} btn-custom`} style={{width:'100%'}} onClick={() => setSelectedPage('changePassword')}>Change Password</button>
            <br />
            <button type="button" className="btn btn-danger btn-custom" onClick={()=>{
              localStorage.removeItem(localStorageUserKey)
              window.location = "/login"

            }} style={{width:'100%'}}>Logout</button>
          </div>
        </div>

        {/* Right side */}
        <div className="col-sm-9 profile-content p-4">
          {renderPage()}
        </div>
      </div>
    </div>
  );
};

export default Account;
