import React from "react";


export default function WarningAlert({children}){
    return(
    <div className="alert alert-warning alert-dismissible fade show " role="alert">
    {children}

    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    )
}