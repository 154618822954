import { Image, Upload } from "antd"
import { useEffect, useState,useRef } from "react"
import { Link } from "react-router-dom"
import Select, { components } from 'react-select';
import validator from 'validator'
import base_url from '../../../base_url';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

export default function Register({detectPageChange}){
  const recaptcha = useRef();

    const [email,setEamil] = useState('')
    const [password,setPassword] = useState('')
    const [chosen_type,setChosen] = useState('')
    const [phone,setPhone]= useState('')
    const [name,setName] = useState('')
    const [company_name,setCompanyName] = useState('')
    const [zipcode,setZipcode] = useState('')
    const [services,setServices] = useState([])
    const [working_area,setWorkingArea] = useState([])
    const [servicesData,setServicesData] = useState([])
    const [about_me,setAboutMe] = useState('')
    const [is_loading,SetisLoading] = useState(false)
    const [locationsData,setLocationsdata] = useState([])
    const [agree_policy,setAgree_policy] = useState(false)
    const [insurance,setInsurance] = useState('')
    const [licenseFile,setLicenceFile] = useState("")
    const [confirmPassword,setConfirmPassword] = useState('')
   
    const [previewImage,setPreviewImage] = useState('')

    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);


    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

      
        useEffect(()=>{
          detectPageChange(window.location.pathname)
          
        },[])



      const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage(reader.result);
            setSelectedImage(file);
            
          };
          reader.readAsDataURL(file);
        }
      };

      const handleImageClick = () => {
        // Trigger the hidden file input when the image is clicked
        fileInputRef.current.click();
      };



      const fetchCitiesData =async ()=>{
        
        axios.get(`${base_url}/apis/city/get-cities`,{
            headers:{
                "x-api-key": process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json', 
            }
        })
        .then(res=>{
            if(res.status == 200){
                let temp = []
                res.data.map(i=>{
                  temp.push({value:i.name,label:i.name})
                })
                temp.sort((a, b) => a.value.localeCompare(b.value))

                setLocationsdata(temp)
               
            }else{
                alert(res.data.message)
            }
        })
        .catch(err=>{
            alert("Something Went Wrong")
        })
     }


     const fetchServicesData =async ()=>{
        
      axios.get(`${base_url}/apis/service/get-services`,{
          headers:{
              "x-api-key": process.env.REACT_APP_API_KEY,
              'Content-Type': 'application/json', 
          }
      })
      .then(res=>{
          if(res.status == 200){
              let temp = []
              res.data.map(i=>{
                temp.push({value:i.name,label:i.name})
              })
              temp.sort((a, b) => a.value.localeCompare(b.value))
              
              setServicesData(temp)
             
             
          }else{
              alert(res.data.message)
          }
      })
      .catch(err=>{
          alert("Something Went Wrong")
      })
    }



    const validateForm = ()=>{
      const captchaValue = recaptcha.current.getValue();
     

      if(validator.isEmail(email) == false){
        alert("Invalid Email")
        return false
      }

      if(name.length<5){
        alert("Name must be at least 5 characters")
        return false
      }

      if(password.length<6){
        alert("Password must be at least 6 characters")
        return false
      }

    
      if(confirmPassword != password){
        alert("Confirm Password do not match")
        return false
      }

      


      if(chosen_type == "contractor"){

       
        if( selectedImage == null){
          alert("Profile picture is required")
          return false;
        }

        if(zipcode.length<5){
          alert("Zipcode must be at least 5 characters")
          return false
        }
  
  
        if(about_me.length<10){
          alert("AboutMe must be at least 10 characters")
          return false
        }

        if(company_name.length<3){
          alert("Company name must be at least 3 characters")
          return false
        }

      


        if(working_area.length<1){
          alert("Working area is required")
          return false
        }

        if(services.length<1){
          alert("Services is required")
          return false
        }
      }

      if (!agree_policy) {
        alert("Please tick the checkbox to be able to continue");
        return false

      } 

      if (!captchaValue) {
        alert("Please verify the reCAPTCHA!");
        return false

      } 
  

      return true


    }



   const register_user = async()=>{
    try{
      const validate = validateForm()
      let formData = new FormData()


      if(validate && !is_loading){


        SetisLoading(true)

       
        formData.append("user_type",chosen_type)

        formData.append("username",name)
        formData.append("email",email)
        formData.append("password",password)
      

        if(chosen_type == "contractor"){
          formData.append("locations",working_area.map(data => data.value).join(','))
          formData.append("company_name",company_name)
          formData.append("services",services.map(data => data.value).join(','))
          formData.append("license",licenseFile)
          formData.append("insurance",insurance)

          formData.append("profile_picture",selectedImage)
          formData.append("phone_number",phone)
  
          formData.append("zipcode",zipcode)
          formData.append("about_me",about_me)

        }


       const response = await fetch(base_url+'/apis/auth/register',{
          method:'POST',
          body:formData,
          headers:{
              "x-api-key":process.env.REACT_APP_API_KEY
          }
        })
      const data= await response.json()
     
      SetisLoading(false)

      if(response.status == 201){
        alert(data.message)
        window.location = "/login"
      }else{
        alert(data.message)

      }
    }

    }catch(err){
      SetisLoading(false)

      alert("Something Went Wrong")
    }
     
        
       



      
    }

    useEffect(()=>{
      fetchCitiesData()
      fetchServicesData()
    },[])



    if(chosen_type == ""){
        return (
            <section className="contact-info-area">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="contact-form">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="sec-title-style1 float-left">
                          <div className="title">Choose</div>
                          <div className="text">
                            <div className="decor-left">
                               
                              <span />
                            </div>
                            <p>Choose Your Type*</p>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                    <div className="inner-box">
                   
                       
                        <form>
        
                        <button onClick={()=>setChosen('contractor')} style={{padding:5}}  data-loading-text="Please wait...">
                                Handyman
                                <span className="flaticon-next" />
                        </button>

                        <br />

                        <button onClick={()=>setChosen('client')} style={{padding:5}} data-loading-text="Please wait...">
                                Client
                                <span className="flaticon-next" />
                        </button>
                        </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
    }else{
        return(
            <>
      {/*Start form area*/}
      <section className="contact-info-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="contact-form">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="sec-title-style1 float-left">
                      <div className="title">Register</div>
                      <div className="text">
                        <div className="decor-left">
                          <span />
                        </div>
                        <p>Registeration Form</p>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className="inner-box">
                  <form
                    id="contact-form"
                    name="contact_form"
                    className="default-form"
                   
                   
                  >
                   {chosen_type == "contractor"? <center>
                   <div
                      role="button"
                      tabIndex={0}
                      onClick={handleImageClick}
                      onKeyPress={handleImageClick}
                      style={{ cursor: 'pointer' }}
                    >
                   <img
                      src={previewImage?previewImage:require('../../../assets/user-circle.png')}
                      alt="Preview"
                      style={{ width: 150, height:150,borderRadius:200 }}
                    />

                    {/* Hidden file input */}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                    />
                    </div>
                    <small>Click to pick profile picture</small>
                    </center>
                    :null}

                    <br />
                    <br />

                    <div className="row">
                      <div className="col-xl-12 col-lg-12">
                        
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="input-box">
                              <input
                                type="text"
                                name="form_name"
                                defaultValue=""
                                placeholder="Name"
                                onChange={(val)=>setName(val.target.value)}
                                required=""
                              />
                            </div>
                       
                          </div>

                          {chosen_type == "contractor"?<div className="input-box col-xl-12">
                              <input
                                type="text"
                                onChange={(val)=>setPhone(val.target.value)}
                                name="form_phone"
                                defaultValue=""
                                placeholder="Phone"
                              />
                          </div>:null}


                            <div className="input-box col-xl-12">
                              <input
                                type="email"
                                name="form_email"
                                onChange={(val)=>setEamil(val.target.value)}
                                defaultValue=""
                                placeholder="Email"
                                required=""
                              />
                            </div>
                           
                        </div>
                     
                      </div>
                     
                    </div>
    
    
    
                    {chosen_type == "contractor"? <div className="input-box xl-12">
                              <input
                                type="text"
                                name="form_website"
                                defaultValue=""
                                onChange={(val)=>setCompanyName(val.target.value)}
                                placeholder="Company Name"
                              />
                            </div>:null}
    
    
                            <div className="input-box" style={{ position: 'relative' }}>
                              <input
                                onChange={(val) => setPassword(val.target.value)}
                                type={showPassword ? "text" : "password"}
                                name="form_email"
                                value={password}
                                placeholder="Password"
                                required
                              />
                              <span
                                onClick={togglePasswordVisibility}
                                style={{
                                  position: 'absolute',
                                  right: '10px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer'
                                }}
                              >
                                <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                              </span>
                            </div>



                    <div className="input-box xl-12">
                            <input
                                type="password"
                                name="form_website"
                                onChange={(val)=>setConfirmPassword(val.target.value)}
                                defaultValue=""
                                placeholder="Confirm Password"
                            />
                    </div>



                    {chosen_type == "contractor"?<div className="input-box xl-12">
                            <input
                              onChange={(val)=>setZipcode(val.target.value)}
                                type="text"
                                name="form_website"
                                defaultValue=""
                                pattern="\d{5}"
                                placeholder="Ziplcode"
                            />
                    </div>:null}
                    
    
                    {chosen_type == "contractor"? <div className="input-box xl-12">
                          <label>License

                          <br />
                        <small >Please email support@81Handyman.com if you have Multiple Licenses  </small>

                          </label>
                            <input
                                type="file"
                                name="form_website"
                                defaultValue=""
                                placeholder="License"
                                onChange={(val)=>setLicenceFile(val.target.files[0])}
                                
                            />
                           
                    </div>:null}


                    {chosen_type == "contractor"? <div className="input-box xl-12 ">
                        <label>Insurance
                        <br />
                        <small >Please email support@81Handyman.com if you have Multiple Insurances </small>

                        </label>
                            <input
                                type="file"
                                name="form_website"
                                defaultValue=""
                                placeholder="Insurance"
                                onChange={(val)=>setInsurance(val.target.files[0])}
                                
                            />

                    </div>:null}

                    {chosen_type == "contractor"?
                    <Select
                    options={servicesData}
                    isMulti
                    isLoading={servicesData.length>0?false:true}
                    onChange={(val)=>setServices(val)}
                    closeMenuOnSelect={false}
                    placeholder="Select Services..."
                  />
                 :null}

                  <br />

                  {chosen_type == "contractor"?<Select
                    
                    options={locationsData}
                    isMulti
                    onChange={(val)=>{
                      setWorkingArea(val)
                    }}
                    isLoading={locationsData.length>0?false:true}

                    closeMenuOnSelect={false}
                    placeholder="Working Areas"
                  />:null}


                {chosen_type == "contractor"? <div className="input-box xl-12 mt-3">
                  <textarea className="form-control" onChange={(val)=>setAboutMe(val.target.value)} placeholder="About You....."></textarea>           
                  </div>:null}

                  <div className="input-box xl-12 border p-2 mb-5">
                            <input
                                style={{width:20,height:20}}
                                onChange={(val)=>{setAgree_policy(val.currentTarget.checked)}}
                                type="checkbox"
                                name="form_website"
                                defaultValue=""
                                
                               
                            /> <a href="/terms-conditions" target="_blank"  style={{fontSize:15}} className="ml-3">Agree with our Terms and Conditions</a>
                    </div>
               

                  <ReCAPTCHA ref={recaptcha} onAbort={()=>{
                    console.log("Aborted Captcha")
                  }} onError={(err)=>{
                    console.log(err)
                  }} sitekey={process.env.REACT_APP_SITE_KEY} />

                   
                  <br />
                  <br />
                  <div className='btn btn-primary'    onClick={register_user} >
                        {is_loading?<Spinner size={18} color='skyblue'/>:'Register'}
                       
                      </div>
                    

                          <br />
                        <center>


                    <p>Already Have An Account Want to? <Link to={'/login'}>Login</Link></p>
                    </center>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End form area*/}
    </>
    
        )
    }
   
}