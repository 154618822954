import React, { useEffect, useRef, useState } from "react";
import base_url from "../../../base_url";
import {localStorageUserKey} from '../../../local_storage_vars';
import { Spinner } from "react-activity";

export default function PaymentMethodSuccess() {
    const [userEmail, setEmail] = useState('');
    const [isLoading, setisLoading] = useState(true);
    const [error, setError] = useState('');
    const [isSucceed,setSucceed] = useState(false)

  const shouldLog  = useRef(true)

    const storePaymentMethod = async (email, payment_method) => {
       
      const user = await localStorage.getItem(localStorageUserKey);
      try {
        const response = await fetch(`${base_url}/apis/user/add-payment_method`, {
          method: 'POST',
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            authorization: user,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, payment_method }),
        });
  
        if (response.status === 200) {
          setisLoading(false);
          setSucceed(true)
          return true;
        } else {
          const data = await response.json();
          setError(data.message);
          setisLoading(false);
          return false;
        }
      } catch (error) {
        setError(error.message);
        setisLoading(false);
      }
    };
  
    const UserInfo = (token) => {
      fetch(process.env.REACT_APP_PAYPAL_USERINFO_API, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((response) => response.json())
        .then((userInfo) => {
          if (userInfo && userInfo.verified) {
            setEmail(userInfo.email);
            return storePaymentMethod(userInfo.email, 'Paypal');
          } else {
            setisLoading(false);
            setError('Paypal Account not found or the account is not verified');
            return false;
          }
        })
        .catch((error) => {
          setisLoading(false);
          setError(error.message);
        });
    };
  
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const desiredParam = params.get('id_token');
      
      if(shouldLog.current){
        shouldLog.current = false
      if ( desiredParam ) {
        UserInfo(desiredParam);
      } else {
        setError('Unauthorized token');
        setisLoading(false);
      }

    }

    }, []);
  
    if (!isLoading) {
      if (error !== '' && isSucceed == false) {
        return (
          <div>
            <center>
              <h3>{error}</h3>
            </center>
          </div>
        );
      } else {
        return (
          <div>
            <center style={{ padding: 20 }}>
              {userEmail ? (
                <>
                  <div className="border p-5">
                    <h2>Payment method added successfully</h2>
                    <a href="/account" className="btn btn-success">
                      Go to account
                    </a>
                  </div>
                </>
              ) : null}
            </center>
          </div>
        );
      }
    } else {
      return (
        <center>
          <Spinner size={40} color="skyblue" />
        </center>
      );
    }
  }