import { useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import base_url from "../../base_url";
import {localStorageUserKey} from '../../local_storage_vars';
import axios from 'axios';
import { PayPalButtons } from "@paypal/react-paypal-js";

export default function Paypal({price,membership_duration,type,membership_id}){

    const buy_membership = async(duration,type,action)=>{
        const user =await localStorage.getItem(localStorageUserKey);
        
        const formData = new FormData();
        formData.append('endDate', duration);
        formData.append('type', type);
        formData.append('membership_id', membership_id);


        
       await axios.post(`${base_url}/apis/user_membership/buy-membership`, formData, {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "authorization": user,
            'Content-Type': 'multipart/form-data', // Set the content type for FormData
          },
        })
          .then(async(response) => {
             const responseData = response.data;

            if (response.status === 200) {
              await action.order.capture()
             
              window.location = "/home"

            } else {
              alert(responseData.message);

            }
          })
          .catch(err => {
            alert("Internal server error");
          });
      }
   



    const createOrder = (data, actions) => {
      // Implement logic to create the order on your server
      return actions.order.create({
        purchase_units: [
          {
            description:"Subscription from 81handyman",
            amount:{
                currency_code:"USD",
                value:price
            }
          },
        ],
      });
    };
  
    const onApprove = async(data, actions) => {
      await buy_membership(membership_duration,type,actions)

    };


    
    return <div>

      <PayPalButtons createOrder={createOrder} onApprove={onApprove} />
    </div>

   


}