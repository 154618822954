import React, { useEffect, useState } from 'react';
import base_url from '../../../base_url';
import validator from 'validator';
import { Spinner } from 'react-activity';
const ContactUsPage = ({socket,user,detectPageChange}) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [phone_no,setPhoneno] = useState('')
  const [isLoading,setisLoading] = useState(false)

  const validateForm = ()=>{
    if(fullName.length<1){
      alert("Full Name Field Is Required")
      return false
    }

    if(validator.isEmail(email) == false){
      alert("Invalid Email")
      return false
    }


    if(subject.length<1){
      alert("Subject is required")
      return false
    }

    if(message.length<1){
      alert("Message is required")
      return false
    }


    if(phone_no.length<1){
      alert("Phone Number is required")
      return false
    }
    return true
  }
  const handleSubmit = async() => {
    const is_validated = validateForm()

    if(!is_validated){
      return false
    }

    setisLoading(true)
    try{

      const formData = new FormData()
      formData.append("email",email)
      formData.append("full_name",fullName)
      formData.append("message",message)

      formData.append("phone_no",phone_no)
      formData.append("subject",subject)
  
      const response = await fetch(base_url+'/apis/contact_us/contact_us',{
        method:'POST',
        body:formData,
        headers:{
            "x-api-key":process.env.REACT_APP_API_KEY
        }
      })
      const data = await response.json()
      if(response.status === 200){
        alert(data.message)
        setPhoneno('')
        setFullName('');
        setEmail('');
        setSubject('');
        setMessage('');
      }else{
        alert(data.message)

      }

      setisLoading(false)
    }catch(err){
      alert("Something Went Wrong")
      setisLoading(false)

    }
   
    
    
    
  };


  useEffect(()=>{
    detectPageChange(window.location.pathname)

  },[])

  return (
    <div className="container mt-5">
      <h2>Contact Us</h2>
      
        <div className="mb-3">
          <label htmlFor="fullName" className="form-label">
            Full Name:
          </label>
          <input
            type="text"
            className="form-control"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email:
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="subject" className="form-label">
            Subject:
          </label>
          <input
            type="text"
            className="form-control"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="subject" className="form-label">
            Phone Number:
          </label>
          <input
            type="text"
            className="form-control"
            id="subject"
            value={phone_no}
            onChange={(e) =>{setPhoneno(e.target.value)} }
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Message:
          </label>
          <textarea
            className="form-control"
            id="message"
            rows="5"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button  onClick={handleSubmit} className="btn btn-primary">
          {isLoading?<Spinner color='skyblue' size={25}/>:null}
          Submit
        </button>
      
    </div>
  );
};

export default ContactUsPage;
