// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: box-shadow 0.2s;
  }
  
  .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .back-button-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: aliceblue;
    width: 100%;
  }
  
  .back-arrow {
    font-size: 24px; /* Adjust the size as needed */
    margin-right: 10px; /* Adjust the spacing between arrow and text */
    color: #007aff;
  }
  
  .button-text {
    font-size: 18px; /* Adjust the size as needed */
    color: #007aff;

  }`, "",{"version":3,"sources":["webpack://./src/pages/main/home/JobsPage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,2BAA2B;EAC7B;;EAEA;IACE,wCAAwC;EAC1C;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,2BAA2B;IAC3B,WAAW;EACb;;EAEA;IACE,eAAe,EAAE,8BAA8B;IAC/C,kBAAkB,EAAE,8CAA8C;IAClE,cAAc;EAChB;;EAEA;IACE,eAAe,EAAE,8BAA8B;IAC/C,cAAc;;EAEhB","sourcesContent":[".card {\r\n    border: 1px solid #ccc;\r\n    border-radius: 8px;\r\n    transition: box-shadow 0.2s;\r\n  }\r\n  \r\n  .card:hover {\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n  }\r\n\r\n  .back-button-container {\r\n    display: flex;\r\n    align-items: center;\r\n    cursor: pointer;\r\n    background-color: aliceblue;\r\n    width: 100%;\r\n  }\r\n  \r\n  .back-arrow {\r\n    font-size: 24px; /* Adjust the size as needed */\r\n    margin-right: 10px; /* Adjust the spacing between arrow and text */\r\n    color: #007aff;\r\n  }\r\n  \r\n  .button-text {\r\n    font-size: 18px; /* Adjust the size as needed */\r\n    color: #007aff;\r\n\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
