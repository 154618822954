import React, { useEffect, useState } from 'react';
import axios from 'axios';
import base_url from '../../../base_url';
import {localStorageAdminKey} from '../../../local_storage_vars';
import {  useParams } from 'react-router-dom';
import validator from 'validator';
const UpdateUser = () => {
    const { id } = useParams();
  const [formData, setFormData] = useState({
    _id: '',
    username: '',
    email: '',
    password: '',
    user_type: '',
    retailer_percentage:'',
    is_active:''
  });
  

  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(validator.isEmail(formData.email) == false){
      alert("Invalid email")
      return false
    }

    if(formData.username.length<4){
      alert("Username must be at least 4 characters")
      return false
    }

    if(formData.is_active.length<1){
      alert("Please select that user will be active or not")
      return false
    }


    if(formData.user_type =="contractor" && formData.retailer_percentage.length <1 || formData.user_type == "contractor" && formData.retailer_percentage>1){
      alert("Retailer Percentage is required and Must not be greater than 1")
      return false
    }
    
    try {
      const response = await axios.put(`${base_url}/apis/user/update_user`, formData, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: localStorage.getItem(localStorageAdminKey),
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        alert(response.data.message);
        // You can redirect or handle success as needed
      } else if(response.status == 400) {
        alert(response.data.message);
      }else if(response.status == 404){
        alert(response.data.message);

      }
    } catch (error) {
      console.error(error);
      alert('Something Went Wrong');
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        `${base_url}/apis/user/view_user?user_id=${id}`
        ,{
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
              authorization: localStorage.getItem(localStorageAdminKey),
              'Content-Type': 'application/json',
            },
          });

      if (response.status === 200) {
        console.log(response.data)
        setFormData({
          _id:id,
          email:response.data.email,
          is_active:response.data.is_active,
          username:response.data.username,
          user_type:response.data.user_type,
          retailer_percentage:response.data.user_type == "contractor" && response.data.contractorEntity.retailer_percentage?response.data.contractorEntity.retailer_percentage['$numberDecimal']:0
        });

       
       
      } else {
       alert("Something Went Wrong")
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      // Handle error
    }
  };

  useEffect(()=>{
    fetchUserDetails()
  },[])

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Update User</h2>
      <form onSubmit={handleSubmit}>
        
        <div className="mb-3">
          <label className="form-label">Username:</label>
          <input
            type="text"
            className="form-control"
            name="username"
            value={formData.username}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Email:</label>
          <input
            type="text"
            className="form-control"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
       
        {formData.user_type == "contractor"?
         <div className="mb-3">
         <label className="form-label">Retainer Percentage:</label>
        <input
          type="number"
          placeholder='Retailer Percentage'
          step="0.01"
          name='retailer_percentage'
          min="0"
          max="1"
          className="form-control"
          value={formData.retailer_percentage}
         onChange={handleChange}
        />
        </div>
        
        :null}





        <div className="mb-3">
          <label className="form-label">Password:</label>
          <input
            type="password"
            className="form-control"
            name="password"
           
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">User Type:</label>
          <select
            className="form-control"
            name="user_type"
            value={formData.user_type}
            onChange={handleChange}
          >
            <option value={''}>Select user type</option>

            <option value="admin">Admin</option>
            <option value="contractor">Contractor</option>
            <option value="client">Client</option>
          </select>
        </div>


        <div className="mb-3">
          <label className="form-label">Active:</label>
          <select
            className="form-control"
            name="is_active"
            value={formData.is_active}
            onChange={handleChange}
          >
            <option value={''}>Select</option>

            <option value={true}>Yes</option>
            <option value={false}>No</option>
            
          </select>
        </div>
        <button type="submit" className="btn btn-primary">
          Update User
        </button>


        <button type="submit" onClick={()=>window.location = "/admin/users/"} className="btn btn-danger">
         Cancel
        </button>
      </form>
    </div>
  );
};

export default UpdateUser;
