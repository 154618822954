import { useEffect } from "react"
import LandingPageComponent from "../../components/landing_page"

export default function LandingPage({detectPageChange}){

    
useEffect(()=>{
    detectPageChange(window.location.pathname)
     
  },[])
  
    return <LandingPageComponent />
}