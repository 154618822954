// AddPricingForm.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import base_url from '../../../base_url';
import {localStorageUserKey, localStorageAdminKey} from '../../../local_storage_vars';

import Select from 'react-select'

const AddPricingForm = ({ onAdd }) => {
  const [service, setService] = useState('');
  const [price, setPrice] = useState('');
  const [servicesData,setservicesData] = useState([])
  const [toPrice,settoPrice] = useState('')
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const user = await localStorage.getItem(localStorageAdminKey);

      await axios.post(`${base_url}/apis/pricing/`, { service, price,toPrice },
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            authorization: user,
            'Content-Type': 'application/json',
          }
        });
      onAdd();
     
      setPrice('');
    } catch (error) {
      if(error.response && error.response.data){
        alert(error.response.data.message)
       }else{
        alert("Failed to add pricing.Please try again")
       }
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/service/get-services`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let temp_services = []
        response.data?.map(i=>{
          temp_services.push({value:i.name,label:i.name})
        })

        setservicesData(temp_services)
      } else {
        
        setservicesData([])
      }
    } catch (error) {
      // console.error(error);
      setservicesData([])
     
    }
  };
  
  useEffect(()=>{
    fetchServices()
  },[])
  return (
    <div>
      <h2>Add Pricing</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="service">Service:</label>
          <Select
            options={servicesData}
            isMulti={false}
            
            closeMenuOnSelect={true}
            placeholder="Services"
            onChange={(val)=>setService(val.value)}
            />
        </div>

       


        <div className="form-group">
          <label htmlFor="price">Price:</label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={e => setPrice(e.target.value)}
            className="form-control"
            required
          />
        </div>


        <div className="form-group">
          <label htmlFor="price">To:</label>
          <input
            type="number"
            id="to_price"
            value={toPrice}
            onChange={e => settoPrice(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Add</button>
      </form>
    </div>
  );
};

export default AddPricingForm;
