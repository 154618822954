import React, { useEffect, useState } from 'react';
import './JobsPage.css';
import axios from 'axios';
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';
import 'react-sliding-side-panel/lib/index.css';
import ContractorjobContainer from '../../../components/main/ContractorjobContainer';
import { Link } from 'react-router-dom';

const ContractorJobsPage = ({ jobs, isLoading,pageName,getJobs,socket,user }) => {
  const [favorites, setFavorites] = useState([]);

  const maxLength = 150; // Set your preferred maximum length
  const [isTruncated, setIsTruncated] = useState(true);
  const [servicesData,setservicesData] = useState([])
  const [favorite_toggled,setFavoriteToggled] = useState(false)

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/service/get-services`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let temp_services = []
        response.data?.map(i=>{
          temp_services.push({value:i.name,label:i.name})
        })

        setservicesData(temp_services)
      } else {
        
        setservicesData([])
      }
    } catch (error) {
      // console.error(error);
      setservicesData([])
     
    }
  };

  const showDescription =(job)=>{
    
    const description = pageName === 'favorites' ? job.job.description : job.description;
    const displayDescription = isTruncated ? description?.slice(0, maxLength) : description;
    return <p>{displayDescription} {description&&description.length>150&&<b style={{color:"#007aff",cursor:'pointer'}} onClick={toggleTruncate}>{isTruncated?' Readmore':' Read Less'}</b>}</p>
  }
  const handleToggleFavorite = async (jobId) => {
    const user =await localStorage.getItem(localStorageUserKey);
    setFavoriteToggled(true)
    
    try {
      // Check if the job is already in favorites
      const isFavorite = favorites.some((favorite) => favorite === jobId);
     
      if (isFavorite) {
        // If it's already a favorite, unfavorite it
        await axios.delete(`${base_url}/apis/job/unfavorite?jobId=${jobId}`,{
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "authorization": user,
            'Content-Type': 'application/json', // Set the content type
          },
        });
        setFavorites((prevFavorites) => prevFavorites.filter((favorite) => favorite !== jobId));
        if(pageName == "favorites"){
          getJobs()
        }
      } else {
        // If it's not a favorite, favorite it
        await axios.post(`${base_url}/apis/job/favorite`, { jobId },{
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "authorization": user,
            'Content-Type': 'application/json', // Set the content type
          },
        });
        setFavorites((prevFavorites) => [...prevFavorites, jobId]);
      }

  setFavoriteToggled(false)

    } catch (error) {
      alert("Something Went Wrong")
      console.error('Error toggling favorite:', error.message);
  setFavoriteToggled(false)

    }
  };

  const gatherFavorites = ()=>{
    if(pageName == "home"){
      jobs?.filter(i=>{
        if(i.isFavorite){
          setFavorites((prevFavorites) => [...prevFavorites, i._id]);
  
        }
      })
    }else{
      jobs?.filter(i=>{
        

          setFavorites((prevFavorites) => [...prevFavorites, i.job._id]);
  
        
      })
    }
    
  }

  useEffect(()=>{
    gatherFavorites()
    fetchServices()
  },[])

  return (
    <div className="container mt-5">
    <div className='border p-4' style={{borderRadius: 5}}>
    <button className='btn btn-primary float-right' onClick={() => window.location.reload()}>
      <i className="fa fa-refresh" aria-hidden="true" style={{fontSize: 15}}/> Refresh
    </button>
    {pageName === "favorites" ? 
      <h3 className="mb-4">Your Favorite Jobs</h3> :
      <div>
    <h4 style={{borderRadius:5}}>Welcome Back 😊 <b>{user?.username}</b></h4>
      
      </div>
    }

    <br />

    {pageName !== "favorites" &&
      <div className="row">
        <div className='col-md-6 mb-3'>
          <div className='border p-3' style={{borderRadius: 5}}>
            <h6><i className="fa fa-tasks" aria-hidden="true"></i> Manage your work orders</h6>
            <Link to="/orders" className="btn btn-success mt-3" style={{width:'100%'}}>
              Click here to see your work orders
            </Link>
          </div>
        </div>

        <div className='col-md-6 mb-3'>
          <div className='border p-3' style={{borderRadius: 5}}>
            <h6><i className="fa fa-envelope-open" aria-hidden="true"></i> Resume your communication with Clients</h6>
            <Link to="/chat/0" className="btn btn-success mt-3" style={{width:'100%'}}>
              Messages
            </Link>
          </div>
        </div>
      </div>
    }
    </div>
    <br />

          {isLoading === false ? (
            <div className=" row-cols-md-2 row-cols-lg-3 g-4">
              {jobs.length > 0 ? (
                jobs.map((job,index) => (
                  <ContractorjobContainer services={servicesData} key={index} favorite_toggled={favorite_toggled} socket={socket} job={job} pageName={pageName} showDescription={showDescription} handleToggleFavorite={handleToggleFavorite}  favorites={favorites}/>
                  
                ))
              ) : (
                <center className="mt-5">
                  <h1>No Jobs Found 🚫</h1>
                </center>
              )}
            </div>
          ) : null}
        </div>
  );
};

export default ContractorJobsPage;
