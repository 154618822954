import React, { useState, useEffect, useCallback, useRef } from 'react';
import './style.css';
import axios from 'axios';
import base_url from '../../../base_url';
import {localStorageUserKey} from '../../../local_storage_vars';
import { Spinner } from 'react-activity';
import MessageContainer from '../../../components/main/MessageContainer';
import OfferContainer from '../../../components/main/OfferContainer';
import { useLocation, useNavigate, useParams,useSearchParams} from 'react-router-dom';

const Chat = ({socket,number_of_unread_msgs,user_data,detectPageChange,CallforMessageCount}) => {
  const [recentChats, setRecentChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messageLoading, setMessageLoading] = useState(true);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(15);// messages per page
  const [newMessage, setNewMessage] = useState('');
  const [toggleChat, setTogglechat] = useState('')
  const [handyman_payment_accounts,setHandymanPaymentAccounts] = useState([])
  const decodedTokenRef = useRef(null); // Correctly defined ref for decodedToken
  const [foundUser, setFoundUser] = useState(false); // Correctly defined state for foundUser
  const [acceptingOfferLoading, setAcceptingOfferLoading] = useState(false); // Correctly defined state for acceptingOfferLoading
  const [servicesData, setservicesData] = useState([]); // Correctly defined state for servicesData
  const [isOnline,setIsOnline] = useState(false); 
  const [isChatPage,setisChatPage] = useState(false); 
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs,setJobs] = useState([])
  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams,setSearchParams] = useSearchParams();

  const shouldLog = useRef(true)
  const  [loadMoreLoading,setLoadMoreLoading]= useState(false)
  const messagesRef = useRef(null);

  const lastScrollTop = useRef(0);
  const scrollingUp = useRef(false)
  const [selectedChatUser,setSelectedChatUser] = useState([])
  const params = useParams();
  const selectedChat = params.user_id;


  const appendFilterParam = ()=>{
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('filter_non_completed_order')) {
        searchParams.set('filter_non_completed_order', 'true');
        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    }
  }

  const handleJobSelect = (job) => {
    setSelectedJob(job);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleInvitationSubmit = () => {

    const user = localStorage.getItem(localStorageUserKey);

    if(selectedJob == null){
      alert("Please select a job")
      return false
    }


    if (selectedChat != decodedTokenRef.current._id) {
      socket.emit('new_message', {
        message: "Invitation for job",
        sender: user,
        job_id:selectedJob._id,
        receiver: selectedChat,
        is_offer: false,
        is_invitation:true,
        price: 0,
        appointment_datetime: '',
        work_need_done: '',
        property_address: '',
        seen:isOnline && isChatPage

      });

    }
  
   
    // Reset selectedJob and close modal
    setSelectedJob(null);
    setShowModal(false);
  };

  const fetchClientJobs = async()=>{
    const token = await localStorage.getItem(localStorageUserKey);

    try {
      // Make a GET request to the API endpoint
      //fetch_client_jobs_not_in_orders
      //fetch_client_jobs_chatpage
      const response = await axios.get(`${base_url}/apis/job/fetch_client_jobs_not_in_orders`, {
        headers: { 
          "authorization": token,
          "x-api-key": process.env.REACT_APP_API_KEY,
        }
      });
      // Update the state with the fetched jobs
      setJobs(response.data.jobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  }

  const verifyToken = async () => {
    const token = await localStorage.getItem(localStorageUserKey);
    try {
      const response = await axios.get(`${base_url}/apis/auth/verify_token_after_login`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "authorization": token,
          'Content-Type': 'application/json',
        },
      });
      const responseData = response.data;

      console.log("***verify token responsedata - chat: ***", responseData);

      if (response.status === 200) {
        if (responseData.found) {
          decodedTokenRef.current =await responseData.user
          
          console.log("***verify token user - chat: ***", responseData.user);
          return decodedTokenRef.current
        } else {
         
         
          return null
        }
      }
    } catch (err) {
     
      return null

    }
  };

  const handleButtonClick = () => {
    if(jobs.length<1){
      fetchClientJobs()
    }
    setShowModal(true);
  };

  const CheckUserExistance = async()=>{
    if(selectedChat == 0) {return false}
    const token = await localStorage.getItem(localStorageUserKey);
    try {
      const response = await axios.get(`${base_url}/apis/user/view_user?user_id=${selectedChat}`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "authorization": token,
          'Content-Type': 'application/json',
        },
      });
      const responseData =await response.data;

      if (response.status === 200) {
       if(responseData){
        setFoundUser(true)
       }else{
        setFoundUser(false)

       }

      
    

      }
    } catch (err) {
      setFoundUser(false)
      

    }
  }

  const handleSendMessage = async () => {
    
    const user = localStorage.getItem(localStorageUserKey);
    if (newMessage.trim() !== '' && selectedChat != decodedTokenRef.current._id) {
      socket.emit('new_message', {
        message: newMessage,
        sender: user,
        receiver: selectedChat,
        is_offer: false,
        price: 0,
        appointment_datetime: '',
        work_need_done: '',
        property_address: '',
        seen:isOnline && isChatPage

      });

      setNewMessage('');
    }
  };

  const getrecentChats = async () => {
    const user = localStorage.getItem(localStorageUserKey);
    try {
      const res = await axios.get(`${base_url}/apis/recent_chat/get_chats`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "authorization": user,
          'Content-Type': 'application/json',
        }
      });
     
      setRecentChats(res.data);
      setIsLoading(false);
    } catch (err) {
      console.log("Something Went Wrong");
    }
  };

  const toggleDropdown = (chatId) => {
    if(chatId == toggleChat){
      setTogglechat('')
    }else{
      setTogglechat(chatId)
    }
  };

  const getMessages = useCallback(async () => {
    if (!hasMoreMessages || selectedChat == 0 ) return;

    setLoadMoreLoading(true)
    const user = localStorage.getItem(localStorageUserKey);
    const filterParam = searchParams.get('filter_non_completed_order') || true;
    

    try {
        const res = await axios.get(`${base_url}/apis/message/get_messages?receiver=${selectedChat}&limit=${limit}&offset=${offset}&filter_non_completed_order=${filterParam}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "authorization": user,
                'Content-Type': 'application/json',
            },
        });

        
            const newMessages = res.data.messages.reverse();
           
            setMessages(prevMessages => [...newMessages,...prevMessages]);
            setMessages(prevMessages => {
              // Create a map to keep track of messages by _id to ensure uniqueness
              const messageMap = new Map();
            
              // Fill the map with existing messages, using _id as the key
              prevMessages.forEach(message => messageMap.set(message._id, message));
            
              // Attempt to add new messages, ignoring those with _id already present
              res.data.messages.forEach(newMessage => {
                if (!messageMap.has(newMessage._id)) {
                  messageMap.set(newMessage._id, newMessage);
                }
              });
            
              // Convert the map back to an array for rendering
              // If order matters, ensure this conversion respects your desired ordering
              return Array.from(messageMap.values());
            });
            
            setMessageLoading(false);
            setLoadMoreLoading(false)
           
            setOffset(prevOffset => prevOffset +15);
           
            
            if (res.data.messages.length < limit) {
                setHasMoreMessages(false); // No more messages to load
            }
       
    } catch (err) {
        console.log(err);
        setMessageLoading(false);
    }
}, [selectedChat, offset, limit, hasMoreMessages]);

/*
  const accept_offer = async (offer_id) => {
    const user = localStorage.getItem(localStorageUserKey);
    setAcceptingOfferLoading(true);
    let formData = new FormData();
    formData.append('offer_id', offer_id);

      //try {
      //const res = 
      await axios.post(
        `${base_url}/apis/order/create-order`, 
        formData, 
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "authorization": user,
            'Content-Type': 'application/json',
          }
        }
      )
      .then((res) => {
        if (res.status === 200) {
              if (res.data.is_created) {
                alert("Order Created Successfully");
                socket.emit("offer_accepted", {
                  sender: user,
                  receiver: selectedChat,
                });
              } else {
                alert(res.data.message);
              }
            } else {
              alert(res.data.message);
            }
            setAcceptingOfferLoading(false);
            // setTimeout(() => {
            //   setAcceptingOfferLoading(false);
            // }, 600);
            // commented timeout for now
      })
      .catch(err => {
        //   setTimeout(() => {
        //     setAcceptingOfferLoading(false);
        //   }, 600);
        setAcceptingOfferLoading(false);
        alert("Something Went Wrong");
        console.log(err);
      });
    //   if (res.status === 200) {
    //     if (res.data.is_created) {
    //       alert("Order Created Successfully");
    //       socket.emit("offer_accepted", {
    //         sender: user,
    //         receiver: selectedChat,
    //       });
    //     } else {
    //       alert(res.data.message);
    //     }
    //   } else {
    //     alert(res.data.message);
    //   }

    //   setTimeout(() => {
    //     setAcceptingOfferLoading(false);
    //   }, 600);
    // } catch (err) {
    //   setTimeout(() => {
    //     setAcceptingOfferLoading(false);
    //   }, 600);

    //   alert("Something Went Wrong");
    // }
   };
*/


  const fetchHandymanPaymentAccounts = async()=>{
   if(params.user_id == 0) return
    const _user = await verifyToken()
    if( _user == undefined || _user == null || foundUser == false) return;
    if( _user.user_type == null || _user.user_type != "client"){return false}

    const user = await localStorage.getItem(localStorageUserKey);
    await axios.get(`${base_url}/apis/user/fetch_payment_accounts?user_id=${selectedChat}`,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', 
      }})
      .then(response=>{
        
        if(response.status == 200){
          setHandymanPaymentAccounts(response.data)
        }
      })
      .catch(err=>{
       console.log(err)
      })
  }
 
  const handleScroll = useCallback((event) => {
    
    const { scrollTop } = event.currentTarget;
   
    const isScrollingUp = scrollTop < lastScrollTop.current;
    scrollingUp.current = isScrollingUp
    // Check if scrolled to top and has more messages to load
    if (scrollTop === 0 && hasMoreMessages) {
      // scrollingUp.current = true
      getMessages();
  
    }

    lastScrollTop.current = scrollTop;

  }, [hasMoreMessages, getMessages]);

  const dismissChat = () => {
    setSelectedChatUser(null);
    window.location="/chat/0";
  }

  const getSelectedChatUser = async()=>{
    if(selectedChat == 0 || !selectedChat) return 
    const user = await localStorage.getItem(localStorageUserKey);
    await axios.get(`${base_url}/apis/message/selected_chat?user_id=${selectedChat}`,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', 
      }})
      .then((response)=>{
            
        setSelectedChatUser(response.data)
            
      })
      .catch(err=>{
        setSelectedChatUser([])

      //  console.log(err)
      })
  }

  // Initial data load
  useEffect(() => { 

    CheckUserExistance();

    
    const initialLoad = async()=>{
      await verifyToken();

      getrecentChats();
      
      getMessages();
  
    }

    
    if(shouldLog.current ){
      shouldLog.current = false
      appendFilterParam()

      detectPageChange("chat")
      initialLoad()
    }

    
    
  }, [location, navigate]);

  useEffect(()=>{

    if (scrollingUp.current == false && messagesRef.current) {
    
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }else if(scrollingUp.current == true && messagesRef.current){
      messagesRef.current.scrollTop = 1200;

    }

  },[selectedChat,[]])

useEffect(()=>{
  if(selectedChat == 0){
    setIsCollapsed(false)
  }else{
    setIsCollapsed(true)

  }
  if(foundUser){
    getSelectedChatUser()
    fetchHandymanPaymentAccounts()
    
  }



},[selectedChat,foundUser])



useEffect(()=>{


 

  socket.on('connected_users',(data)=>{
    
    let is_online = data.connected_users.filter(u=>u.user_id == params.user_id)
    
    setIsOnline(is_online.length>0)
    
   
  })

  socket.on('chat_page_users',(data)=>{
   
    let is_chat_page = data.chat_page_users.filter(u=>u.user_id == params.user_id)
    
    setisChatPage(is_chat_page.length>0)

  })


  
},[socket])


useEffect(() => {
  const handleMessage = async(data) => {
    // Check if the selected chat is active and the decoded token is available
    if (selectedChat !== 0 && decodedTokenRef.current !== null) {
      // Handle both 'broadcast' and 'simple' message types with a single condition
      if ((data.type === "broadcast" && data.receiver === decodedTokenRef.current._id) || data.type === "simple") {
        // Update messages state once, regardless of message type
       

        setMessages(prevMessages => [...prevMessages, data.newMessage]);
        await getrecentChats();
        
        return true; // Indicate that the message was handled
      }
    }

    return false; // Indicate that no action was taken
  };


  const handleAcceptOfferNoti = (data)=>{
  
    if (data.type == "broadcast" && data.receiver == decodedTokenRef.current._id) {
      setMessages(prevItems =>
        prevItems.map(item =>
          item._id === data.offer_id ? { ...item, is_accepted: true } : item
        )
      );

      
   
    }
  }

  socket.on("new_messages", handleMessage);
  socket.on('offer_accepted_notification',handleAcceptOfferNoti)
  return () => {
    // Clean up the event listener when the component unmounts or dependencies change
    socket.off("new_messages", handleMessage);
    socket.off('offer_accepted_notification',handleAcceptOfferNoti)


  };
}, [socket, selectedChat]);




  return (

  <div className="row p-4">
    
 <div className="col-md-4">
      <div className='border p-2'>
        <h4 style={{ borderRadius: 5, cursor: 'pointer' }} onClick={toggleCollapse}>
          All Conversations
          <span style={{ float: 'right',color:'#007bff' }}>{isCollapsed ? '▼' : '▲'}</span>
        </h4>
      </div>
      {!isCollapsed && (
        <>           
          {/* conversation pane area */}
          <br />
          <div className="list-group">
            {isLoading === false ? (
              <div style={{ height: 380, flexDirection: 'column', overflowY: 'scroll' }}>
                {recentChats.map((chat, index) => (
                  <div key={index}>
                    {chat.user[0] ? (
                      <a
                        href={`/chat/${chat.partnerId}`}
                        type="button"
                        style={{ color: 'black' }}
                        className={`list-group-item list-group-item-action ${selectedChat === chat.partnerId ? 'active' : ''}`}
                      >
                        <div style={{ display: 'flex' }}>
                          <img
                            src={chat.user[0]?.profile_picture ? chat.user[0]?.profile_picture : require('../../../assets/user-circle.png')}
                            style={{ borderRadius: 50, height: 50, width: 50 }}
                          />
                          <div style={{ marginTop: 5, marginLeft: 10 }}>
                            <b>{' ' + chat.user[0]?.username}</b>
                            <br />
                            <small style={{ color: 'gray' }}>
                              {chat.lastMessage[0]?.sender === decodedTokenRef.current._id ? 'Me' : chat.user[0]?.username} :{' '}
                              {chat.lastMessage[0]?.message.slice(0, 20) + '...'}
                            </small>
                          </div>
                        </div>
                        {chat.unseenMessagesCount > 0 && chat.user[0]._id !== selectedChat ? (
                          <p
                            style={{
                              position: 'absolute',
                              backgroundColor: 'red',
                              color: 'white',
                              width: 22,
                              height: 22,
                              borderRadius: '100%',
                              left: '90%',
                              bottom: 10,
                              textAlign: 'center',
                              alignContent: 'center',
                              fontSize: 12,
                              fontWeight: 'bold',
                            }}
                          >
                            {chat.unseenMessagesCount}
                          </p>
                        ) : null}
                      </a>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : (
              <Spinner size={20} color='skyblue' />
            )}
          </div>
          {/* end conversation pane area */}
        </>
      )}
    </div>
   
    {!isLoading && decodedTokenRef.current != null && selectedChat != 0 && selectedChat && !messageLoading && selectedChatUser && (
      <div className="col-md-8" 
      style={{border: 'Pink 0px Solid', position:'absolute', top:'0px', right:'0px', bottom:'0px', 
        zIndex:'1000', backgroundColor: 'white' }}>
          
          <div  className='border p-1 mt-2' style={{borderRadius:10,borderColor:'#007aff',backgroundColor:'#007aff'}}>
         
          <div style={{display:'flex',flexDirection:'row', border: 'Orange 0px Solid'}} >
            <img src={selectedChatUser.profile_picture?selectedChatUser.profile_picture:require('../../../assets/user-circle.png')} style={{width:40,height:40,borderRadius:'100%'}}/>
            <p style={{color:'white',fontWeight:'bold',marginTop:5,marginLeft:10}}>{selectedChatUser?.username}
           
            {isOnline?<small> <i className="fa fa-circle" style={{color:'green'}}></i> online </small>:<small> <i className="fa fa-circle" style={{color:'red'}}></i> offline </small>}
            </p>

          </div>

          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={dismissChat} 
                  style={{position:'absolute', top:'15px', right:'35px', width:'40px', height:'40px'}} >&times; </button>
          </div>
          <div>
             {foundUser?<div className="messages-box" ref={messagesRef} onScroll={handleScroll}>
              {messageLoading == false ? <div >
                {loadMoreLoading?<center><Spinner color='skyblue' size={18} /></center>:null}
                {messages?.map((message,index) => {
                  if (!message.is_offer || message.is_offer == null) {
                    return <MessageContainer key={index} message={message} decodedTokenRef={decodedTokenRef} />
                  } else {
                    return (
                      <OfferContainer services={servicesData}  payment_accounts={handyman_payment_accounts} key={index} selectedChat={selectedChat} socket={socket}  message={message} decodedTokenRef={decodedTokenRef} acceptingOfferLoading={acceptingOfferLoading} />
                      // accept_offer={accept_offer} 
                    );
                  }
                })}
              </div> : <center><Spinner color="skyblue" size={25} /></center>}
            </div>:
            <center className='mt-5'>
              <h1>User Not Found(404) </h1>
              <br />
            </center>
            }

            {/* chat and buttons area */}
            {selectedChat && foundUser && selectedChat != decodedTokenRef.current._id?<div className="new-message-box">
              {selectedChatUser.is_active?<>
              
              <textarea
                className="form-control"
                rows="3"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(val) => setNewMessage(val.target.value)}
              ></textarea>

                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap'}}>

                <div style={{display:'inline'}}>
                <button disabled={selectedChat == 0 ? true : false} className="btn btn-primary ml-1 mt-1" onClick={handleSendMessage}>
                  Send message <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>

                {decodedTokenRef.current?.user_type == "client"?<button disabled={selectedChat == 0 ? true : false} onClick={handleButtonClick}  
                className="btn btn-success ml-1 mt-1" >
                  Create Job Invitation
                </button> :null}
                </div>
              

                <div className='border' style={{borderWidth:'0', borderColor:'#FF5B61', padding:'10px', float:'right', borderRadius:'5', paddingRight:'30px', marginTop:'5px'}}>
                <input type='checkbox' checked={searchParams.get('filter_non_completed_order')==="true"?true:false} style={{width:'20',height:'20'}} onChange={(val)=>{
                    setSearchParams({ filter_non_completed_order: val.target.checked});
                    window.location.reload()
                }} />
                <span style={{marginLeft:'10', paddingTop:'-10px'}}> Hide messages for completed orders</span>
                
              </div>

              </div>

              </>:
              <center style={{backgroundColor:'#FF5B61',borderRadius:5}}>
                <p style={{color:'white'}}><b> {selectedChatUser.username}</b> been temporarily deactivated by the admin.</p>
              </center>}
            
            </div>
            :null}
            {/* end chat and buttons area */}

          </div>

      </div>
    )} 

              
    {(!isLoading || decodedTokenRef.current != null || selectedChat === 0 || !selectedChatUser) &&
    (<div  className="col-md-8">           
            
        {number_of_unread_msgs >0 && (<div className="mt-1">
          <h2>Unread Messages</h2>
          {recentChats.map((chat,index) => (
              <div key={index}>
              {chat.unseenMessagesCount>0?<div key={chat._id}>
                  <div className="d-flex justify-content-between jumbotron" style={{padding:10}}>
                      <div style={{display:'flex',flexDirection:'row'}}>

                      <img src={chat.user[0]?.profile_picture ? chat.user[0]?.profile_picture : require('../../../assets/user-circle.png')} style={{ borderRadius: '100%', height: 40, width: 40 }} />
                      <h6 style={{paddingTop:15,marginLeft:10}}>{chat.user[0]?.username}</h6>
                      </div>

                      <button className="btn btn-link" onClick={() => toggleDropdown(chat._id)}>
                          {chat.unseenMessagesCount > 0 && `Unseen Messages: ${chat?.unseenMessagesCount}`} <i className="fa fa-arrow-down"></i>
                      </button>
                  </div>
                  {chat.unseenMessagesCount>0 && toggleChat == chat._id ? (
                      <ul className="list-group mt-2">
                          {chat.unseenMessages.map((message, index) => (
                              <li key={index} className="list-group-item " style={{float:'left'}}><b>{chat.user[0]?.username}{': '}</b> {message.message}</li>
                          ))}
                      </ul>
                  ):null}
          
              </div>:null}
              </div>

          ))}
        </div>)}
        <center className="  mt-5">
          <img src={require('../../../assets/messages.webp')} style={{ width: 120, height: 120 }} />
          
          <p style={{ color: 'black',fontWeight:'bold',fontSize:20 }}>Please select a conversation to start</p>
          <br />
          <br />
          </center> 
          
        </div> 
    )
     }
    
    {isLoading && (
      <center><Spinner size={25} color='skyblue' /></center>
    )}

     {/* Modal */}
     <div
        className={`modal fade ${showModal ? 'show' : ''}`}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? 'block' : 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Send Job Invitation

              </h5>
             

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>

           

            </div>

            <p style={{marginLeft:'20px'}}>Please select a job to begin:</p>
            <div className="modal-body" style={{overflow:'auto', height:'60vh'}}>
            <ul className="list-group">
                {jobs.map((job) => (
                  <li
                    key={job._id}
                    style={{cursor: 'pointer'}}
                    className={`list-group-item ${
                      selectedJob && selectedJob._id === job._id ? 'active' : ''
                    }`}
                    onClick={() => handleJobSelect(job)}
                  >
                   <h5>{job.name}</h5>
                    <div>
                      <strong>Posted date:</strong> {new Date(job.createdAt).toDateString()}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-footer">

            <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={() => handleInvitationSubmit()}
              >
                Send
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              {/* Add additional buttons or actions if needed */}
            </div>
          </div>
        </div>
      </div>
      {/* Modal backdrop */}
      {showModal && <div className="modal-backdrop fade show"></div>}
  </div>


  );


};

export default Chat;
