import React from "react";


export default function Footer(){
    return(
        <>
    {/* Footer */}
    <footer
        id="app-footer"
        className="text-center text-white"
        style={{
        backgroundColor: "#0751a0",
        padding: "20px 0",
        marginTop: "20%",
        position: "relative"
        }}
    >
        {/* Grid container */}
        <div className="container">
        {/* Section: Links */}
        {/* Section: Links */}
        <hr className="my-3" />
        {/* Section: Text */}
        <section className="mb-3">
            <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
            <p> Welcome to 81Handyman.com! </p>
        <p>Your Trusted Partner for Quality Handyman Services.</p>
        <p>No Job too Big or too Small.</p>
        <p>Please Register for your FREE QUOTE.</p>
             
            </div>
            </div>
        </section>
        {/* Section: Text */}
        {/* Section: Social */}
        </div>
        {/* Grid container */}
        {/* Copyright */}
        <div
        className="text-center p-2"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", fontSize: 12 }}
        >
        Copyright © 2024{" "}
        <a
            className="text-white "
            target="_blank"
            style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            href="http://81handyman.com/"
        >
            81Handyman®
        </a>
        . All rights reserved / <a href="/terms-conditions"  target="_blank" style={{color:'white'}}>Terms and Conditions </a>
        </div>
        {/* Copyright */}
    </footer>
    {/* Footer */}
    </>

    )
}