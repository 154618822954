import React from "react";


export default function WarningAlert({children}){
    return(
    <div className="alert alert-warning alert-dismissible fade show " role="alert">
    {children}

    <button type="button" className="close" data-dismiss="alert" aria-label="Close" 
    style={{paddingTop:'5px', paddingRight:'5px'}} >&times;
    {/* <span aria-hidden="true" style={{fontSize: '0.8em'}} >&times;</span> */}
    </button>
    </div>
    )
}